import { Item } from "./item";

export class ContainerType implements Item {
  public "@id"?: string;

  constructor(
    _id?: string,
    public name?: string,
    public abbreviation?: string,
    public teu?: number,
    public gridSize?: number,
  ) {
    this["@id"] = _id;
  }
}
