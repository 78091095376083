import axios from "axios";
import { hideModal } from "../data/modal";

/*****************/
/* INITIAL STATE */
/*****************/
const initialState = {
  loading: false,
  error: "",
  data: [],
};

/*********/
/* TYPES */
/*********/
const FETCH_VESSELS = "FETCH_VESSELS";
const FETCH_VESSELS_SUCCESS = "FETCH_VESSELS_SUCCESS";
const FETCH_VESSELS_ERROR = "FETCH_VESSELS_ERROR";
const PUT_VESSEL = "PUT_VESSEL";
const POST_VESSEL = "POST_VESSEL";
const DELETE_VESSEL = "DELETE_VESSEL";
const FETCH_NEXT_VESSELS = "FETCH_NEXT_VESSELS";
const FETCH_NEXT_VESSELS_SUCCESS = "FETCH_NEXT_VESSELS_SUCCESS";

/*******************/
/* ACTION CREATORS */
/*******************/
export const getVessels = () => async (dispatch) => {
  dispatch(loadVessels());

  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/vessels?page=1`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.data["hydra:member"].length === 0) {
    dispatch(setError("No vessels found"));
  } else {
    dispatch(setVessels(response.data["hydra:member"]));
    if (
      response.data &&
      response.data["hydra:view"] &&
      response.data["hydra:view"] !== undefined &&
      response.data["hydra:view"]["hydra:next"] &&
      response.data["hydra:view"]["hydra:next"] !== undefined
    ) {
      dispatch(getNextVessels(response.data["hydra:view"]["hydra:next"]));
    }
  }
};

export const getNextVessels = (nextUrl) => async (dispatch) => {
  dispatch(loadNextVessels());

  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL}${nextUrl}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  dispatch(setNextVessels(response.data["hydra:member"]));

  if (
    response.data &&
    response.data["hydra:view"] &&
    response.data["hydra:view"] !== undefined &&
    response.data["hydra:view"]["hydra:next"] &&
    response.data["hydra:view"]["hydra:next"] !== undefined
  ) {
    dispatch(getNextVessels(response.data["hydra:view"]["hydra:next"]));
  }
};

export const updateVessel = (vessel) => async (dispatch, getState) => {
  dispatch(putVessel());
  const {
    vessels: { data },
  } = getState();

  const response = await axios({
    method: "put",
    url: `${process.env.REACT_APP_URL}${vessel["@id"]}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: vessel,
  });

  if (response.status === 200) {
    const resultData = data.map((vessel) => {
      if (vessel["@id"] === response.data["@id"]) {
        return response.data;
      }

      return vessel;
    });

    dispatch(setVessels(resultData));
    dispatch(hideModal());
  } else {
    setError("Something went wrong updating");
  }
};

export const removeVessel = (vessel) => async (dispatch, getState) => {
  dispatch(putVessel());
  const {
    vessels: { data },
  } = getState();

  const response = await axios({
    method: "delete",
    url: `${process.env.REACT_APP_URL}${vessel["@id"]}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.status === 204) {
    const resultData = data.filter((x) => x["@id"] !== vessel["@id"]);

    dispatch(setVessels(resultData));
    dispatch(hideModal());
  } else {
    setError("Something went wrong deleting");
  }
};

export const createVessel = (vessel) => async (dispatch, getState) => {
  dispatch(postVessel());
  const {
    vessels: { data },
  } = getState();

  const response = await axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/vessels`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: vessel,
  });

  if (response.status === 201) {
    const resultData = [...data, ...[response.data]];

    dispatch(setVessels(resultData));
    dispatch(hideModal());
  } else {
    setError("Something went wrong deleting");
  }
};

export const loadVessels = () => ({ type: FETCH_VESSELS });

export const putVessel = () => ({ type: PUT_VESSEL });

export const postVessel = () => ({ type: POST_VESSEL });

export const deleteVessel = () => ({ type: DELETE_VESSEL });

export const setVessels = (vessels) => ({
  type: FETCH_VESSELS_SUCCESS,
  payload: { vessels },
});

export const loadNextVessels = (page) => ({
  type: FETCH_NEXT_VESSELS,
  payload: page,
});
export const setNextVessels = (vessels) => ({
  type: FETCH_NEXT_VESSELS_SUCCESS,
  payload: vessels,
});

export const setError = (msg) => ({
  type: FETCH_VESSELS_ERROR,
  payload: msg,
});

/***********/
/* REDUCER */
/***********/
const vesselsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_VESSELS:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_VESSELS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        data: payload.vessels,
      };
    case PUT_VESSEL:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case POST_VESSEL:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case DELETE_VESSEL:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_VESSELS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case FETCH_NEXT_VESSELS:
      return {
        ...state,
        error: "",
      };
    case FETCH_NEXT_VESSELS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, ...payload],
      };
    default:
      return state;
  }
};
export default vesselsReducer;
