import React, { useState } from 'react';
import FileView from './FileView';

import { 
  Divider,
  Grid,
  List as MuiList,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import {
  InsertDriveFileSharp as InsertDriveFileSharpIcon,
} from "@mui/icons-material";

const FileList = ({files}) => {
  const [selectedFile, setSelectedFile] = useState(files && files.length > 0 ? files[0] : undefined);
  
  if(!files || files.length === 0) {
    return '';
  }
  return (
      <Grid container sx={{ display: 'flex', gap:'5px', flexWrap: 'nowrap' }}>
        <Grid item>
          <MuiList>
            { files && files.length > 0 &&
              files.map((file:any, index:any) => (
                <ListItem 
                  disablePadding 
                  className={`js-existing-file-element ${ file === selectedFile ? 'active-menu' : '' }`}
                  data-existing-file-id={file['@id']}
                  key={`file-view-existing-file-${index}`}
                >
                  <ListItemButton 
                    onClick={() => setSelectedFile(file) }
                  >
                    <ListItemIcon>
                      <InsertDriveFileSharpIcon />
                    </ListItemIcon>
                    <ListItemText primary={`${ file.description }`} />
                  </ListItemButton>
                </ListItem>
              ))
            }
          </MuiList>
        </Grid>
        <Grid item sx={{ width: '5px' }}>
          <Divider orientation="vertical"/>
        </Grid>
        <Grid item sx={{ minHeight: '40vh !important', maxWidth: '100%' }}>
          { selectedFile ? (
            <FileView file={selectedFile} includeButtons={false} />
          ) : 'No file selected'}
        </Grid>
      </Grid>
    )
}

export default FileList;
