import axios from "axios";
import { hideModal } from "./modal";

/*****************/
/* INITIAL STATE */
/*****************/
const initialState = {
  loading: false,
  error: "",
  data: [],
};

/*********/
/* TYPES */
/*********/
const FETCH_NOTES = "FETCH_NOTES";
const FETCH_NOTES_SUCCESS = "FETCH_NOTES_SUCCESS";
const FETCH_NOTES_ERROR = "FETCH_NOTES_ERROR";
const PUT_NOTE = "PUT_NOTE";
const POST_NOTE = "POST_NOTE";
const DELETE_NOTE = "DELETE_NOTE";
const FETCH_NEXT_NOTES = "FETCH_NEXT_NOTES";
const FETCH_NEXT_NOTES_SUCCESS = "FETCH_NEXT_NOTES_SUCCESS";

/*******************/
/* ACTION CREATORS */
/*******************/
export const getNotes = () => async (dispatch) => {
  dispatch(loadNotes());

  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/notes?page=1`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.data["hydra:member"].length === 0) {
    dispatch(setError("No notes found"));
  } else {
    dispatch(setNotes(response.data["hydra:member"]));
    if (
      response.data &&
      response.data["hydra:view"] &&
      response.data["hydra:view"] !== undefined &&
      response.data["hydra:view"]["hydra:next"] &&
      response.data["hydra:view"]["hydra:next"] !== undefined
    ) {
      dispatch(getNextNotes(response.data["hydra:view"]["hydra:next"]));
    }
  }
};

export const getNextNotes = (nextUrl) => async (dispatch, getState) => {
  dispatch(loadNextNotes());

  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL}${nextUrl}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  dispatch(setNextNotes(response.data["hydra:member"]));

  if (
    response.data &&
    response.data["hydra:view"] &&
    response.data["hydra:view"] !== undefined &&
    response.data["hydra:view"]["hydra:next"] &&
    response.data["hydra:view"]["hydra:next"] !== undefined
  ) {
    dispatch(getNextNotes(response.data["hydra:view"]["hydra:next"]));
  }
};

export const updateNote = (note) => async (dispatch, getState) => {
  dispatch(putNote());
  const {
    notes: { data },
  } = getState();

  const response = await axios({
    method: "put",
    url: `${process.env.REACT_APP_URL}${note["@id"]}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: note,
  });

  if (response.status === 200) {
    const resultData = data.map((note) => {
      if (note["@id"] === response.data["@id"]) {
        return response.data;
      }

      return note;
    });

    dispatch(setNotes(resultData));
    dispatch(hideModal());
  } else {
    setError("Something went wrong updating");
  }
};

export const removeNote = (note) => async (dispatch, getState) => {
  dispatch(putNote());
  const {
    notes: { data },
  } = getState();

  const response = await axios({
    method: "delete",
    url: `${process.env.REACT_APP_URL}${note["@id"]}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.status === 204) {
    const resultData = data.filter((x) => x["@id"] !== note["@id"]);

    dispatch(setNotes(resultData));
    dispatch(hideModal());
  } else {
    setError("Something went wrong deleting");
  }
};

export const createNote = (note) => async (dispatch, getState) => {
  dispatch(postNote());
  const {
    notes: { data },
  } = getState();

  const response = await axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/notes`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: note,
  });

  if (response.status === 201) {
    const resultData = [...data, ...[response.data]];

    dispatch(setNotes(resultData));
    dispatch(hideModal());
  } else {
    setError("Something went wrong deleting");
  }
};

export const loadNotes = () => ({ type: FETCH_NOTES });

export const putNote = () => ({ type: PUT_NOTE });

export const postNote = () => ({ type: POST_NOTE });

export const deleteNote = () => ({ type: DELETE_NOTE });

export const setNotes = (notes) => ({
  type: FETCH_NOTES_SUCCESS,
  payload: { notes },
});

export const loadNextNotes = (page) => ({
  type: FETCH_NEXT_NOTES,
  payload: page,
});
export const setNextNotes = (notes) => ({
  type: FETCH_NEXT_NOTES_SUCCESS,
  payload: notes,
});

export const setError = (msg) => ({
  type: FETCH_NOTES_ERROR,
  payload: msg,
});

/***********/
/* REDUCER */
/***********/
const notesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_NOTES:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_NOTES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        data: payload.notes,
      };
    case PUT_NOTE:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case POST_NOTE:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case DELETE_NOTE:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_NOTES_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case FETCH_NEXT_NOTES:
      return {
        ...state,
        error: "",
      };
    case FETCH_NEXT_NOTES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, ...payload],
      };
    default:
      return state;
  }
};
export default notesReducer;
