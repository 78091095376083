import React, { useEffect, useState } from 'react';
import  { Link } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../data/user";
import { stringAvatar } from './Helpers';
import ContainerSearch from './partial/ContainerSearch';

import { 
  AppBar as MuiAppBar, 
  Divider,
  Toolbar, 
  Container, 
  IconButton, 
  Typography,
  Grid,
  Paper, 
  Menu,
  MenuList,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Box, 
  CssBaseline,
  Stack,
  Avatar,
} from '@mui/material';

import {
  Menu as MenuIcon,
  AltRoute as AltRouteIcon,
  GridOn as GridOnIcon,
  Tune as TuneIcon,
  PrecisionManufacturing as PrecisionManufacturingIcon,
  InsertChartSharp as InsertChartSharpIcon,
} from '@mui/icons-material';

import Logo from './Logo.tsx';

const defaultTheme = createTheme();

export default function Layout({ showContainerSearch = false, children }:any) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [userName, setUserName] = useState('');

  const selectedData = useSelector((state) => ({
    user: state.user.data,
  }));

  useEffect(() => {
    dispatch(getCurrentUser());
  }, [dispatch]);

  const {
    user
  } = selectedData;


  useEffect(() => {
    if (user) {
      setUserName(`${user?.lastName ?? ''} ${user?.firstName ?? ''}`)
    }
    
  }, [user]);
  
  const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
      setAnchorEl(null);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <MuiAppBar>
          <Toolbar>
            <Grid 
              sx={{ 
                display: 'flex',
                flexGrow: 1,
                alignContent: 'center',
                alignItems: 'center',
                gap: '20px'
              }}>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                sx={{
                  display: 'flex',   
                  alignItems: 'center',
                }}
              >
                <Logo/>
              </Typography>
              <IconButton
                  id="menu-button"
                  aria-controls={open ? 'navigation-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  sx={{ color: '#FFFFFF' }}
              >
                  <MenuIcon />
              </IconButton>
              <Menu
                  id="navigation-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'menu-button',
                  }}
                  sx={{ paddingTop: 0, paddingBottom: 0 }}
                >
                <Paper sx={{ width: 320, height: '100%', boxShadow: 'none' }}>
                  <MenuList>
                    <Link to='/logistics'>
                      <MenuItem>
                        <ListItemIcon>
                          <AltRouteIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Logistics</ListItemText>
                      </MenuItem>
                    </Link>
                    <Link to='/container-planner'>
                      <MenuItem>
                        <ListItemIcon>
                          <GridOnIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Container Planner</ListItemText>
                      </MenuItem>
                    </Link>
                    <Link to='/terminal-view'>
                      <MenuItem>
                        <ListItemIcon>
                          <PrecisionManufacturingIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Terminal View</ListItemText>
                      </MenuItem>
                    </Link>
                    <Link to='/reports'>
                      <MenuItem>
                        <ListItemIcon>
                          <InsertChartSharpIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Reports</ListItemText>
                      </MenuItem>
                    </Link>
                    <Divider/>
                    <Link to='/settings/contact'>
                      <MenuItem>
                        <ListItemIcon>
                          <TuneIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Settings</ListItemText>
                      </MenuItem>
                    </Link>
                  </MenuList>
                </Paper>
              </Menu>
            </Grid>
            <Grid item sx={{ display:'flex', gap:'15px'}}>
              { showContainerSearch ? (
                <Grid item>
                  <ContainerSearch/>
                </Grid>
              ) : ''}
              { userName && userName.length > 0 ? (
                <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                  <Avatar {...stringAvatar(userName)} sx={{ width: 30, height: 30, fontSize: '1rem' }} />
                  <Typography>{userName}</Typography>
                </Stack>
              ) : ''}
            </Grid>
          </Toolbar>
        </MuiAppBar>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container sx={{ mt: 4, mb: 4, padding: '0 !important',  maxWidth: 'none !important' }}>
            <main>{children}</main>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}