import * as React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import {
  createTerminalCall,
  updateTerminalCall,
} from "../../data/terminalcall";
import { useDispatch } from "react-redux";
import { TerminalCall } from "../../types/TerminalCall";
import { SelectField } from "../FormFields/RenderSelectField";
import { DateTimeField } from "../FormFields/RenderDateTimeField";
import GetTerminalOptions from "../FormHelpers/TeminalSelectOptions";
import GetVesselOptions from "../FormHelpers/VesselSelectOptions";
import { hideModal } from "../../data/modal";
import { updateContainer } from "../../data/containers";
import { removeTerminalCall } from "../../data/terminalcall";
import { SelectMultipleField } from "../FormFields/RenderSelectMultipleField";
import GetLabelOptions from "../FormHelpers/LabelSelectOptions";

import { Button, Divider, Grid, Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";

import {
  Close as CloseIcon,
  Save as SaveIcon,
  Delete as DeleteIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import { CheckboxField } from "../FormFields/RenderCheckboxField";

const ACTION_TYPE_LOADING = "loading";
const ACTION_TYPE_UNLOADING = "unloading";
const DOCKING_SIDE_STARBOARD = 'starboard';
const DOCKING_SIDE_PORT = 'port';

const TerminalCallForm = ({
  terminalCall,
  terminals,
  vessels,
  container,
  labels,
  loadingUnloading = "",
}) => {
  const dispatch = useDispatch();

  const { handleSubmit, control, formState } = useForm({
    defaultValues: terminalCall ?? new TerminalCall(),
  });
  const { isSubmitting } = formState;

  const onSubmit: SubmitHandler<TerminalCall> = (data) => {
    if (terminalCall) {
      dispatch(updateTerminalCall(data));
    } else {
      dispatch(createTerminalCall(data));
    }
  };

  const detachTerminalCall = (container, loadingUnloading) => {
    let detachedTerminalCall = container;

    if (loadingUnloading === ACTION_TYPE_LOADING) {
      detachedTerminalCall = {
        ...container,
        loadingTerminal: null,
        loadingTerminalCall: null,
      };
    }
    if (loadingUnloading === ACTION_TYPE_UNLOADING) {
      detachedTerminalCall = {
        ...container,
        unloadingTerminal: null,
        unloadingTerminalCall: null,
      };
    }

    dispatch(updateContainer(detachedTerminalCall));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* TODO: Max width should be removed when state debugger is removed. */}
      <Grid container maxWidth='80vh'>
        <Grid item xs={12} sm={12}>
          <Grid item xs={12}>
            <Typography variant="h6" className="form-fields-title">
              Transport
            </Typography>
            <Grid item xs={12} className="form-fields-container">
              <Grid item xs={12} sm={6}>
                <SelectField
                  name="vessel"
                  label="Vessel"
                  control={control}
                  options={GetVesselOptions(vessels, false)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectField
                  name="terminal"
                  label="Terminal"
                  control={control}
                  options={GetTerminalOptions(terminals)}
                  required
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} marginTop={1} marginBottom={1}>
            <Divider orientation="horizontal" />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" className="form-fields-title">
              ETA / ETD
            </Typography>
            <Grid item xs={12} className="form-fields-container">
              <Grid item xs={12} sm={4} className="form-field-datetime">
                <DateTimeField
                  name="planned"
                  label="Planned"
                  control={control}
                  required={ true }
                />
              </Grid>
              <Grid item xs={12} sm={4} className="form-field-datetime">
                <DateTimeField
                  name="arrival"
                  label="Arrival"
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={4} className="form-field-datetime">
                <DateTimeField
                  name="departure"
                  label="Departure"
                  control={control}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} marginTop={1} marginBottom={1}>
            <Divider orientation="horizontal" />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" className="form-fields-title">
              Operation Time
            </Typography>
            <Grid item xs={12} className="form-fields-container">
              <Grid item xs={12} sm={4} className="form-field-datetime">
                <DateTimeField
                  name="operationStart"
                  label="Started"
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={4} className="form-field-datetime">
                <DateTimeField
                  name="operationEnd"
                  label="Finished"
                  control={control}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} marginTop={1} marginBottom={1}>
            <Divider orientation="horizontal" />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" className="form-fields-title">
              Operation
            </Typography>
            <Grid item xs={12} className='form-fields-container'>
                <Grid item xs={12} sm={4}>
                    <CheckboxField
                        name='loadingPlanLocked'
                        label='Lock loading plan'
                        control={ control }
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SelectField
                    name="dockingSide"
                    label="Docking side"
                    control={control}
                    options={[
                      {
                        value: DOCKING_SIDE_PORT,
                        label: 'Port',
                      },
                      {
                        value: DOCKING_SIDE_STARBOARD,
                        label: 'Starboard',
                      },
                    ]}
                  />
                </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} marginTop={1} marginBottom={1}>
            <Divider orientation="horizontal" />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" className="form-fields-title">
              Labels
            </Typography>
            <Grid item xs={12} sm={12} className="form-fields-container">
              <Grid item xs={12} sm={12}>
                <SelectMultipleField
                  name="labels"
                  label="Labels"
                  control={control}
                  options={GetLabelOptions(labels)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} marginTop={1} marginBottom={1}>
            <Divider orientation="horizontal" />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" className="form-fields-title">
              States
            </Typography>
            <Grid item xs={12} sm={12} className="form-fields-container">
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="arrival-state-content"
                  id="arrival-state-header"
                >
                  Arrival State
                </AccordionSummary>
                <AccordionDetails>
                    {terminalCall && terminalCall.arrivalState ? (
                      JSON.stringify(terminalCall.arrivalState)
                    ) : '' }
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12} sm={12} className="form-fields-container">
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="departure-state-content"
                  id="departure-state-header"
                >
                  Departure State
                </AccordionSummary>
                <AccordionDetails>
                {terminalCall && terminalCall.departureState ? (
                    JSON.stringify(terminalCall.departureState)
                  ) : '' }
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className="button-wrapper">
          <Grid container spacing={1}>
            <Grid item>
              <Button
                onClick={() => {
                  dispatch(hideModal());
                }}
                disabled={isSubmitting}
                variant="contained"
              >
                <CloseIcon fontSize="inherit" />
                &nbsp;Close
              </Button>
            </Grid>
            {container && (
              <Grid item>
                <Button
                  onClick={() =>
                    detachTerminalCall(container, loadingUnloading)
                  }
                  disabled={isSubmitting}
                  variant="contained"
                  color="error"
                >
                  <DeleteIcon fontSize="inherit" />
                  &nbsp;Unlink container
                </Button>
              </Grid>
            )}
            { terminalCall && (
              <Grid item>
                <Button
                  onClick={() => {dispatch(removeTerminalCall(terminalCall))}}
                  disabled={isSubmitting}
                  variant="contained"
                  color="error"
                >
                  <DeleteIcon fontSize="inherit" />
                  &nbsp;Delete
                </Button>
              </Grid>
            )}
          </Grid>
          <Button type="submit" disabled={isSubmitting} variant="contained">
            <SaveIcon fontSize="inherit" />
            &nbsp;Save
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default TerminalCallForm;
