import axios from "axios";
import { getContainers } from "./containers";
import { hideModal } from "../data/modal";

/*****************/
/* INITIAL STATE */
/*****************/
const initialState = {
  loading: false,
  error: "",
  data: [],
  query: { vessel: null, total: null },
};

/*********/
/* TYPES */
/*********/
const FETCH_TERMINALCALLS = "FETCH_TERMINALCALLS";
const FETCH_TERMINALCALLS_SUCCESS = "FETCH_TERMINALCALLS_SUCCESS";
const FETCH_TERMINALCALLS_ERROR = "FETCH_TERMINALCALLS_ERROR";
const PUT_TERMINALCALL = "PUT_TERMINALCALL";
const POST_TERMINALCALL = "POST_TERMINALCALL";
const DELETE_TERMINALCALL = "DELETE_TERMINALCALL";
const FETCH_NEXT_TERMINALCALLS = "FETCH_NEXT_TERMINALCALLS";
const FETCH_NEXT_TERMINALCALLS_SUCCESS = "FETCH_NEXT_TERMINALCALLS_SUCCESS";

/*******************/
/* ACTION CREATORS */
/*******************/
export const getTerminalCalls = (vessel) => async (dispatch, getState) => {
  const {
    containers: { query, data },
  } = getState();

  if (vessel !== query.vessel || data.length !== query.total) {
    dispatch(loadTerminalCalls());

    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/terminal_calls?page=1${
        vessel ? `&vessel=${vessel["@id"]}` : ""
      }`,
      headers: {
        "Content-Type": "application/ld+json; charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (response.data["hydra:member"].length === 0) {
      dispatch(setError("No terminalcalls found"));
    } else {
      dispatch(
        setTerminalCalls(response.data["hydra:member"], {
          vessel: vessel,
          total: response.data["hydra:totalItems"],
        })
      );
      if (
        response.data &&
        response.data["hydra:view"] &&
        response.data["hydra:view"] !== undefined &&
        response.data["hydra:view"]["hydra:next"] &&
        response.data["hydra:view"]["hydra:next"] !== undefined
      ) {
        dispatch(
          getNextTerminalCalls(response.data["hydra:view"]["hydra:next"])
        );
      }
    }
  }
};

export const getNextTerminalCalls = (nextUrl) => async (dispatch) => {
  dispatch(loadNextTerminalCalls());

  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL}${nextUrl}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  dispatch(setNextTerminalCalls(response.data["hydra:member"]));

  if (
    response.data &&
    response.data["hydra:view"] &&
    response.data["hydra:view"] !== undefined &&
    response.data["hydra:view"]["hydra:next"] &&
    response.data["hydra:view"]["hydra:next"] !== undefined
  ) {
    dispatch(getNextTerminalCalls(response.data["hydra:view"]["hydra:next"]));
  }
};

export const updateTerminalCall =
  (terminalCall) => async (dispatch, getState) => {
    dispatch(putTerminalCall());
    const {
      terminalCalls: { data, query },
    } = getState();

    const response = await axios({
      method: "put",
      url: `${process.env.REACT_APP_URL}${terminalCall["@id"]}`,
      headers: {
        "Content-Type": "application/ld+json; charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: terminalCall,
    });

    if (response.status === 200) {
      const resultData = data.map((terminalCall) => {
        if (terminalCall["@id"] === response.data["@id"]) {
          return response.data;
        }

        return terminalCall;
      });

      dispatch(setTerminalCalls(resultData, query));
      dispatch(hideModal());
    } else {
      setError("Something went wrong updating");
    }
  };

export const createTerminalCall =
  (terminalCall) => async (dispatch, getState) => {
    dispatch(postTerminalCall());
    const {
      terminalCalls: { data, query },
    } = getState();

    const response = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/terminal_calls`,
      headers: {
        "Content-Type": "application/ld+json; charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: terminalCall,
    });

    if (response.status === 201) {
      const resultData = [...data, ...[response.data]];

      dispatch(setTerminalCalls(resultData, query));
      dispatch(hideModal());
    } else {
      setError("Something went wrong deleting");
    }
  };

export const removeTerminalCall =
  (terminalCall) => async (dispatch, getState) => {
    dispatch(putTerminalCall());
    const {
      terminalCalls: { data, query },
    } = getState();

    const response = await axios({
      method: "delete",
      url: `${process.env.REACT_APP_URL}${terminalCall["@id"]}`,
      headers: {
        "Content-Type": "application/ld+json; charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (response.status === 204) {
      const resultData = data.filter((x) => x["@id"] !== terminalCall["@id"]);

      dispatch(setTerminalCalls(resultData, query));
      //TODO REMOVE
      // if (
      //   terminalCall.loadingContainers.length > 0 ||
      //   terminalCall.unloadingContainers.length > 0
      // ) {
      //   dispatch(getContainers());
      // }
      dispatch(hideModal());
    } else {
      setError("Something went wrong deleting");
    }
  };

export const loadTerminalCalls = () => ({ type: FETCH_TERMINALCALLS });

export const putTerminalCall = () => ({ type: PUT_TERMINALCALL });

export const postTerminalCall = () => ({ type: PUT_TERMINALCALL });

export const deleteTerminalCall = () => ({ type: DELETE_TERMINALCALL });

export const setTerminalCalls = (terminalcalls, query) => ({
  type: FETCH_TERMINALCALLS_SUCCESS,
  payload: { terminalcalls, query },
});

export const loadNextTerminalCalls = (page) => ({
  type: FETCH_NEXT_TERMINALCALLS,
  payload: page,
});
export const setNextTerminalCalls = (terminalcalls) => ({
  type: FETCH_NEXT_TERMINALCALLS_SUCCESS,
  payload: terminalcalls,
});

export const setError = (msg) => ({
  type: FETCH_TERMINALCALLS_ERROR,
  payload: msg,
});

/***********/
/* REDUCER */
/***********/
const terminalcallReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_TERMINALCALLS:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_TERMINALCALLS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        data: payload.terminalcalls,
        query: payload.query,
      };

    case PUT_TERMINALCALL:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case POST_TERMINALCALL:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case DELETE_TERMINALCALL:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_TERMINALCALLS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case FETCH_NEXT_TERMINALCALLS:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_NEXT_TERMINALCALLS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, ...payload],
      };
    default:
      return state;
  }
};
export default terminalcallReducer;
