import React, {useState} from 'react';

import {
  Box,
  Grid,
  Tabs, 
  Tab, 
} from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export default function TabView({ views }) {
  const [value, setValue] = useState(0);
  
  if (!views) {
    return ''
  }

  const CustomTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
  
    return (
      <Grid
        key={`tab-view-content-${ index }`}
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        //sx={{ minHeight: '42vh' }}
      >
        {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
      </Grid>
    );
  }
  
  const tabProps = (index: number) => {
    return {
      id: `tab-view-${index}`,
      'aria-controls': `tab-view-tabpanel-${index}`,
    };
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const RenderTabs = ({views}) => {
    return (
      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        { views.map((view, index) => (
          <Tab label={view.label} {...tabProps(index)} key={`tab-view-${ index }`}/>
        ))}
      </Tabs>
    )
  }
  
  const RenderTabContent = ({views}) => {
    return (
      <>
        { views.map((view, index) => (
          <CustomTabPanel value={value} index={index}>
            { view.content }
          </CustomTabPanel>
        ))}
      </>
    )
  }

  return (
    <Grid item sx={{ width: '100%' }}>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <RenderTabs views={ views }/>
        </Box>
        <RenderTabContent views={ views }/>
      </Box>
    </Grid>
  );
}