import { Tenant } from "./Tenant";
import { Item } from "./item";

export class Vessel implements Item {
  public "@id"?: string;

  constructor(
    _id?: string,
    public name?: string,
    public mmsi?: string,
    public euid?: string,
    public tenant?: Tenant[]| string[],
    public linkedVessel?: Vessel[]| string[],
  ) {
    this["@id"] = _id;
  }
}
