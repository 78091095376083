import * as React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { ContainerType } from "../../types/ContainerType";
import { InputTextField } from "../FormFields/RenderInputTextField";
import { NumberField } from "../FormFields/RenderNumberField";
import {
  createContainerType,
  updateContainerType,
} from "../../data/containertype";
import { useDispatch, useSelector } from "react-redux";
import { hideModal, hideChildModal } from "../../data/modal";

import { Button, Grid } from "@mui/material";

import { Close as CloseIcon, Save as SaveIcon } from "@mui/icons-material";

const ContainerTypeForm = ({ containerType, callback }) => {
  const dispatch = useDispatch();
  const selectedData = useSelector((state) => ({
    modal: state.modal,
  }));

  const { handleSubmit, control, formState } = useForm({
    defaultValues: containerType ?? new ContainerType(),
  });
  const { isSubmitting } = formState;

  const onSubmit: SubmitHandler<ContainerType> = (data) => {
    if (containerType) {
      dispatch(updateContainerType(data));
    } else {
      dispatch(createContainerType(data, callback));
    }
  };

  const handleCloseForm = () => {
    if (selectedData.modal.childModal) {
      dispatch(hideChildModal());
    } else {
      dispatch(hideModal());
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container xs={12}>
        <Grid item xs={12} sm={12}>
          <Grid item xs={12} className="form-fields-container">
            <Grid item xs={12} sm={6}>
              <InputTextField
                name="name"
                label="Name"
                control={control}
                required
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <InputTextField
                name="abbreviation"
                label="Abbreviation"
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <NumberField name="teu" label="TEU Size" control={control} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className="button-wrapper">
          <Button
            onClick={handleCloseForm}
            disabled={isSubmitting}
            variant="contained"
          >
            <CloseIcon fontSize="inherit" />
            &nbsp;Close
          </Button>
          <Button type="submit" disabled={isSubmitting} variant="contained">
            <SaveIcon fontSize="inherit" />
            &nbsp;Save
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ContainerTypeForm;
