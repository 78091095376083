import * as React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Controller } from "react-hook-form";

export const SelectField: any = ({
  name,
  control,
  label,
  options,
  required = false,
}) => {
  if (!options) {
    return <></>;
  }

  const generateOptions = () => {
    return options.map((option: any) => {
      return (
        <MenuItem key={`key-${option.value}`} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };

  return (
    <FormControl size={"small"} sx={{ width: '100%' }}>
      <InputLabel>{`${label}${ required ? '*' : ''}`}</InputLabel>
      <Controller
        render={({ field: { onChange, value } }) => (
          <Select 
            onChange={onChange} 
            value={ value && value !== undefined ? (value['@id'] ? value['@id'] : value) : '' } 
            required={required} 
            fullWidth
          >
            <MenuItem key='All' value={ null }>
              Select
            </MenuItem>
            { options.length > 0 ? generateOptions() : '' }
          </Select>
        )}
        control={control}
        name={name}
        defaultValue={undefined}
      />
    </FormControl>
  );
};