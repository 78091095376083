import React from "react";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { hideModal } from "../../data/modal";
import { showModal } from "../../data/modal";
import { RenderLabels, findObjectById } from "../Helpers.tsx";
import TabView from "../partial/TabView";
import FileList from "../partial/FileList";

import {
  Button,
  Divider,
  Grid,
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableRow,
} from "@mui/material";

import {
  Close as CloseIcon,
  Edit as EditIcon,
} from "@mui/icons-material";

const ContainerModal = (properties: any, show:boolean) => {
  const dispatch = useDispatch();
  const container = properties.container;
  const terminals = properties.terminals;
  const containerTypes = properties.containerTypes;
  const vessels = properties.vessels;
  const terminalCalls = properties.terminalCalls;
  const labels = properties.labels;

  const getContainerVessel = (vesselId:any) => {
    return findObjectById(vessels, vesselId)
  }

  const NO_CONTAINER_COLOR = '#FFFFFF';

  if (!properties.container) {
    return
  }

  return (
      <Grid container sx={{ minWidth: '35vh', width: '80vh' }}>
        <TableContainer>
          <Table aria-label="container table" className='container-modal-table'>
            <TableBody>
              { container.labels && container.labels.length > 0 ? ( 
                <TableRow>
                  <TableCell>Labels:</TableCell>
                  <TableCell>{ RenderLabels(container.labels) }</TableCell>
                </TableRow>
              ) : ''}
              <TableRow>
                  <TableCell>Weight:</TableCell>
                  <TableCell>{ container.weight && container.weight > 0 ? (container.weight/1000).toFixed(3) : 0}T</TableCell>
              </TableRow>
              <TableRow>
                  <TableCell>Type:</TableCell>
                  <TableCell>{ container.containerType && container.containerType.name ?  `${ container.containerType.abbreviation ? `${ container.containerType.abbreviation } - ` : '' }${ container.containerType.name }` : ''}</TableCell>
              </TableRow>
              <TableRow>
                  <TableCell>TEU:</TableCell>
                  <TableCell>{ container.containerType && container.containerType.teu ?  container.containerType.teu : ''}</TableCell>
              </TableRow>
              <TableRow>
                  <TableCell>Loading Terminal:</TableCell>
                  <TableCell>
                    { container.loadingTerminal || container.loadingTerminalCall ? (
                       <Grid className='container-planner-terminal-color' item sx={{ 
                        backgroundColor: container.loadingTerminal && container.loadingTerminalCall.terminal && container.loadingTerminalCall.terminal.color ? container.loadingTerminalCall.terminal.color : (container.loadingTerminal && container.loadingTerminal.color ? container.loadingTerminal.color : NO_CONTAINER_COLOR),
                      }}></Grid>
                    ) : ''}
                    <Grid item sx={{ fontWeight: '600' }}>
                      { container.loadingTerminalCall && container.loadingTerminalCall.terminal ? container.loadingTerminalCall.terminal.name : (container.loadingTerminal ? container.loadingTerminal.name : '')}
                    </Grid>
                    { container.loadingTerminalCall ? (
                      <>
                        &nbsp;
                        { container.loadingTerminalCall.planned ? `Planned on ${ dayjs(container.loadingTerminalCall.planned).format("DD/MM/YYYY HH:mm") }` : "Not yet planned." }
                      </>
                    ) : ''}
                  </TableCell>
              </TableRow>
              <TableRow>
                  <TableCell>Loading Closure:</TableCell>
                  <TableCell>{ container.loadingTerminalClosure ? dayjs(container.loadingTerminalClosure).format("DD/MM/YYYY HH:mm") : ""}</TableCell>
              </TableRow>
              <TableRow>
                  <TableCell>Unloading Terminal:</TableCell>
                  <TableCell>
                    { container.unloadingTerminal || container.unloadingTerminalCall ? (
                       <Grid className='container-planner-terminal-color' item sx={{ 
                        backgroundColor:  container.unloadingTerminalCall && container.unloadingTerminalCall.terminal && container.unloadingTerminalCall.terminal.color ? container.unloadingTerminalCall.terminal.color : (container.unloadingTerminal && container.unloadingTerminal.color ? container.unloadingTerminal.color : NO_CONTAINER_COLOR),
                      }}></Grid>
                    ) : ''}
                    <Grid item sx={{ fontWeight: '600' }}>
                      { container.unloadingTerminalCall && container.unloadingTerminalCall.terminal ? container.unloadingTerminalCall.terminal.name : (container.unloadingTerminal ? container.unloadingTerminal.name : '')}
                    </Grid>
                    { container.unloadingTerminalCall ? (
                      <>
                        ,&nbsp;
                        { container.unloadingTerminalCall.planned ? `Planned on ${ dayjs(container.unloadingTerminalCall.planned).format("DD/MM/YYYY HH:mm") }` : "Not yet planned." }
                      </>
                    ) : ''}
                  </TableCell>
              </TableRow>
              <TableRow>
                  <TableCell>Unloading Closure:</TableCell>
                  <TableCell>{ container.unloadingTerminalClosure ? dayjs(container.unloadingTerminalClosure).format("DD/MM/YYYY HH:mm"): "" }</TableCell>
              </TableRow>
              { container.position &&  container.position.length >= 3 ? (
                <TableRow>
                    <TableCell>Position:</TableCell>
                    <TableCell>{ `${ getContainerVessel(`/api/vessels/${container.position[3]}`).name } - Layer: ${ container.position[0] } Row: ${ container.position[1] } Position: ${ container.position[2] } `}</TableCell>
                </TableRow>
              ) : ''}
            </TableBody>
          </Table>
        </TableContainer>
        <TabView views={[
          {
            label: 'Files',
            content: <FileList files={container.fileGroup && container.fileGroup.files ? container.fileGroup.files : []}/>,
          },
          {
            label: 'Notes',
            content: undefined,
          }
        ]}/>

        <Grid item xs={12} sm={12} marginTop={1} marginBottom={1}>
            <Divider orientation="horizontal" />
        </Grid>

        <Grid item xs={12} className='button-wrapper' sx={{ justifyContent: 'space-between' }}>
          <Button 
              onClick={() => {dispatch(hideModal())}}
              variant='contained'
          >
              <CloseIcon fontSize="inherit" />&nbsp;Close
          </Button>
          <Button 
              onClick={() => dispatch(
                showModal({
                  title: "Edit container",
                  description: "Edit container",
                  form: "container",
                  formProps: {
                    container: container,
                    terminals: terminals,
                    terminalCalls: terminalCalls,
                    vessels: vessels,
                    containerTypes: containerTypes,
                    labels: labels,
                  },
                })
              )}
              variant='contained'
          >
              <EditIcon fontSize="inherit" />&nbsp;Edit
          </Button>
        </Grid>
      </Grid>
  );
}

export default ContainerModal;
