import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../Layout.tsx";
import { getVessels } from "../../../data/vessels";
import { Vessel } from "../../../types/Vessel";
import Modal from "../../Modal.tsx";
import { showModal } from "../../../data/modal";
import SettingsMenu from "../Menu";

import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Typography,
} from "@mui/material";

import { 
  Edit as EditIcon,
  SettingsSharp as SettingsSharpIcon,
} from "@mui/icons-material";

const VesselManage = () => {
  const dispatch = useDispatch();

  const selectedData = useSelector((state) => ({
    vessels: state.vessels,
  }));

  useEffect(() => {
    dispatch(getVessels());
  }, [dispatch]);

  const { vessels } = selectedData;
  
  return (
    <Layout>
    <Modal/>
    <Grid container>
      <Grid item xs={12} sm={2} padding={1}>
          <Grid item component={ Paper }  sx={{ height: 'auto' }}>
            <SettingsMenu activeMenu={ 'vessel' }/>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={10} padding={1}>
          <Grid item component={ Paper }  sx={{ height: 'auto' }}>
            <Grid className='settings-page-header' item xs={12} sm={12} padding={1}>
            <Grid item>
              <Typography variant="h6" gutterBottom>
                Vessel
              </Typography>
            </Grid>
            <Grid item>
              <Button 
                  variant='contained'
                  size='small'
                  onClick={() => {
                    dispatch(
                      showModal({
                        title: "Add vessel",
                        description: "Creates a new vessel",
                        form: "vessel",
                        formProps: { vessels: vessels.data },
                      })
                    );
                  }}
                >
                Add Vessel
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} padding={1} sx={{ height: '80vh' }}>
            <TableContainer>
              <Table aria-label="terminal table" stickyHeader>
                <TableHead>
                    <TableRow>
                      <TableCell>
                        Name
                      </TableCell>
                      <TableCell align='center'>
                        EUID
                      </TableCell>
                      <TableCell align='center'>
                        MMSI
                      </TableCell>
                      <TableCell align='right'></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                      { vessels && vessels.data.length !== 0 && vessels.data.map((vessel: Vessel) => (
                        <TableRow className='js-collection-item' data-object-id={ vessel['@id'] }>
                          <TableCell>{ vessel.name  }</TableCell>
                          <TableCell align='center'>{ vessel.euid }</TableCell>
                          <TableCell align='center'>{ vessel.mmsi }</TableCell>
                          <TableCell align='right'>
                            <IconButton 
                              onClick={() => {
                                dispatch(
                                  showModal({
                                    title: "Vessel grid",
                                    description: "Vessel grud settings",
                                    form: "vesselgridsettings",
                                    formProps: { vessel: vessel },
                                  })
                                );
                              }}
                            >
                              <SettingsSharpIcon size='small'/> 
                            </IconButton>
                            <IconButton 
                              onClick={() => {
                                dispatch(
                                  showModal({
                                    title: "Edit vessel",
                                    description: "Edit a vessel",
                                    form: "vessel",
                                    formProps: { vessel: vessel, vessels: vessels.data },
                                  })
                                );
                              }}
                            >
                              <EditIcon size='small'/> 
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    </Layout>
  );
};

export default VesselManage;
