import { Note } from "./Note";
import { Terminal } from "./Terminal";
import { Vessel } from "./Vessel";
import { Container } from "./Container";
import { Item } from "./item";

export class TerminalCall implements Item {
  public "@id"?: string;

  constructor(
    _id?: string,
    public planned?: Date,
    public arrival?: Date,
    public departure?: Date,
    public terminal?: Terminal[]| string[],
    public vessel?: Vessel[]| string[],
    public notes?: Note[]| string[],
    public loadingContainers?: Container[]| string[],
    public unloadingContainers?: Container[]| string[],
  ) {
    this["@id"] = _id;
  }
}
