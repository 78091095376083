import React, { useCallback, useState } from 'react';
import { Document, Page, pdfjs} from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { useDispatch } from "react-redux";
import { hideModal } from "../../data/modal";
import { removeFile} from "../../data/files";

import {
    Box,
    Button,
    Divider,
    Grid,
    Typography,
} from "@mui/material";

import {
    Close as CloseIcon,
    DeleteSharp as DeleteSharpIcon,
} from '@mui/icons-material';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

const FileView = ({file, includeButtons = true}) => {
    const dispatch = useDispatch();
    const regex = /(?:\.([^.]+))?$/;
    const extension = regex.exec(file.contentUrl)[1];
    const options = {
        cMapUrl: '/cmaps/',
        standardFontDataUrl: '/standard_fonts/',
    };

    const [numPages, setNumPages] = useState<number>();
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [searchText, setSearchText] = useState('');

    const highlightPattern = (text, pattern) => {
        return text.replace(pattern, (value) => `<mark>${value}</mark>`);
    }

    const onChange = (event) => {
    setSearchText(event.target.value);
    }

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
        setNumPages(numPages);
        setPageNumber(pageNumber);
    }
    
    const textRenderer = useCallback(
        (textItem) => highlightPattern(textItem.str, searchText),
        [searchText]
    );
    
    const changePage = (offset) => {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }
    
    const previousPage = () => {
        changePage(-1);
    }
    
    const nextPage = () => {
        changePage(1);
    }
    
    return (
        <Grid container>
            <Grid item xs={12}  sx={{ maxWidth: `${ includeButtons ? '80vh' : '50vh' }  !important`, overflow: 'auto' }}>
            { extension === 'pdf' ? (
                 <>
                 <Box sx={{ display: "flex", justifyContent: 'space-between', alignItems:'center' }}>
                   <Grid item>
                    <label htmlFor="search">Search:</label>
                    <input type="search" id="search" value={searchText} onChange={onChange} />
                   </Grid>
                   <Grid item>
                    <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                      { numPages > 1 ? (
                        <>
                          <Button
                            type="button"
                            disabled={pageNumber <= 1}
                            onClick={previousPage}
                          >
                            Previous
                          </Button>
                          <Typography fontSize={14}>
                            Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                          </Typography>
                          <Button
                            type="button"
                            disabled={pageNumber >= numPages}
                            onClick={nextPage}
                          >
                            Next
                          </Button>
                        </>
                      ) : ''}
                    </Box>
                   </Grid>
                 </Box>
                 <hr/>
                 <Box sx={{ maxHeight: '700px', overflow: 'scroll' }}>
                   <Document file={file.contentUrl}  onLoadSuccess={onDocumentLoadSuccess} options={options} style={{ color: 'white', textAlign: 'center', margin: 30, overflow: "scroll", height: 700 }}>
                     <Page size="A4" pageNumber={pageNumber} customTextRenderer={textRenderer}/>
                   </Document>
                 </Box>
               </>
            ) : '' }
            { ['png', 'jpeg', 'jpg'].includes(extension) ? (
                <img src={`${ file.contentUrl }`} height={ includeButtons ? 700 : 500 } alt="fileImage"/>
            ) : '' }
            </Grid>
            { includeButtons ? (
              <>
                <Grid item xs={12}>
                  <Divider/>
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: '5px' }}>
                    <Button 
                        onClick={() => {dispatch(hideModal())}}
                        variant='contained'
                    >
                        <CloseIcon fontSize="inherit" />&nbsp;Close
                    </Button>
                    {/* <Button 
                        variant='contained'
                        color='error'
                        onClick={() => {dispatch(removeFile(file))}}
                    >
                        <DeleteSharpIcon fontSize="inherit" />&nbsp;Delete
                    </Button> */}
                </Grid>
              </>
            ) : ''}
        </Grid>
    )
}

export default FileView
