import "../../styles/teminalcallweights.css";
import React from "react";
import { Grid, Typography } from "@mui/material";
import { getTerminalCallLoadingContainerIds } from "../Helpers.tsx";

export default function TerminalCallWeightsAvailable({
  terminalCall, 
  containers = [],
}) {
  if (terminalCall === undefined || containers.length === 0) {
    return '';
  }
  const filteredContainerIds = getTerminalCallLoadingContainerIds(terminalCall)
  const filteredContainers = containers.filter((container) => container.position !== undefined && filteredContainerIds.includes(container['@id']))

  const vessel = terminalCall.vessel;
  const barge = vessel.linkedVessel;
  let totalTeu = (vessel.maxTeu ?? 0) + (barge && barge.maxTeu ? barge.maxTeu : 0);
  let totalWeightKg = (vessel.maxWeight ?? 0) + (barge && barge.maxWeight ? barge.maxWeight : 0);

  const containerTeu = filteredContainers.reduce(
    (accumulator, container) =>
      accumulator +
      (container.containerType.teu ? container.containerType.teu : 0),
    0
  );

  const containerWeightKg = filteredContainers.reduce(
    (accumulator, container) =>
      accumulator +
      (container.weight ? container.weight : 0),
    0
  );

  return (
    <Grid className='terminal-call-weights-wrapper' item>
      <Grid item className='terminal-call-weights'>
        <Typography className={ `${containerTeu > totalTeu ? 'terminal-call-weights-overflow' : ''}`}>
          { `${totalTeu - containerTeu}`}
        </Typography>
        <Typography>
          &nbsp;{ `of ${totalTeu} TEU available`}
        </Typography>
      </Grid>
      <Grid item className='terminal-call-weights'>
        <Typography className={ `${containerWeightKg > totalWeightKg ? 'terminal-call-weights-overflow' : ''}`}>
          { totalWeightKg > 0 ? ((totalWeightKg - containerWeightKg)/1000).toFixed(3) : 0}
        </Typography>
        <Typography>
          &nbsp;{ `of ${totalWeightKg > 0 ? (totalWeightKg/1000).toFixed(3) : 0} T available`}
        </Typography>
      </Grid>
    </Grid>
  );
}
