import "../../styles/planner.css";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../Layout.tsx";
import { getTerminalCalls, updateTerminalCall } from "../../data/terminalcall";
import { getVessels } from "../../data/vessels";
import { getContainers, updateContainer } from "../../data/containers";
import { TerminalCall } from "../../types/TerminalCall";
import { getTerminals } from "../../data/terminals";
import { getLabels } from "../../data/labels";
import { getContainerTypes } from "../../data/containertype";
import { Vessel } from "../../types/Vessel";
import { Container } from "../../types/Container";
import { showModal } from "../../data/modal";
import Modal from "../Modal.tsx";
import TerminalCallDetail from "../partial/TerminalCallDetail";
import ContainerCounts from "../partial/ContainerCounts";
import VesselLoadStability from "../partial/VesselLoadStability";
import { getContainerSearchParams, highlightContainer, highlightTerminal } from "../partial/ContainerSearch";

import {
  containerUidFormatter,
  findObjectById,
  formatDateToString,
  getTerminalCallArrivalStateContainerIds,
  getTerminalCallLoadingContainerIds,
  textColorHandler,
} from "../Helpers.tsx";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Select,
  Menu,
  MenuItem,
  Snackbar,
  Alert,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

import {
  Add as AddIcon,
  Comment as CommentIcon,
  Edit as EditIcon,
  ExpandMore as ExpandMoreIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Map as MapIcon,
  Refresh as RefreshIcon,
  RadioButtonChecked as RadioButtonCheckedIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
  RotateLeftSharp as RotateLeftSharpIcon,
} from "@mui/icons-material";

const NO_CONTAINER_COLOR = "#FFFFFF";
const DEFAULT_CONTAINER_COLOR = "#FFE4C4";
const LOADING_CONTAINER_COLOR = "loading_color";
const UNLOADING_CONTAINER_COLOR = "unloading_color";
const PLAN_TYPE_LOADING = "loadingTerminalCall";
const PLAN_TYPE_UNLOADING = "unloadingTerminalCall";
const DOCKING_SIDE_STARBOARD = "starboard";
const DOCKING_SIDE_PORT = "port";

const ContainerPlanner = () => {
  const searchedContainerParams = getContainerSearchParams();
  const dispatch = useDispatch();
  const draggedContainer = useRef();
  const [dockingSide, setDockingSide] = useState(DOCKING_SIDE_PORT);
  const [containerData, setContainerData] = useState<any[]>([]);
  const [terminalCallData, setTerminalCallData] = useState([]);
  const [planType, setPlanType] = useState(PLAN_TYPE_LOADING);
  const [containerColorType, setContainerColorType] = useState(
    UNLOADING_CONTAINER_COLOR
  );
  const [snackbar, setSnackbar] = useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectedData = useSelector((state) => ({
    terminalCalls: state.terminalCalls,
    vessels: state.vessels,
    containers: state.containers,
    terminals: state.terminals,
    containerTypes: state.containerTypes,
    labels: state.labels,
  }));

  const [selectedVessel, setSelectedVessel] = useState(undefined);
  const [selectedVesselForPlanner, setSelectedVesselForPlanner] =
    useState(undefined);
  const [selectedTerminalCall, setSelectedTerminalCall] = useState(undefined);

  useEffect(() => {
    dispatch(getVessels(true));
    dispatch(getTerminals());
    dispatch(getTerminalCalls());
    dispatch(getContainerTypes());
    dispatch(getContainers());
    dispatch(getLabels());
  }, [dispatch]);

  const {
    vessels,
    terminals,
    terminalCalls,
    containerTypes,
    labels,
    containers,
  } = selectedData;

  const getUpdatedTerminalCall = () => {
    if (selectedTerminalCall) {
      return findObjectById(terminalCalls.data, selectedTerminalCall["@id"]);
    }

    return undefined;
  };

  const findTerminalCallsByVessel = (vessel: Vessel) => {
    return terminalCalls.data.filter(
      (terminalCall: TerminalCall) =>
        terminalCall.vessel["@id"] === vessel["@id"]
    );
  };

  const filterMainVessels = () => {
    return vessels.data.filter((vessel: Vessel) => !vessel.barge);
  };

  useEffect(() => {
    if (!selectedVessel && vessels.data && vessels.data.length > 0) {
      setSelectedVessel(filterMainVessels()[0]);
      setSelectedVesselForPlanner(filterMainVessels()[0]);
    }

    if (selectedVessel) {
      setTerminalCallData(findTerminalCallsByVessel(selectedVessel));
    }

    if (selectedTerminalCall) {
      setSelectedTerminalCall(
        findObjectById(
          terminalCalls.data, 
          selectedTerminalCall["@id"]
        )
      );
    }

    if (selectedVesselForPlanner && selectedTerminalCall) {
      let containerIds = [];

      if (planType === PLAN_TYPE_LOADING) {
        containerIds = getTerminalCallLoadingContainerIds(selectedTerminalCall);
      }

      if (planType === PLAN_TYPE_UNLOADING) {
        containerIds = selectedTerminalCall.unloadingContainers;
      }

      if (containerIds.length !== 0) {
        let foundcontainers = containerIds.map(id => findObjectById(containers.data, id)).filter(Boolean)
// Check if length of both arrays are the same before continuing, check if loading for containerdata is finished before entering this block
        console.log(foundcontainers, containerIds)
        setContainerData(foundcontainers)
      }
    }
  }, [vessels, selectedVesselForPlanner, selectedTerminalCall, planType]);

  const selectedVesselHandler = (e: any) => {
    const value = e.target.value;

    if (!value) {
      return;
    }

    const vessel = findObjectById(vessels.data, value);

    setSelectedTerminalCall(undefined);
    setSelectedVessel(vessel);
    setSelectedVesselForPlanner(vessel);
    setTerminalCallData(findTerminalCallsByVessel(vessel));
  };

  const selectedVesselForPlannerHandler = (
    event: React.MouseEvent<HTMLElement>,
    vessel: Vessel
  ) => {
    setSelectedVesselForPlanner(vessel);
  };

  const terminalPlanningFormatter = () => {
    const formattedTerminalCallList = [];

    if (!terminalCalls.data || terminalCalls.error) {
      return formattedTerminalCallList;
    }

    terminalCallData.sort(function (a: any, b: any) {
      return Number(new Date(a.planned)) - Number(new Date(b.planned));
    });

    terminalCallData.map((termincalCall: TerminalCall) => {
      formattedTerminalCallList[formatDateToString(termincalCall.planned)] ??=
        [];
      formattedTerminalCallList[formatDateToString(termincalCall.planned)].push(
        termincalCall
      );
    });

    return formattedTerminalCallList;
  };

  const selectedTerminalCallHandler = (e: any) => {
    const element = e.target.closest(".js-terminal-call-parent");
    const terminalCallId = element.getAttribute("data-terminal-call-id");

    if (!terminalCallId) {
      return;
    }
    const terminalCall = findObjectById(terminalCalls.data, terminalCallId);
    setSelectedTerminalCall(terminalCall);
    setDockingSide(terminalCall?.dockingSide ?? DOCKING_SIDE_PORT);
  };

  const hasPositionBarrier = (axis: any) => {
    let requirementsObtained: any = [];

    if (
      !selectedVessel ||
      !selectedVesselForPlanner ||
      selectedVesselForPlanner.barriers.length === 0
    ) {
      return false;
    }

    selectedVesselForPlanner.barriers.map((barrierPosition: any) => {
      requirementsObtained.push(
        JSON.stringify(barrierPosition) === JSON.stringify(axis)
      );
    });

    return requirementsObtained.includes(true);
  };

  const dragStart = (e: any) => {
    draggedContainer.element = e;
    draggedContainer.id = e.target.getAttribute("data-container-id");
    draggedContainer.teu = e.target.getAttribute("data-container-teu");
  };

  const dragLeave = (e: any) => {
    e.target.classList.remove("drag-over-grid");
  };

  const dragOver = (e: any) => {
    if (!e.target.getAttribute("data-container-list")) {
      e.target.classList.add("drag-over-grid");
    }
    e.preventDefault();
  };

  const containerStateHandler = (e: any) => {
    e.preventDefault();

    if (!draggedContainer) {
      return;
    }

    let position = null;
    let hasBarrierOverflow: any = [];
    const containerId = draggedContainer.id;
    const containerTeu = parseInt(draggedContainer.teu);
    const layer = e.target.getAttribute("data-grid-layer");
    const x = e.target.getAttribute("data-grid-x");
    const y = e.target.getAttribute("data-grid-y");
    const isContainerList = e.target.getAttribute("data-container-list");
    let containerObject = findObjectById(containerData, containerId);

    if (!containerObject) {
      return;
    }

    if (containerTeu > 1) {
      {
        [...Array((containerTeu === 4 ? 2 : containerTeu) - 1)].map((t, gx) => {
          hasBarrierOverflow.push(
            hasPositionBarrier([parseInt(x) + gx, parseInt(x) + (gx + 1)])
          );
        });
      }
    }

    if (hasBarrierOverflow.includes(true)) {
      e.target.classList.remove("drag-over-grid");
      setSnackbar({
        children: "Overlaping a barrier",
        severity: "warning",
        variant: "filled",
      });
      return;
    }

    if (layer && x && y && selectedVesselForPlanner) {
      position = [
        parseInt(layer),
        parseInt(x),
        parseInt(y),
        selectedVesselForPlanner["@id"].replace("/api/vessels/", ""),
      ];
    }

    if (
      !isContainerList &&
      containerObject.containerType &&
      containerObject.containerType.teu > 1
    ) {
      e.target.classList.add(
        `container-planner-grid-teu-${containerObject.containerType.teu}`
      );
    }

    if (
      !containerObject.vessel &&
      selectedTerminalCall &&
      selectedTerminalCall.vessel
    ) {
      containerObject = {
        ...containerObject,
        vessel: selectedVessel,
      };
    }

    dispatch(
      updateContainer({
        ...containerObject,
        position: position,
        vessel: selectedVessel,
      })
    );
  };

  const isContainerFromArrivalContainers = (
    container: Container | undefined
  ) => {
    if (container === undefined) {
      return false;
    }

    return (
      planType !== PLAN_TYPE_UNLOADING &&
      getTerminalCallArrivalStateContainerIds(selectedTerminalCall).includes(
        container["@id"]
      )
    );
  };

  // useEffect(() => {
  //   if (
  //     (planType === PLAN_TYPE_LOADING &&
  //       selectedTerminalCall &&
  //       selectedTerminalCall.loadingContainers.length === 0 &&
  //       getTerminalCallArrivalStateContainerIds(selectedTerminalCall).length ===
  //         0) ||
  //     (planType === PLAN_TYPE_UNLOADING &&
  //       selectedTerminalCall &&
  //       selectedTerminalCall.unloadingContainers.length === 0) ||
  //     containers.error
  //   ) {
  //     setContainerData([]);
  //   } else {
  //     setContainerData(containers.data);
  //   }
  // }, [containers]);

  const findContainerByPosition = (position) => {
    if (selectedVesselForPlanner) {
      position.push(
        selectedVesselForPlanner["@id"].replace("/api/vessels/", "")
      );
    }

    return containerData.find(
      (container: Container) =>
        container &&
        container.position &&
        JSON.stringify(container.position) === JSON.stringify(position)
    );
  };

  const numberOfContainersWithPosition = () => {
    if (
      !containerData ||
      containerData.length === 0 ||
      selectedTerminalCall === undefined
    ) {
      return 0;
    }

    const containersWithPosition = containerData.filter(
      (container: Container) =>
        container.position !== undefined &&
        selectedTerminalCall.loadingContainers.includes(container["@id"])
    );

    return containersWithPosition.length;
  };

  const containerDropHandler = (
    layer: any,
    xAxis: any,
    yAxis: any,
    gridCountPerXAxis: any,
    gridArray: any
  ) => {
    const draggedContainerTeu = draggedContainer.teu
      ? parseInt(draggedContainer.teu)
      : 1;
    const droppedContainer = findContainerByPosition([layer - 1, xAxis, yAxis]);
    const nextToDroppedContainer = findContainerByPosition([
      layer - 1,
      xAxis + 1,
      yAxis,
    ]);
    const previousTodroppedContainer = findContainerByPosition([
      layer - 1,
      xAxis - 1,
      yAxis,
    ]);
    const nextXAxisGridCount = gridArray[xAxis];
    const totalXAxisGrids = gridArray.length;

    if (draggedContainerTeu === 2 && droppedContainer) {
      if (droppedContainer.containerType.teu === 2) {
        return true;
      }

      if (
        droppedContainer.containerType.teu === 1 &&
        nextToDroppedContainer !== undefined &&
        nextToDroppedContainer.containerType.teu === 1
      ) {
        return true;
      }

      if (
        nextToDroppedContainer === undefined ||
        (droppedContainer.containerType.teu !== 1 &&
          nextToDroppedContainer.containerType.teu !== 1)
      ) {
        return false;
      }

      if (
        droppedContainer.containerType.teu === 1 &&
        nextToDroppedContainer.containerType.teu > 1
      ) {
        return false;
      }
    }

    if (
      draggedContainerTeu === 1 &&
      droppedContainer &&
      droppedContainer.containerType &&
      droppedContainer.containerType.teu > 1
    ) {
      return false;
    }

    if (
      draggedContainerTeu === 1 &&
      previousTodroppedContainer &&
      previousTodroppedContainer.containerType &&
      previousTodroppedContainer.containerType.teu > 1
    ) {
      return false;
    }

    if (
      draggedContainerTeu > 2 &&
      droppedContainer &&
      droppedContainer.containerType &&
      droppedContainer.containerType.teu >= 1
    ) {
      return false;
    }

    if (draggedContainerTeu > 3 && yAxis === parseFloat(gridCountPerXAxis)) {
      return false;
    }

    if (draggedContainerTeu === 3 && xAxis === 11) {
      return false;
    }

    if (
      draggedContainerTeu > 1 &&
      (xAxis === totalXAxisGrids ||
        (nextXAxisGridCount !== undefined &&
          xAxis === totalXAxisGrids - 1 &&
          nextXAxisGridCount < 4))
    ) {
      return false;
    }

    if (layer === 1 && !droppedContainer) {
      return true;
    }

    if (layer > 1 && droppedContainer) {
      return true;
    }

    return false;
  };

  const containerColorTypeChange = (e: any) => {
    setAnchorEl(null);
    setContainerColorType(e.target.getAttribute("data-container-color-type"));
  };

  const planTypeChange = (e: any) => {
    const planType = e.target.getAttribute("data-plan-type");
    setPlanType(planType);
    setContainerColorType(
      planType && planType === PLAN_TYPE_UNLOADING
        ? LOADING_CONTAINER_COLOR
        : UNLOADING_CONTAINER_COLOR
    );
  };

  const dockingSideChange = (
    event: React.MouseEvent<HTMLElement>,
    dockingSide: string
  ) => {
    setDockingSide(dockingSide);
  };

  const containerColorHandler = (container: Container | undefined) => {
    if (container === undefined) {
      return NO_CONTAINER_COLOR;
    }

    if (containerColorType === LOADING_CONTAINER_COLOR) {
      return container.loadingTerminalCall &&
        container.loadingTerminalCall.terminal
        ? container.loadingTerminalCall.terminal.color
        : container.loadingTerminal && container.loadingTerminal.color
        ? container.loadingTerminal.color
        : DEFAULT_CONTAINER_COLOR;
    }

    if (containerColorType === UNLOADING_CONTAINER_COLOR) {
      return container.unloadingTerminalCall &&
        container.unloadingTerminalCall.terminal
        ? container.unloadingTerminalCall.terminal.color
        : container.unloadingTerminal && container.unloadingTerminal.color
        ? container.unloadingTerminal.color
        : DEFAULT_CONTAINER_COLOR;
    }

    return DEFAULT_CONTAINER_COLOR;
  };

  const RenderGrid = (
    layer: any,
    xAxis: any,
    yAxis: any,
    gridCount: any,
    grids: any
  ) => {
    const containerWithPosition: Container | undefined =
      findContainerByPosition([layer, xAxis, yAxis]);
    const isContainerDroppable: boolean =
      containerWithPosition === undefined &&
      containerDropHandler(layer, xAxis, yAxis, gridCount, grids);
    const isAnArrivalContainer: boolean = isContainerFromArrivalContainers(
      containerWithPosition
    );

    return (
      <Grid
        item
        sx={{
          backgroundColor: `${containerColorHandler(
            containerWithPosition
          )} !important`,
          color: textColorHandler(containerColorHandler(containerWithPosition)),
        }}
        className={`container-planner-grid ${
          isAnArrivalContainer ? "grid-arrival-container" : ""
        } ${
          containerWithPosition &&
          containerWithPosition.containerType &&
          containerWithPosition.containerType.teu > 1
            ? `container-planner-grid-teu-${containerWithPosition.containerType.teu}`
            : ""
        }`}
        data-grid-layer={layer}
        data-grid-x={xAxis}
        data-grid-y={yAxis}
        onDrop={(e: any) =>
          isContainerDroppable ? containerStateHandler(e) : ""
        }
        onDragOver={(e: any) => (isContainerDroppable ? dragOver(e) : "")}
        onDragLeave={(e: any) => (isContainerDroppable ? dragLeave(e) : "")}
        onDragStart={(e: any) => dragStart(e)}
        data-container-id={
          containerWithPosition ? containerWithPosition["@id"] : undefined
        }
        data-container-teu={
          containerWithPosition && containerWithPosition.containerType
            ? containerWithPosition.containerType.teu
            : 1
        }
        draggable={
          !isAnArrivalContainer &&
          containerWithPosition !== undefined &&
          planType !== PLAN_TYPE_UNLOADING
        }
        onDoubleClick={() =>
          containerWithPosition !== undefined
            ? dispatch(
                showModal({
                  title: `${containerWithPosition.uid}`,
                  description: "Container information",
                  form: "containerviewmodal",
                  formProps: {
                    container: containerWithPosition,
                    vessels: vessels.data,
                    terminals: terminals.data,
                    terminalCalls: terminalCalls.data,
                    containerTypes: containerTypes.data,
                    labels: labels.data,
                    callback: setValue,
                  },
                })
              )
            : undefined
        }
      >
        {containerWithPosition ? (
          <>
          { highlightContainer(searchedContainerParams.searchedContainerIds, containerWithPosition["@id"]) }
          <Typography fontSize="inherit">
            {containerUidFormatter(containerWithPosition.uid).letters}
            <br />
            {containerUidFormatter(containerWithPosition.uid).numbers}
          </Typography>
          </>
        ) : (
          ""
        )}
      </Grid>
    );
  };

  const resetContainerPositions = () => {
    const loadingContainerIds = selectedTerminalCall.loadingContainers;
    const loadingContainers = containerData.filter(
      (container: Container) => container && container.position && loadingContainerIds.includes(container['@id'])
    )

    loadingContainers.map((container: Container) => {
      dispatch(updateContainer({
        ...container,
        position: null,
      }));
    })

    setSelectedTerminalCall(undefined)
  }

  //TODO: to be removed after a fix to update active state
  //
  const manualRefresh = () => {
    if (selectedTerminalCall) {
      dispatch(updateTerminalCall(selectedTerminalCall));
    }
  };
  //END

  const RenderTerminalDetail = (terminalCall: TerminalCall) => {
    return (
      <>
        <TerminalCallDetail terminalCall={terminalCall} />
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
          }}
        >
          {planType === PLAN_TYPE_LOADING ? (
            <Grid
              item
              className="planning-terminal-action"
              sx={{ width: "100% !important", margin: "5px 0 0 !important" }}
            >
              <ContainerCounts
                containers={containerData.filter((container) =>
                  terminalCall.loadingContainers.includes(container["@id"])
                )}
                containerTypes={containerTypes.data}
                loading={true}
              />
            </Grid>
          ) : (
            ""
          )}
          {planType === PLAN_TYPE_UNLOADING ? (
            <Grid
              item
              className="planning-terminal-action"
              sx={{ width: "100% !important", margin: "5px 0 0 !important" }}
            >
              <ContainerCounts
                containers={containerData.filter(
                  (container) =>
                    terminalCall.unloadingContainers.includes(container["@id"]),
                  false
                )}
                containerTypes={containerTypes.data}
                loading={false}
              />
            </Grid>
          ) : (
            ""
          )}
        </Grid>
        <Grid
          item
          className="container-planner-terminal-information-action-buttons"
        >
          <IconButton
            onClick={() =>
              dispatch(
                showModal({
                  title: `${
                    terminalCall.terminal.abbreviation
                      ? `${terminalCall.terminal.abbreviation} - `
                      : ""
                  }${terminalCall.terminal.name}`,
                  description: "Terminal map",
                  form: "terminalmapmodal",
                  formProps: {
                    terminal: terminalCall.terminal,
                  },
                })
              )
            }
          >
            <MapIcon sx={{ fontSize: 20 }} />
          </IconButton>
          <IconButton
            onClick={() =>
              dispatch(
                showModal({
                  title: "Planning notes",
                  description: "Planning notes",
                  form: "note",
                  formProps: {
                    terminalCall: terminalCall,
                  },
                })
              )
            }
          >
            <CommentIcon
              sx={{ fontSize: 20 }}
              color={terminalCall.notes.length > 0 ? "primary" : "default"}
            />
          </IconButton>
          <IconButton
            onClick={() => {
              dispatch(
                showModal({
                  title: "Edit planning",
                  description: "Creates a new planning",
                  form: "terminalCall",
                  formProps: {
                    terminalCall: terminalCall,
                    terminals: terminals.data,
                    vessels: vessels.data,
                    labels: labels.data,
                  },
                })
              );
            }}
          >
            <EditIcon sx={{ fontSize: 20 }} />
          </IconButton>
          <IconButton
            onClick={() => {
              manualRefresh();
            }}
          >
            <RefreshIcon sx={{ fontSize: 20 }} />
          </IconButton>
        </Grid>
      </>
    );
  };

  return (
    <Layout showContainerSearch={true}>
      <Grid container>
        <Grid
          className="container-planner-terminal-main-wrapper"
          item
          xs={12}
          sm={1}
          padding={1}
        >
          <Grid item component={Paper} sx={{ height: "auto" }}>
            <Grid item xs={12}>
              <Select
                size="small"
                onChange={(e) => selectedVesselHandler(e)}
                fullWidth
                sx={{ height: "100%" }}
                value={
                  selectedVessel &&
                  !selectedVessel.barge &&
                  selectedVessel["@id"]
                    ? selectedVessel["@id"]
                    : ""
                }
              >
                {vessels &&
                  !vessels.error &&
                  filterMainVessels().map((vessel: Vessel) => (
                    <MenuItem
                      key={`vessel-${vessel["@id"]}`}
                      value={vessel["@id"]}
                    >
                      {vessel.name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
          </Grid>
          <Grid
            item
            className="container-planner-terminal-call-items"
            component={Paper}
            sx={{ minHeight: "81vh", height: "100%" }}
          >
            {terminalCallData
              ? Object.entries(terminalPlanningFormatter()).map(
                  ([key, value, i]) => (
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={` panel-${i}-content`}
                        id={` panel-${i}-header`}
                        key={`terminal-panel-${i}-header`}
                        sx={{ padding: "0 5px" }}
                      >
                        <Grid
                          sx={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <Grid>{key}</Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails sx={{ padding: "8px 5px 10px" }}>
                        {value.map((terminalCall: TerminalCall) => (
                          <Grid
                            className={`planning-terminal-item js-terminal-call-parent ${
                              selectedTerminalCall &&
                              selectedTerminalCall["@id"] ===
                                terminalCall["@id"]
                                ? "planning-selected-terminal-call"
                                : ""
                            }`}
                            sx={{
                              padding: "4px",
                              backgroundColor: `${
                                terminalCall.terminal &&
                                terminalCall.terminal.color
                                  ? terminalCall.terminal.color
                                  : NO_CONTAINER_COLOR
                              } !important`,
                              color: textColorHandler(
                                terminalCall.terminal &&
                                  terminalCall.terminal.color
                                  ? terminalCall.terminal.color
                                  : NO_CONTAINER_COLOR
                              ),
                            }}
                            component={Paper}
                            data-terminal-call-id={terminalCall["@id"]}
                          >
                            { highlightTerminal(searchedContainerParams.searchedTerminalCallIds, terminalCall["@id"]) }
                            <Grid
                              item
                              sx={{ display: "flex", flexDirection: "column" }}
                              onClick={(e: any) =>
                                selectedTerminalCallHandler(e)
                              }
                            >
                              <Grid
                                item
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                {formatDateToString(
                                  terminalCall.planned,
                                  "HH:mm"
                                )}
                              </Grid>
                              <Grid item>
                                {terminalCall.terminal &&
                                terminalCall.terminal.name
                                  ? `${terminalCall.terminal.name}`
                                  : "No name"}
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  )
                )
              : ""}
          </Grid>
        </Grid>
        <Grid
          className="container-planner-terminal-main-wrapper"
          item
          xs={12}
          sm={2}
          padding={1}
        >
          <Grid
            item
            className="container-planner-terminal-information"
            component={Paper}
          >
            {selectedTerminalCall && getUpdatedTerminalCall()
              ? RenderTerminalDetail(getUpdatedTerminalCall())
              : ""}
          </Grid>
          <Grid
            item
            className={`container-planner-loading-container-wrapper ${
              planType && planType === PLAN_TYPE_UNLOADING ? "hidden" : ""
            }`}
            component={Paper}
            data-container-list={true}
            onDrop={(e: any) => containerStateHandler(e)}
            onDragOver={(e: any) => dragOver(e)}
          >
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                maxHeight: "30px",
                color: "#666666",
                marginBottom: "5px",
              }}
            >
              <>
                {selectedVessel && selectedTerminalCall ? (
                  <>
                  <IconButton
                    onClick={() =>
                      dispatch(
                        showModal({
                          title: "Add container",
                          description: "Add a container",
                          form: "container",
                          formProps: {
                            terminals: terminals.data,
                            terminalCalls: terminalCalls.data,
                            vessels: vessels.data,
                            containerTypes: containerTypes.data,
                            labels: labels.data,
                            selectedVessel: selectedVessel,
                            selectedLoadingTerminalCall: selectedTerminalCall,
                          },
                        })
                      )
                    }
                  >
                    <AddIcon sx={{ fontSize: 20 }} />
                  </IconButton>
                  <IconButton 
                      onClick={() => resetContainerPositions() }
                    >
                    <RotateLeftSharpIcon sx={{ fontSize: 20 }}/> 
                  </IconButton>
                  </>
                ) : (
                  ""
                )}
                {selectedVessel &&
                selectedTerminalCall &&
                selectedTerminalCall.loadingContainers.length > 0 &&
                !containers.error ? (
                  <small>
                    Already loaded: {numberOfContainersWithPosition()}/
                    {selectedTerminalCall.loadingContainers.length}
                  </small>
                ) : (
                  ""
                )}
              </>
            </Grid>
            {selectedVessel &&
            selectedTerminalCall &&
            containerData &&
            !containers.error &&
            planType
              ? containerData.map(
                  (value, key) =>
                    !value.position && (
                      <Grid
                        item
                        className="container-planner-loading-container-row"
                        data-container-list={true}
                        key={`planner-grid-${key}`}
                      >
                        <Grid
                          item
                          className={`container-planner-grid ${
                            value.containerType && value.containerType.teu > 1
                              ? `container-planner-grid-teu-${value.containerType.teu}`
                              : ""
                          }`}
                          data-container-id={value["@id"]}
                          data-container-teu={
                            value.containerType && value.containerType.teu
                              ? value.containerType.teu
                              : 1
                          }
                          onDragStart={(e: any) => dragStart(e)}
                          draggable
                          data-container-list={true}
                          sx={{
                            backgroundColor: `${containerColorHandler(
                              value
                            )} !important`,
                            color: textColorHandler(
                              containerColorHandler(value)
                            ),
                          }}
                          onDoubleClick={() =>
                            dispatch(
                              showModal({
                                title: `${value.uid}`,
                                description: "Container information",
                                form: "containerviewmodal",
                                formProps: {
                                  container: value,
                                  vessels: vessels.data,
                                  terminals: terminals.data,
                                  terminalCalls: terminalCalls.data,
                                  containerTypes: containerTypes.data,
                                  labels: labels.data,
                                },
                              })
                            )
                          }
                        >
                          { highlightContainer(searchedContainerParams.searchedContainerIds, value["@id"]) }
                          {containerUidFormatter(value.uid).letters}
                          <br />
                          {containerUidFormatter(value.uid).numbers}
                        </Grid>
                        <Typography>
                          {value.weight && value.weight > 0
                            ? (value.weight / 1000).toFixed(3)
                            : 0}
                          T
                        </Typography>
                      </Grid>
                    )
                )
              : ""}
          </Grid>
        </Grid>
        <Grid
          className="container-planner-grid-main-wrapper"
          item
          xs={12}
          sm={7}
          padding={1}
        >
          <Grid
            item
            className="container-planner-menu-wrapper"
            component={Paper}
          >
            <Grid item className="container-planner-menu">
              <Button
                id="container-color-button"
                aria-controls={open ? "container-color-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
                size="small"
                variant="outlined"
              >
                Container color
              </Button>
              <Menu
                id="container-color-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "container-color-button",
                }}
              >
                <MenuItem
                  onClick={(e: any) => containerColorTypeChange(e)}
                  data-container-color-type={LOADING_CONTAINER_COLOR}
                >
                  {containerColorType &&
                  containerColorType === LOADING_CONTAINER_COLOR ? (
                    <RadioButtonCheckedIcon color="primary" fontSize="small" />
                  ) : (
                    <RadioButtonUncheckedIcon
                      color="primary"
                      fontSize="small"
                    />
                  )}
                  &nbsp; Loading color
                </MenuItem>
                <MenuItem
                  onClick={(e: any) => containerColorTypeChange(e)}
                  data-container-color-type={UNLOADING_CONTAINER_COLOR}
                >
                  {containerColorType &&
                  containerColorType === UNLOADING_CONTAINER_COLOR ? (
                    <RadioButtonCheckedIcon color="primary" fontSize="small" />
                  ) : (
                    <RadioButtonUncheckedIcon
                      color="primary"
                      fontSize="small"
                    />
                  )}
                  &nbsp; Unloading color
                </MenuItem>
              </Menu>
              <Divider orientation="vertical" />
              <Button
                size="small"
                variant="outlined"
                onClick={(e: any) => planTypeChange(e)}
                className={`${
                  planType === PLAN_TYPE_LOADING ? "active-plan" : ""
                }`}
                data-plan-type={PLAN_TYPE_LOADING}
              >
                Loading plan
              </Button>
              <Button
                size="small"
                variant="outlined"
                onClick={(e: any) => planTypeChange(e)}
                className={`${
                  planType === PLAN_TYPE_UNLOADING ? "active-plan" : ""
                }`}
                data-plan-type={PLAN_TYPE_UNLOADING}
              >
                Unloading plan
              </Button>
              <Divider orientation="vertical" />
              <Button size="small" variant="outlined">
                Status
              </Button>
            </Grid>
            <Grid item className="container-planner-vessel-switch">
              <ToggleButtonGroup
                value={dockingSide}
                exclusive
                onChange={dockingSideChange}
              >
                <ToggleButton value={DOCKING_SIDE_PORT}>
                  {DOCKING_SIDE_PORT}
                </ToggleButton>
                <ToggleButton value={DOCKING_SIDE_STARBOARD}>
                  {DOCKING_SIDE_STARBOARD}
                </ToggleButton>
              </ToggleButtonGroup>
              <Divider orientation="vertical" />
              {selectedVessel && selectedVessel.linkedVessel ? (
                <>
                  <ToggleButtonGroup
                    value={selectedVesselForPlanner}
                    exclusive
                    onChange={selectedVesselForPlannerHandler}
                  >
                    <ToggleButton value={selectedVessel}>
                      {selectedVessel.name}
                    </ToggleButton>
                    <ToggleButton value={selectedVessel.linkedVessel}>
                      {selectedVessel.linkedVessel.name}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
          <Grid
            item
            className="container-planner-main-wrapper"
            component={Paper}
          >
            {selectedVesselForPlanner &&
              selectedTerminalCall &&
              selectedVesselForPlanner.positions &&
              selectedVesselForPlanner.positions.map(
                (grids: any, layer: any) => (
                  <Grid
                    className={`container-planner-wrapper ${
                      dockingSide === DOCKING_SIDE_STARBOARD
                        ? "container-planner-wrapper--revese"
                        : ""
                    }`}
                    item
                  >
                    <Grid className="container-planner-ship-bow" item>
                      <Typography className="container-planner-layer-label">
                        {layer + 1}
                      </Typography>
                    </Grid>
                    <Grid className="container-planner-ship-body" item>
                      {grids &&
                        grids.map((gridCount, x) => (
                          <>
                            <Grid
                              className="container-planner-ship-grid-wrapper"
                              item
                            >
                              <Typography className="container-planner-x-axis-label">
                                {x + 1}
                              </Typography>
                              {[...Array(gridCount)].map((g, y) =>
                                RenderGrid(
                                  layer + 1,
                                  x + 1,
                                  y + 1,
                                  gridCount,
                                  grids
                                )
                              )}
                            </Grid>
                            {hasPositionBarrier([x + 1, x + 2]) ? (
                              <Grid
                                className="container-planner-barrier"
                                item
                              ></Grid>
                            ) : (
                              ""
                            )}
                          </>
                        ))}
                    </Grid>
                  </Grid>
                )
              )}
            {selectedTerminalCall &&
            selectedVesselForPlanner &&
            !selectedVesselForPlanner.positions ? (
              <Grid item sx={{ display: "flex", justifyContent: "center" }}>
                <Alert severity="warning">
                  Grid positions for this vessel are not set. Please contact the
                  administrator.
                </Alert>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        <Grid
          className="container-planner-grid-main-wrapper"
          item
          xs={12}
          sm={2}
          padding={1}
        >
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
              height: "100%",
            }}
            component={Paper}
          >
            <VesselLoadStability
              vessel={selectedVesselForPlanner}
              containers={containerData}
              terminalCall={selectedTerminalCall}
            />
          </Grid>
        </Grid>
      </Grid>
      {snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
      <Modal />
    </Layout>
  );
};

export default ContainerPlanner;
