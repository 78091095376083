import { Tenant } from "./Tenant";
import { User } from "./User";
import { Item } from "./item";

export class Note implements Item {
  public "@id"?: string;

  constructor(
    _id?: string,
    public note?: string,
    public tenant?: Tenant[]| string[],
    public user?: User[]| string[],
  ) {
    this["@id"] = _id;
  }
}
