import React, {useState} from 'react';

import {
    Grid,
    TextField,
    Typography,
} from "@mui/material";

export default function Search({className = 'js-search-element', label = 'Search'}) {
    const counts = {
        found: 0,
        total: 0,
    };
    
    const [elementsFound, setElementsFound] = useState(counts);

    const SearchFunction = (element:any) => {
        const searchString = element.target.value;
        const dataElements = document.getElementsByClassName(className) as HTMLCollectionOf<Element> | null;

        [].forEach.call(dataElements, function (element:any) {
            element.style.display = 'block';
        });

        counts.found = 0;
        counts.total = dataElements.length;

        [].forEach.call(dataElements, function (element:any) {
            if (!element.innerText.toLowerCase().includes(searchString.toLowerCase())) {
                element.style.display = 'none';
            } else {
                counts.found += 1;
            }
        });

        setElementsFound(counts)
    }

    return (
        <Grid item sx={{ marginBottom: '10px', marginTop: '8px' }}>
            { elementsFound.found > 0 && elementsFound.found !== elementsFound.total ? (
                <Typography sx={{ fontSize: '12px', textAlign:'right' }}>
                    { `Found ${elementsFound.found}/${elementsFound.total}` }
                </Typography>
            ) : ''}
            <TextField
                fullWidth
                size="small"
                label={ label }
                onChange={(e:any) => SearchFunction(e)}
            />
        </Grid>
    )
}
