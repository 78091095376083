import React from 'react';

import { 
    MapContainer, 
    Marker, 
    Popup, 
    TileLayer 
} from 'react-leaflet'

import {
  Grid,
  List as MuiList,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";

const TerminalMapModal = (properties: any, show:boolean) => {
  if (!properties.terminal) {
    return
  }

  const terminal = properties.terminal;
  const name = `${ properties.terminal.abbreviation ? `${ properties.terminal.abbreviation } - ` : '' }${ properties.terminal.name }`;
  const coordinates = terminal.latitude && terminal.longitude ? [terminal.latitude , terminal.longitude] : [51.23102, 4.39790];

  return (
    <>
      <MapContainer center={coordinates} zoom={12} scrollWheelZoom={false}>
          <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={coordinates}>
              <Popup>
                  { name }
              </Popup>
          </Marker>
      </MapContainer>
      <Grid container>
        { terminal.contacts && terminal.contacts.length > 0 ? (
          <Grid item xs={12}>
            <Typography variant='h6' mt={1}>
              Contacts
            </Typography>
            <MuiList>
              {Object.entries(terminal.contacts).map(([key, value, i]) => (
                <>
                  <ListItem disablePadding>
                    <ListItemText 
                      primary={`${value.name}${ value.phoneNumber ? ` - ${ value.phoneNumber }` : ''}${ value.email ? ` - ${ value.email }` : ''}`}
                      secondary={`${value.address.number ?? ''} ${value.address.street ?? ''}, ${value.address.postalCode ?? ''} ${value.address.city ?? ''}, ${value.address.country ?? ''}`}
                    />
                  </ListItem>
                </>
              ))}
            </MuiList>
          </Grid>
        ) : ''}
      
      </Grid>
    </>
  );
}

export default TerminalMapModal;
