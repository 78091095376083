import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm, SubmitHandler } from "react-hook-form"
import { InputTextField } from '../FormFields/RenderInputTextField';
import { Terminal } from '../../types/Terminal';
import { NumberField } from '../FormFields/RenderNumberField';
import { getContacts } from "../../data/contacts";
import { SelectMultipleField } from "../FormFields/RenderSelectMultipleField";
import GetContactOptions from "../FormHelpers/ContactSelectOptions";
import { createTerminal, updateTerminal } from "../../data/terminals";
import { hideModal } from "../../data/modal";

import {
    Button,
    Grid,
} from "@mui/material";

import {
    Close as CloseIcon,
    Save as SaveIcon,
} from '@mui/icons-material';

import { 
    MapContainer, 
    Marker, 
    Popup, 
    TileLayer,
    useMapEvents,
} from 'react-leaflet'
import { InputColorField } from "../FormFields/RenderInputColorField";

const TerminalForm = ({ terminal }) => {
    const dispatch = useDispatch();
    
    const [latitude, setLatitude] = useState(terminal && terminal.latitude ? terminal.latitude : 51.23102)
    const [longitude, setLongitude] = useState(terminal && terminal.longitude ? terminal.longitude : 4.39790)

    const selectedData = useSelector((state) => ({
        contacts: state.contacts,
    }));
    
    useEffect(() => {
        dispatch(getContacts());
    }, [dispatch]);
    
    const { contacts } = selectedData;

    const { handleSubmit, control, formState, setValue } = useForm({
        defaultValues: terminal ?? new Terminal(),
    });
    const { isSubmitting } = formState;

    const onSubmit: SubmitHandler<Terminal> = (data) => {
        const formData = {
            ...data,
            latitude: data.latitude ? data.latitude.toString() : null,
            longitude: data.longitude ? data.longitude.toString() : null,
        }   

        if (terminal) {
            dispatch(updateTerminal(formData));
        } else {
            dispatch(createTerminal(formData));
        }  
    }

    const MapEvents = () => {
        const map = useMapEvents({
            click: (e:any) => {
                const latLang = e.latlng
                setLatitude(latLang.lat)
                setLongitude(latLang.lng)
                setValue('latitude', latLang.lat, { shouldValidate: true })
                setValue('longitude', latLang.lng, { shouldValidate: true })
            },
        })

        return null
    }
    
    return (
        <form onSubmit={ handleSubmit(onSubmit) }>
            <Grid container xs={12}>
                <Grid item xs={12} sm={6}>
                    <Grid item xs={12} className='form-fields-container'>
                        <Grid item xs={12} sm={12}>
                            <InputTextField
                                name='name'
                                label='Name'
                                control={ control }
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className='form-fields-container'>
                        <Grid item xs={12} sm={6}>
                            <InputTextField
                                name='abbreviation'
                                label='Abbreviation'
                                control={ control }
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputTextField
                                name='city'
                                label='City'
                                control={ control }
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className='form-fields-container'>
                        <Grid xs={12} sm={6}>
                            <NumberField
                                name='latitude'
                                label='Latitude'
                                control={ control }
                                required={false}
                                decimal={5}
                                value={ latitude }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <NumberField
                                name='longitude'
                                label='Longitude'
                                control={ control }
                                required={false}
                                decimal={5}
                                value={ longitude }
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className='form-fields-container'>
                        <Grid item xs={12} sm={4}>
                            <InputColorField
                                name='color'
                                label='Color'
                                control={ control }
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className='form-fields-container'>
                        <Grid xs={12} sm={12}>
                            <SelectMultipleField
                                name='contacts'
                                label='Contacts'
                                control={ control }
                                options={GetContactOptions(contacts.data)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={5} marginLeft='15px'>
                    <MapContainer 
                        center={[latitude, longitude]} 
                        zoom={12} 
                        scrollWheelZoom={false}
                    >
                        <MapEvents />
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker position={[latitude, longitude]}>
                            <Popup>
                                { `[${ latitude }, ${ longitude }]` }
                            </Popup>
                        </Marker>
                    </MapContainer>
                </Grid>
                <Grid item xs={12} className='button-wrapper'>
                <Button 
                    onClick={() => {dispatch(hideModal())}}
                    disabled={ isSubmitting }
                    variant='contained'
                >
                    <CloseIcon fontSize="inherit" />&nbsp;Close
                </Button>
                    <Button 
                        type='submit'
                        disabled={ isSubmitting }
                        variant='contained'
                    >
                        <SaveIcon fontSize="inherit" />&nbsp;Save
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

export default TerminalForm;