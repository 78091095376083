/*****************/
/* INITIAL STATE */
/*****************/
const initialState = {
  container: {
    labels: [],
    loadingTerminals: [],
  },
  terminals: {},
};

/*********/
/* TYPES */
/*********/
interface FilterProperties {
  container: {
    labels?: Array<string>;
    loadingTerminals?: Array<string>;
  };
}

enum FilterActionTypes {
  CLEAR_FILTER = "CLEAR_FILTER",
  ADD_CONTAINER_LABEL_FILTER = "ADD_CONTAINER_LABEL_FILTER",
  REMOVE_CONTAINER_LABEL_FILTER = "REMOVE_CONTAINER_LABEL_FILTER",
  ADD_CONTAINER_LOADING_TERMINAL_FILTER = "ADD_CONTAINER_LOADING_TERMINAL_FILTER",
  REMOVE_CONTAINER_LOADING_TERMINAL_FILTER = "REMOVE_CONTAINER_LOADING_TERMINAL_FILTER",
}

export interface FilterAction {
  type: FilterActionTypes;
  payload?: FilterProperties;
}

/*******************/
/* ACTION CREATORS */
/*******************/
export function clearFilter(): FilterAction {
  return {
    type: FilterActionTypes.CLEAR_FILTER,
  };
}

export function addContainerLabelFilterValue(payload): FilterAction {
  return {
    type: FilterActionTypes.ADD_CONTAINER_LABEL_FILTER,
    payload,
  };
}

export function removeContainerLabelFilterValue(payload): FilterAction {
  return {
    type: FilterActionTypes.REMOVE_CONTAINER_LABEL_FILTER,
    payload,
  };
}

export function addContainerLoadingTerminalFilterValue(payload): FilterAction {
  return {
    type: FilterActionTypes.ADD_CONTAINER_LOADING_TERMINAL_FILTER,
    payload,
  };
}

export function removeContainerLoadingTerminalFilterValue(
  payload
): FilterAction {
  return {
    type: FilterActionTypes.REMOVE_CONTAINER_LOADING_TERMINAL_FILTER,
    payload,
  };
}

/***********/
/* REDUCER */
/***********/
function modalReducer(state = initialState, action: FilterAction) {
  switch (action.type) {
    case FilterActionTypes.CLEAR_FILTER:
      return initialState;
    case FilterActionTypes.ADD_CONTAINER_LABEL_FILTER:
      return {
        ...state,
        container: {
          labels: [...state.container.labels, action.payload],
          loadingTerminals: [...state.container.loadingTerminals],
        },
      };
    case FilterActionTypes.REMOVE_CONTAINER_LABEL_FILTER:
      return {
        ...state,
        container: {
          labels: state.container.labels.filter(
            (element) => element !== action.payload
          ),
          loadingTerminals: [...state.container.loadingTerminals],
        },
      };
    case FilterActionTypes.ADD_CONTAINER_LOADING_TERMINAL_FILTER:
      return {
        ...state,
        container: {
          labels: [...state.container.labels],
          loadingTerminals: [
            ...state.container.loadingTerminals,
            action.payload,
          ],
        },
      };
    case FilterActionTypes.REMOVE_CONTAINER_LOADING_TERMINAL_FILTER:
      return {
        ...state,
        container: {
          labels: [...state.container.labels],
          loadingTerminals: state.container.loadingTerminals.filter(
            (element) => element !== action.payload
          ),
        },
      };
    default:
      return state;
  }
}
export default modalReducer;
