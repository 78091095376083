import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter.js";
import "../../styles/form.css";
import { useForm, SubmitHandler } from "react-hook-form";
import { Container } from "../../types/Container";
import { InputTextField } from "../FormFields/RenderInputTextField";
import { NumberField } from "../FormFields/RenderNumberField";
import { SelectField } from "../FormFields/RenderSelectField";
import { DateTimeField } from "../FormFields/RenderDateTimeField";
import { SelectMultipleField } from "../FormFields/RenderSelectMultipleField";
import GetTerminalOptions from "../FormHelpers/TeminalSelectOptions";
import GetContainerTypeOptions from "../FormHelpers/ContainerTypeSelectOptions";
import GetTerminalCallOptions from "../FormHelpers/TerminalCallSelectOptions";
import GetVesselOptions from "../FormHelpers/VesselSelectOptions";
import GetLabelOptions from "../FormHelpers/LabelSelectOptions";
import { TerminalCall } from "../../types/TerminalCall";
import { findObjectById } from "../Helpers.tsx";
import {
  createContainer,
  updateContainer,
  removeContainer,
} from "../../data/containers";
import { MultipleFileField } from "../FormFields/RenderMultipleFileField";
import {
  hideModal,
  showChildModal,
  showModal,
  hideChildModal,
} from "../../data/modal";
import {
  IconButton,
  Button,
  Container as MuiContainer,
  Divider,
  Grid,
  Typography,
} from "@mui/material";

import {
  Add as AddIcon,
  Close as CloseIcon,
  Save as SaveIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";

dayjs.extend(isSameOrAfter);

const FIELD_CHANGE_EVENT_TYPE = "change";

const ContainerForm = ({
  container,
  terminals,
  terminalCalls,
  vessels,
  containerTypes,
  labels,
  selectedVessel,
  selectedLoadingTerminalCall,
  selectedLoadingTerminal,
  selectedUnloadingTerminal,
  callback,
}) => {
  const dispatch = useDispatch();

  const selectedData = useSelector((state) => ({
    modal: state.modal,
    containerTypes: state.containerTypes.data,
  }));

  const containerData = container ?? {
    ...new Container(),
    vessel: selectedVessel ? selectedVessel["@id"] : undefined,
    loadingTerminalCall: selectedLoadingTerminalCall,
    loadingTerminal: selectedLoadingTerminalCall
      ? selectedLoadingTerminalCall.terminal
      : selectedLoadingTerminal,
    unloadingTerminal: selectedUnloadingTerminal,
  };

  const { handleSubmit, control, formState, watch, setValue, register } =
    useForm({
      defaultValues: containerData,
    });
    
  const { isSubmitting } = formState;
  const initialLoadingTerminalCalls = containerData.loadingTerminalCall
    ? [
        findObjectById(
          terminalCalls,
          containerData.loadingTerminalCall["@id"]
            ? containerData.loadingTerminalCall["@id"]
            : containerData.loadingTerminalCall
        ),
      ]
    : [];
  const initialUnloadingTerminalCalls = containerData.unloadingTerminalCall
    ? [
        findObjectById(
          terminalCalls,
          containerData.unloadingTerminalCall["@id"]
            ? containerData.unloadingTerminalCall["@id"]
            : containerData.unloadingTerminalCall
        ),
      ]
    : [];
  const [loadingTerminalCalls, setLoadingTerminalCalls] = useState(
    initialLoadingTerminalCalls
  );
  const [unloadingTerminalCalls, setUnloadingTerminalCalls] = useState(
    initialUnloadingTerminalCalls
  );

  const filterTerminalLoadingCalls = (container: Container) => {
    return terminalCalls.filter((terminalCall: TerminalCall) => {
      const requirementsObtained = [];

      if (container.vessel) {
        const vesselId = container.vessel["@id"]
          ? container.vessel["@id"]
          : container.vessel;
        requirementsObtained.push(
          (terminalCall.vessel["@id"] &&
            terminalCall.vessel["@id"] === vesselId) ||
            terminalCall.vessel.toString() === vesselId
        );
      }

      if (container.loadingTerminal) {
        const terminalId =
          container.loadingTerminal && container.loadingTerminal["@id"]
            ? container.loadingTerminal["@id"]
            : container.loadingTerminal;
        requirementsObtained.push(
          (terminalCall.terminal["@id"] &&
            terminalCall.terminal["@id"] === terminalId) ||
            terminalCall.terminal.toString() === terminalId
        );
      }

      if (container.loadingTerminalClosure) {
        requirementsObtained.push(
          terminalCall.planned &&
            dayjs(terminalCall.planned).isSameOrAfter(
              container.loadingTerminalClosure
            )
        );
      }

      if (requirementsObtained.length > 0) {
        return requirementsObtained.every(
          (requirement) => requirement === true
        );
      }

      return false;
    });
  };

  const filterTerminalUnloadingCalls = (container: Container) => {
    return terminalCalls.filter((terminalCall: TerminalCall) => {
      const requirementsObtained = [];

      if (container.vessel) {
        const vesselId = container.vessel["@id"]
          ? container.vessel["@id"]
          : container.vessel;
        requirementsObtained.push(
          (terminalCall.vessel["@id"] &&
            terminalCall.vessel["@id"] === vesselId) ||
            terminalCall.vessel.toString() === vesselId
        );
      }

      if (container.unloadingTerminal) {
        const terminalId =
          container.unloadingTerminal && container.unloadingTerminal["@id"]
            ? container.unloadingTerminal["@id"]
            : container.unloadingTerminal;
        requirementsObtained.push(
          (terminalCall.terminal["@id"] &&
            terminalCall.terminal["@id"] === terminalId) ||
            terminalCall.terminal.toString() === terminalId
        );
      }

      if (container.unloadingTerminalClosure) {
        requirementsObtained.push(
          terminalCall.planned &&
            dayjs(terminalCall.planned).isSameOrAfter(
              container.unloadingTerminalClosure
            )
        );
      }

      if (requirementsObtained.length > 0) {
        return requirementsObtained.every(
          (requirement) => requirement === true
        );
      }

      return false;
    });
  };

  useEffect(() => {
    const subscription = watch((value: any, { name, type }) => {
      if (type === FIELD_CHANGE_EVENT_TYPE) {
        setUnloadingTerminalCalls([
          ...new Set(filterTerminalUnloadingCalls(value)),
          ...initialUnloadingTerminalCalls,
        ]);
        setLoadingTerminalCalls([
          ...new Set(filterTerminalLoadingCalls(value)),
          ...initialLoadingTerminalCalls,
        ]);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, terminalCalls]);

  const onSubmit: SubmitHandler<Container> = (formData: any) => {
    if (formData.loadingTerminalCall && !formData.loadingTerminalCall["@id"]) {
      formData = {
        ...formData,
        loadingTerminal: findObjectById(
          terminalCalls,
          formData.loadingTerminalCall
        ).terminal,
      };
    }

    if (
      formData.unloadingTerminalCall &&
      !formData.unloadingTerminalCall["@id"]
    ) {
      formData = {
        ...formData,
        unloadingTerminal: findObjectById(
          terminalCalls,
          formData.unloadingTerminalCall
        ).terminal,
      };
    }

    if (container) {
      dispatch(updateContainer(formData));
    } else {
      dispatch(createContainer(formData, callback));
    }
  };

  const handleCloseForm = () => {
    if (selectedData.modal.childModal) {
      dispatch(hideChildModal());
    } else {
      dispatch(hideModal());
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <MuiContainer>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" className="form-fields-title">
              Container Information
            </Typography>
            <Grid item xs={12} className="form-fields-container">
              <Grid item xs={12} sm={6}>
                <InputTextField
                  name="uid"
                  label="UID"
                  control={control}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <SelectField
                  name="containerType"
                  label="Type"
                  control={control}
                  options={GetContainerTypeOptions(selectedData.containerTypes)}
                  required
                />
                <IconButton
                  onClick={() =>
                    dispatch(
                      showChildModal({
                        title: `containerType quickadd`,
                        description: "Add containerType",
                        form: "containerType",
                        formProps: {
                          callback: setValue,
                        },
                      })
                    )
                  }
                >
                  <AddIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12} sm={3}>
                <NumberField
                  name="weight"
                  label="Weight(kg)"
                  control={control}
                  required
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} marginTop={1} marginBottom={1}>
            <Divider orientation="horizontal" />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" className="form-fields-title">
              Container Trajectory
            </Typography>
            <Grid item xs={12} sm={12} className="form-fields-container">
              <Grid item xs={12} sm={6}>
                <SelectField
                  name="loadingTerminal"
                  label="Loading Terminal"
                  control={control}
                  options={GetTerminalOptions(terminals)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectField
                  name="unloadingTerminal"
                  label="Unloading Terminal"
                  control={control}
                  options={GetTerminalOptions(terminals)}
                />
              </Grid>
            </Grid>
            <Grid display="flex">
              <Grid item xs={12} sm={6} className="form-fields-container">
                <Grid item xs={12} sm={6} className="form-field-datetime">
                  <DateTimeField
                    name="loadingTerminalClosure"
                    label="Loading Closure"
                    control={control}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} className="form-fields-container">
                <Grid item xs={12} sm={6} className="form-field-datetime">
                  <DateTimeField
                    name="unloadingTerminalClosure"
                    label="Unloading Closure"
                    control={control}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} marginTop={1} marginBottom={1}>
            <Divider orientation="horizontal" />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" className="form-fields-title">
              Barge Booking
            </Typography>
            <Grid item xs={12} sm={12} className="form-fields-container">
              <Grid item xs={12} sm={3}>
                <SelectField
                  name="vessel"
                  label="Vessel"
                  control={control}
                  options={GetVesselOptions(vessels, false)}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} className="form-fields-container">
              <Grid item xs={12} sm={6}>
                <SelectField
                  name="loadingTerminalCall"
                  label="Loading Terminal Call"
                  control={control}
                  options={GetTerminalCallOptions(loadingTerminalCalls)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectField
                  name="unloadingTerminalCall"
                  label="Unloading Terminal Call"
                  control={control}
                  options={GetTerminalCallOptions(unloadingTerminalCalls)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} marginTop={1} marginBottom={1}>
            <Divider orientation="horizontal" />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" className="form-fields-title">
              Labels
            </Typography>
            <Grid item xs={12} sm={12} className="form-fields-container">
              <Grid item xs={12} sm={12}>
                <SelectMultipleField
                  name="labels"
                  label="Labels"
                  control={control}
                  options={GetLabelOptions(labels)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} marginTop={1} marginBottom={1}>
            <Divider orientation="horizontal" />
          </Grid>

          <MultipleFileField
            control={control}
            register={register}
            setValue={setValue}
            name="fileGroup.files"
            existingFiles={
              container && container.fileGroup ? container.fileGroup.files : []
            }
          />
        </Grid>
      </MuiContainer>
      <Grid item xs={12} className="button-wrapper">
        <Grid container spacing={1}>
          <Grid item>
            <Button
              onClick={handleCloseForm}
              disabled={isSubmitting}
              variant="contained"
            >
              <CloseIcon fontSize="inherit" />
              &nbsp;Close
            </Button>
          </Grid>
          {container && (
            <Grid item>
              <Button
                onClick={() => {
                  dispatch(removeContainer(container));
                }}
                disabled={isSubmitting}
                variant="contained"
                color="error"
              >
                <DeleteIcon fontSize="inherit" />
                &nbsp;Delete
              </Button>
            </Grid>
          )}
        </Grid>
        <Button type="submit" disabled={isSubmitting} variant="contained">
          <SaveIcon fontSize="inherit" />
          &nbsp;Save
        </Button>
      </Grid>
    </form>
  );
};

export default ContainerForm;
