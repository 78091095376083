import axios from "axios";

/*****************/
/* INITIAL STATE */
/*****************/
const initialState = {
  loading: false,
  error: "",
  data: [],
};

/*********/
/* TYPES */
/*********/
const FETCH_CONTACTS = "FETCH_CONTACTS";
const FETCH_CONTACTS_SUCCESS = "FETCH_CONTACTS_SUCCESS";
const FETCH_CONTACTS_ERROR = "FETCH_CONTACTS_ERROR";
const FETCH_NEXT_CONTACTS = "FETCH_NEXT_CONTACTS";
const FETCH_NEXT_CONTACTS_SUCCESS = "FETCH_NEXT_CONTACTS_SUCCESS";

/*******************/
/* ACTION CREATORS */
/*******************/
export const getContacts = () => async (dispatch) => {
  dispatch(loadContacts());

  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/contacts?page=1`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.data["hydra:member"].length === 0) {
    dispatch(setError("No contacts found"));
  } else {
    dispatch(setContacts(response.data["hydra:member"]));
    if (
      response.data &&
      response.data["hydra:view"] &&
      response.data["hydra:view"] !== undefined &&
      response.data["hydra:view"]["hydra:next"] &&
      response.data["hydra:view"]["hydra:next"] !== undefined
    ) {
      dispatch(getNextContacts(response.data["hydra:view"]["hydra:next"]));
    }
  }
};

export const getNextContacts = (nextUrl) => async (dispatch) => {
  dispatch(loadNextContacts());

  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL}${nextUrl}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  dispatch(setNextContacts(response.data["hydra:member"]));
  if (
    response.data &&
    response.data["hydra:view"] &&
    response.data["hydra:view"] !== undefined &&
    response.data["hydra:view"]["hydra:next"] &&
    response.data["hydra:view"]["hydra:next"] !== undefined
  ) {
    dispatch(getNextContacts(response.data["hydra:view"]["hydra:next"]));
  }
};

export const loadContacts = () => ({ type: FETCH_CONTACTS });

export const setContacts = (contacts) => ({
  type: FETCH_CONTACTS_SUCCESS,
  payload: { contacts },
});

export const loadNextContacts = (page) => ({
  type: FETCH_NEXT_CONTACTS,
  payload: page,
});
export const setNextContacts = (contacts) => ({
  type: FETCH_NEXT_CONTACTS_SUCCESS,
  payload: contacts,
});

export const setError = (msg) => ({
  type: FETCH_CONTACTS_ERROR,
  payload: msg,
});

/***********/
/* REDUCER */
/***********/
const contactReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CONTACTS:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_CONTACTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        data: payload.contacts,
      };

    case FETCH_CONTACTS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case FETCH_NEXT_CONTACTS:
      return {
        ...state,
        error: "",
      };
    case FETCH_NEXT_CONTACTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, ...payload],
      };
    default:
      return state;
  }
};

export default contactReducer;
