import * as React from 'react';
import { Controller } from "react-hook-form";
import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
  } from "@mui/material";

  export const RadioField: any = ({
    name,
    control,
    label,
    options,
  }) => {
    if (!options) {
        return <></>;
    }

    const generateOptions = () => {
        return options.map((option) => (
            <FormControlLabel
                value={option.value}
                label={option.label}
                control={<Radio />}
                key={`${ name }-option-${ option.value }`}
            />
        ));
    };

    return (
      <FormControl component="fieldset">
        <FormLabel component="legend">{label}</FormLabel>
        <Controller
          name={name}
          control={control}
          render={({
            field: { onChange, value },
            fieldState: { error },
            formState,
          }) => (
            <RadioGroup value={ value ?? '' } onChange={onChange}>
               {generateOptions() }
            </RadioGroup>
          )}
        />
      </FormControl>
    );
  };