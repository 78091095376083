import { Label } from "../../types/Label";

const GetLabelOptions = (labels: Label[]) => {
  const labelOptions = [];

  
  if (!labels || labels.length === 0) {
      return labelOptions
  }

  labels.map((label: Label) => { 
    labelOptions.push({
      value: label['@id'],
      label: label.name,
      color: label.color,
    })
  })

  return labelOptions;
}

export default GetLabelOptions;