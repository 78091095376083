import { Tenant } from "./Tenant";
import { Item } from "./item";

export class Terminal implements Item {
  public "@id"?: string;

  constructor(
    _id?: string,
    public name?: string,
    public contact?: string,
    public abbreviation?: string,
    public city?: string,
    public latitude?: number,
    public longitude?: number,
    public tenant?: Tenant[]| string[],
  ) {
    this["@id"] = _id;
  }
}
