import * as React from 'react';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useForm, SubmitHandler } from "react-hook-form"
import { InputTextField } from '../FormFields/RenderInputTextField';
import {
    Button,
    Grid,
    Typography,
} from "@mui/material";
import {
    Save as SaveIcon,
} from '@mui/icons-material';
import { Tenant } from '../../types/Tenant';


const TenantForm = () => {
    const { handleSubmit, control, formState } = useForm();
    
    const { isSubmitting } = formState;
    const navigate = useNavigate();
    const refreshPage = () => {
        navigate(0);
    }

    const onSubmit: SubmitHandler<Tenant> = (data) => {
        const formData = {
            ...data,
        }
          
        axios({
            method: "POST",
            url: `${process.env.REACT_APP_API_URL}/tenants`,
            headers: {
              "Content-Type": "application/ld+json; charset=utf-8",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            data: formData
        }).then((res) => {
            refreshPage()
        }).catch((error) => console.log(error));
    }
        
    return (
        <form onSubmit={ handleSubmit(onSubmit) }>
            <Grid container xs={12}>
                <Typography variant='h6'>
                    Add Tenant
                </Typography>
                <Grid item  xs={12} mt={2}>
                    <InputTextField
                        name='name'
                        label='Name'
                        control={ control }
                        required
                    />
                </Grid> 
                <Grid item  xs={12} mt={2}>
                    <hr/>
                </Grid>
                <Grid item  xs={12} mt={2}>
                    <InputTextField
                        name='address.number'
                        label='Number'
                        control={ control }
                        required
                    />
                </Grid> 
                <Grid item  xs={12} mt={2}>
                    <InputTextField
                        name='address.street'
                        label='Street'
                        control={ control }
                        required
                    />
                </Grid> 
                <Grid item  xs={12} mt={2}>
                    <InputTextField
                        name='address.postalCode'
                        label='Postal Code'
                        control={ control }
                        required
                    />
                </Grid> 
                <Grid item  xs={12} mt={2}>
                    <InputTextField
                        name='address.city'
                        label='City'
                        control={ control }
                        required
                    />
                </Grid> 
                <Grid item  xs={12} mt={2}>
                    <InputTextField
                        name='address.country'
                        label='Country'
                        control={ control }
                        required
                    />
                </Grid> 
                <Grid item  xs={12} mt={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button 
                        type='submit'
                        disabled={ isSubmitting }
                        variant='contained'
                    >
                        <SaveIcon fontSize="inherit" />&nbsp;Save
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

export default TenantForm;