import { Terminal } from "../../types/Terminal";

const GetTerminalOptions = (terminals: Terminal[]) => {
      const terminalOptions = [];
      
      if (!terminals || terminals.length === 0) {
          return terminalOptions;
      }

      terminals.map((terminal: Terminal) => { 
        terminalOptions.push({
          value: terminal['@id'],
          label: `${ terminal.abbreviation ? `${ terminal.abbreviation } - ` : '' }${ terminal.name }`
        })
      })

    return terminalOptions;
}

export default GetTerminalOptions;