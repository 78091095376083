import React from "react";
import { useFieldArray } from "react-hook-form";
import { NumberField } from "../FormFields/RenderNumberField";

import { IconButton, Grid } from "@mui/material";
import { Remove as RemoveIcon, Add as AddIcon } from "@mui/icons-material";

const VesselDraughtKGMax = ({
  name,
  label,
  control,
  required = false,
  register,
}: any) => {
  const { fields, append, remove } = useFieldArray({
    name: name,
    control,
    rules: {
      required: "Please append at least 1 item",
    },
  });

  return (
    <>
      {fields.map((field, index) => {
        return (
          <section key={field.id}>
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
            >
              <Grid item xs={3} sx={{ marginBottom: "5px" }}>
                <NumberField
                  name="layer"
                  label="Layer"
                  control={control}
                  {...register(`${name}.${index}.layer`, {
                    required: true,
                    valueAsNumber: true,
                  })}
                />
              </Grid>
              <Grid item xs={3} sx={{ marginBottom: "5px" }}>
                <NumberField
                  name="draught"
                  label="Draught"
                  control={control}
                  {...register(`${name}.${index}.draught`, {
                    required: true,
                    valueAsNumber: true,
                  })}
                />
              </Grid>
              <Grid item xs={3} sx={{ marginBottom: "5px" }}>
                <NumberField
                  name="KGmax"
                  label="KG Max"
                  control={control}
                  {...register(`${name}.${index}.KGmax`, {
                    valueAsNumber: true,
                  })}
                />
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  type="button"
                  color="error"
                  size="small"
                  onClick={() => remove(index)}
                >
                  <RemoveIcon />
                </IconButton>
              </Grid>
            </Grid>
          </section>
        );
      })}
      <IconButton
        type="button"
        color="info"
        size="small"
        onClick={() => {
          append({
            weight: 0,
            draught: 0,
          });
        }}
      >
        <AddIcon />
      </IconButton>
    </>
  );
};

export default VesselDraughtKGMax;
