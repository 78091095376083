import * as React from 'react';
import axios from "axios";
import { useLocation, Link } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form"
import { InputTextField } from '../FormFields/RenderInputTextField';
import { Contact } from '../../types/Contact';
import { useDispatch } from "react-redux";
import { hideModal } from "../../data/modal";

import {
    Button,
    Divider,
    Grid,
    Typography,
} from "@mui/material";

import {
    Close as CloseIcon,
    Save as SaveIcon,
} from '@mui/icons-material';

const ContactForm = ({ contact }) => {    
    const dispatch = useDispatch();
    const location = useLocation();
    const { handleSubmit, control, formState } = useForm({
        defaultValues: contact ?? new Contact(),
    });
    const { isSubmitting } = formState;

    const onSubmit: SubmitHandler<Contact> = (data) => {          
        axios({
            method: contact ? 'PUT' : 'POST',
            url: `${process.env.REACT_APP_API_URL}/contacts${ contact && contact['@id'] ? `/${contact['@id'].replace('/api/contacts/', '')}` : '' }`,
            headers: {
              "Content-Type": "application/ld+json; charset=utf-8",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            data: data
        }).then((res) => {
            window.location.href = location.pathname
        }).catch((error) => console.log(error));
    }
        
    return (
        <form onSubmit={ handleSubmit(onSubmit) }>
            <Grid container xs={12}>
                <Grid item xs={12} sm={12}>
                    <Grid item xs={12}>
                        <Typography variant='h6' className='form-fields-title'>
                            Contact Information
                        </Typography>
                        <Grid item xs={12} className='form-fields-container'>
                            <Grid item xs={12} sm={4}>
                                <InputTextField
                                    name='name'
                                    label='Name'
                                    control={ control }
                                    required
                                />
                            </Grid>
                            <Grid item  xs={12} sm={4}>
                                <InputTextField
                                    name='phoneNumber'
                                    label='Phone Number'
                                    control={ control }
                                />
                            </Grid> 
                            <Grid item  xs={12} sm={4}>
                                <InputTextField
                                    name='email'
                                    label='Email'
                                    control={ control }
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} marginTop={1} marginBottom={1}>
                        <Divider orientation="horizontal" />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Typography variant='h6' className='form-fields-title'>
                            Address
                        </Typography>
                        <Grid item xs={12} className='form-fields-container'>
                            <Grid item xs={12} sm={2}>
                                <InputTextField
                                    name='address.number'
                                    label='Number'
                                    control={ control }
                                />
                            </Grid> 
                            <Grid item xs={12} sm={4}>
                                <InputTextField
                                    name='address.street'
                                    label='Street'
                                    control={ control }
                                />
                            </Grid> 
                            <Grid item xs={12} sm={2}>
                                <InputTextField
                                    name='address.postalCode'
                                    label='Postal Code'
                                    control={ control }
                                />
                            </Grid> 
                            <Grid item xs={12} sm={2}>
                                <InputTextField
                                    name='address.city'
                                    label='City'
                                    control={ control }
                                />
                            </Grid> 
                            <Grid item xs={12} sm={2}>
                                <InputTextField
                                    name='address.country'
                                    label='Country'
                                    control={ control }
                                />
                            </Grid> 
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className='button-wrapper'>
                        <Grid item>
                            <Button 

                                onClick={() => {dispatch(hideModal())}}
                                disabled={ isSubmitting }
                                variant='contained'
                            >
                                <CloseIcon fontSize="inherit" />&nbsp;Close
                            </Button>
                        </Grid>
                        <Button 
                            type='submit'
                            disabled={ isSubmitting }
                            variant='contained'
                        >
                            <SaveIcon fontSize="inherit" />&nbsp;Save
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}

export default ContactForm;