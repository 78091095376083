import * as React from 'react';
import dayjs from 'dayjs';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Controller } from "react-hook-form";

export const DateTimeField = ({ name, control, label, required = false }: any) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nl">
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <DateTimePicker 
            format='DD/MM/YYYY HH:mm'
            label={label} 
            value={value ? dayjs(value) : undefined} 
            onChange={onChange} 
            ampm={false}
          />
        )}
      />
    </LocalizationProvider>
  );
};