import React from "react";

import "../../styles/vesselstability.css";
import { Container } from "../../types/Container";

import { Grid, Typography, Tooltip } from "@mui/material";

import CircleIcon from "@mui/icons-material/Circle";

import { styled } from "@mui/material/styles";
import { keyframes } from "@mui/system";
import { Vessel } from "../../types/Vessel";
import { TerminalCall } from "../../types/TerminalCall";

const blink = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const BlinkedBox = styled("div")({
  width: 14,
  height: 14,
  borderRadius: 14,
  animation: `${blink} 1s linear infinite`,
});

const WEIGHT_X_AXIS_TYPE = "x_axis";
const WEIGHT_Y_AXIS_TYPE = "y_axis";
const WEIGHT_GROUP_FRONT = "front";
const WEIGHT_GROUP_MIDDLE = "middle";
const WEIGHT_GROUP_BACK = "back";

const getContainerTotalWeights = (containerWeights: any) => {
  const newContainerWeights = [...containerWeights];
  const arrayRange = Math.round(containerWeights.length / 3);

  const formattedContainerWeights = new Array(Math.ceil(arrayRange))
    .fill()
    .map((_) => newContainerWeights.splice(0, arrayRange));
  const totalContainerWeights = [];
  let rangeMin = 1;

  formattedContainerWeights.slice(0, 3).map((weights: any, index: any) => {
    rangeMin = index === 0 ? rangeMin : rangeMin + weights.length;
    let rangeMax = ++index * weights.length;

    totalContainerWeights.push({
      total: weights.reduce((accumulator, weight) => accumulator + weight, 0),
      range: `${rangeMin} - ${rangeMax}`,
    });
  });

  return totalContainerWeights;
};

const vesselWeightGroups = (containerWeights: any, weightGroup: any) => {
  const totalContainerWeights = [];
  let formattedWeightGroup = [];

  weightGroup.map((group, index) => {
    formattedWeightGroup[group] ??= [];
    formattedWeightGroup[group].push(index);
  });

  if (
    !formattedWeightGroup[WEIGHT_GROUP_FRONT] ||
    !formattedWeightGroup[WEIGHT_GROUP_MIDDLE] ||
    !formattedWeightGroup[WEIGHT_GROUP_BACK]
  ) {
    return "";
  }

  const frontWeights = containerWeights.filter((weight, index) =>
    formattedWeightGroup[WEIGHT_GROUP_FRONT].includes(index)
  );
  const middleWeights = containerWeights.filter((weight, index) =>
    formattedWeightGroup[WEIGHT_GROUP_MIDDLE].includes(index)
  );
  const backWeights = containerWeights.filter((weight, index) =>
    formattedWeightGroup[WEIGHT_GROUP_BACK].includes(index)
  );
  const frontTotalWeight = frontWeights.reduce(
    (accumulator, weight) => accumulator + weight,
    0
  );
  const middleTotalWeights = middleWeights.reduce(
    (accumulator, weight) => accumulator + weight,
    0
  );
  const backTotalWeights = backWeights.reduce(
    (accumulator, weight) => accumulator + weight,
    0
  );
  const allWeights = [...frontWeights, ...middleWeights, ...backWeights];

  return (
    <>
      <Grid className="weight-over-beam-content-tonnage" item>
        <Tooltip
          title={`Rows: ${formattedWeightGroup[WEIGHT_GROUP_FRONT].map(
            (layer) => layer + 1
          ).toString()}`}
        >
          <Grid
            className="weight-over-beam-content-tonnage-per-row"
            item
            sx={{ width: "35% !important" }}
          >
            <small>Front</small>
            <Typography>{(frontTotalWeight / 1000).toFixed(0)}T</Typography>
          </Grid>
        </Tooltip>
        <Tooltip
          title={`Rows: ${formattedWeightGroup[WEIGHT_GROUP_MIDDLE].map(
            (layer) => layer + 1
          ).toString()}`}
        >
          <Grid
            className="weight-over-beam-content-tonnage-per-row"
            item
            sx={{ width: "35% !important" }}
          >
            <small>Middle</small>
            <Typography>{(middleTotalWeights / 1000).toFixed(0)}T</Typography>
          </Grid>
        </Tooltip>
        <Tooltip
          title={`Rows: ${formattedWeightGroup[WEIGHT_GROUP_BACK].map(
            (layer) => layer + 1
          ).toString()}`}
        >
          <Grid
            className="weight-over-beam-content-tonnage-per-row"
            item
            sx={{ width: "35% !important" }}
          >
            <small>Back</small>
            <Typography>{(backTotalWeights / 1000).toFixed(0)}T</Typography>
          </Grid>
        </Tooltip>
      </Grid>
      <Grid className="weight-over-beam-content-level-wrapper" item>
        {allWeights.map((containerWeight) => (
          <Grid
            className="weight-over-beam-content-level-item"
            item
            sx={{
              height: `${
                containerWeight > 0 ? `${containerWeight / 10000}px` : "0px"
              } !important`,
            }}
          ></Grid>
        ))}
      </Grid>
    </>
  );
};

const defaultWeights = (containerWeights: any) => {
  if (containerWeights.length === 0 || containerWeights.length < 3) {
    return "";
  }

  let totalContainerWeights = getContainerTotalWeights(containerWeights);

  return (
    <>
      <Grid
        item
        className="weight-over-beam-content-wrapper"
        sx={{ marginBottom: "10px" }}
      >
        <Grid
          className="weight-over-beam-content"
          item
          sx={{ width: "100% !important" }}
        >
          <Grid className="weight-over-beam-content-tonnage" item>
            {totalContainerWeights.map((containerWeight) => (
              <>
                <Grid
                  className="weight-over-beam-content-tonnage-per-row"
                  item
                  sx={{ width: "35% !important" }}
                >
                  <small>{containerWeight.range}</small>
                  <Typography>
                    {(containerWeight.total / 1000).toFixed(0)}T
                  </Typography>
                </Grid>
              </>
            ))}
          </Grid>
          <Grid className="weight-over-beam-content-level-wrapper" item>
            {containerWeights.map((containerWeight) => (
              <Grid
                className="weight-over-beam-content-level-item"
                item
                sx={{
                  height: `${
                    containerWeight > 0 ? `${containerWeight / 10000}px` : "0px"
                  } !important`,
                }}
              ></Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const sideWeights = (containerWeights: any) => {
  return {
    starboard:
      containerWeights[0] > 0 || containerWeights[1] > 0
        ? parseInt(
            ((containerWeights[0] + containerWeights[1]) / 1000).toFixed(0)
          )
        : 0,
    port:
      containerWeights[2] > 0 || containerWeights[3] > 0
        ? parseInt(
            ((containerWeights[2] + containerWeights[3]) / 1000).toFixed(0)
          )
        : 0,
  };
};

const sideWeightChecker = (vessel: Vessel, containerWeights: any) => {
  const sideWeightData = sideWeights(containerWeights);
  const beamWarnings =
    vessel.beamWarnings && vessel.beamWarnings.length > 0
      ? vessel.beamWarnings
      : [20, 35, 50, 60];
  const sideWeightDifference = Math.abs(
    sideWeightData.starboard - sideWeightData.port
  );
  let overTheLimit = false;
  let isPortSideHeavier = true;

  if (sideWeightData.starboard > sideWeightData.port) {
    isPortSideHeavier = false;
  }

  if (sideWeightDifference > 60) {
    overTheLimit = true;
  }

  const result = {
    isPortSideHeavier: isPortSideHeavier,
    overTheLimit: overTheLimit,
    beamWarnings: beamWarnings,
    weightDistribution: [false, false, false, false],
  };

  if (sideWeightDifference > beamWarnings[3]) {
    return {
      ...result,
      weightDistribution: [true, true, true, true],
    };
  } else if (
    sideWeightDifference <= beamWarnings[3] &&
    sideWeightDifference >= beamWarnings[2]
  ) {
    return {
      ...result,
      weightDistribution: [false, true, true, true],
    };
  } else if (
    sideWeightDifference <= beamWarnings[2] &&
    sideWeightDifference >= beamWarnings[1]
  ) {
    return {
      ...result,
      weightDistribution: [false, false, true, true],
    };
  } else if (
    sideWeightDifference <= beamWarnings[1] &&
    sideWeightDifference >= beamWarnings[0]
  ) {
    return {
      ...result,
      weightDistribution: [false, false, false, true],
    };
  } else {
    return result;
  }
};

const GetContainerGroupedByAxis = (
  vessel: Vessel,
  containers: any,
  terminalCall: TerminalCall | undefined,
  axisType: string
) => {
  let containerGroupedByAxis = [];

  if (!vessel || !vessel.positions || terminalCall === undefined) {
    return "";
  }

  const vesselId = vessel["@id"].replace("/api/vessels/", "");
  let maxNumberAxis = Math.max(...vessel.positions[0]) ?? 0;
  let position = 2;

  if (axisType === WEIGHT_X_AXIS_TYPE) {
    maxNumberAxis =
      vessel && vessel.positions && vessel.positions.length > 0
        ? vessel.positions[0].length
        : 0;
    position = 1;
  }

  for (let i = 1; i <= maxNumberAxis; i++) {
    containerGroupedByAxis[i] = containers
      .filter(
        (container: Container) =>
          container.position !== undefined &&
          container.position[position] === i &&
          container.position[3] === vesselId
      )
      .reduce((prev, current) => {
        return prev + current.weight;
      }, 0);
  }

  return containerGroupedByAxis.filter(function () {
    return true;
  });
};

const GetContainerGroupedByLayer = (vessel: Vessel, containers: any) => {
  let containerGroupedByLayer = [];
  let containerGroupedByLayerAndRow = [];
  const vesselId = vessel["@id"].replace("/api/vessels/", "");
  const numberOfLayers = vessel.positions.length;
  const maxNumberAxis = Math.max(...vessel.positions[0]) ?? 0;

  if (numberOfLayers === 0 || containers.length === 0) {
    return "";
  }

  for (let i = 1; i <= numberOfLayers; i++) {
    containerGroupedByLayer[i] = containers
      .filter(
        (container: Container) =>
          container.position !== undefined &&
          container.position[0] === i &&
          container.position[3] === vesselId
      )
      .reduce((prev, current) => {
        return prev + current.weight;
      }, 0);
  }

  return (
    <Grid item sx={{ display: "flex", flexDirection: "column-reverse" }}>
      {containerGroupedByLayer
        .filter(function () {
          return true;
        })
        .map((weightsByLayer, layer) => (
          <Grid
            item
            sx={{
              display: "flex",
              marginBottom: "5px",
              gap: "2px",
              padding: "2px",
            }}
          >
            <Grid item sx={{ display: "flex", alignItems: "flex-end" }}>
              {`L${layer + 1}`}
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: "2px",
              }}
            >
              <Grid
                item
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Grid
                  item
                  sx={{
                    backgroundColor: "#1976D2",
                    width: `${
                      weightsByLayer > 0
                        ? `${weightsByLayer / 1000 / 4}px`
                        : "0px"
                    } !important`,
                  }}
                ></Grid>
                <Grid item>{(weightsByLayer / 1000).toFixed(0)}T</Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}
    </Grid>
  );
};

const VesselLoadStability = ({ vessel, containers, terminalCall }) => {
  const containerGroupedByYAxis = GetContainerGroupedByAxis(
    vessel,
    containers,
    terminalCall,
    WEIGHT_Y_AXIS_TYPE
  );

  const containerGroupedByXAxis = GetContainerGroupedByAxis(
    vessel,
    containers,
    terminalCall,
    WEIGHT_X_AXIS_TYPE
  );

  if (
    containerGroupedByYAxis.length === 0 ||
    containerGroupedByXAxis.length === 0
  ) {
    return "";
  }

  return (
    <>
      <Typography variant="subtitle1" className="vessel-stability-title">
        Weight over length
      </Typography>
      {vessel.weightGroup
        ? vesselWeightGroups(containerGroupedByXAxis, vessel.weightGroup)
        : defaultWeights(containerGroupedByXAxis)}
      <Typography variant="subtitle1" className="vessel-stability-title">
        Weight over beam
      </Typography>
      <Grid item className="weight-over-beam-content-wrapper">
        <Grid
          className="weight-over-beam-sides weight-over-beam-sides--starboard"
          item
        >
          SB
        </Grid>
        <Grid className="weight-over-beam-content" item>
          <Grid className="weight-over-beam-content-tonnage" item>
            {containerGroupedByYAxis.map((containerWeight) => (
              <Grid className="weight-over-beam-content-tonnage-per-row" item>
                {(containerWeight / 1000).toFixed(0)}T
              </Grid>
            ))}
          </Grid>
          {containerGroupedByYAxis.length === 4 ? (
            <Grid className="weight-over-beam-content-tonnage-division" item>
              <Grid className="weight-over-beam-content-tonnage-item" item>
                {sideWeights(containerGroupedByYAxis).starboard}T
              </Grid>
              <Grid className="weight-over-beam-content-tonnage-item" item>
                {sideWeights(containerGroupedByYAxis).port}T
              </Grid>
            </Grid>
          ) : (
            ""
          )}
          <Grid className="weight-over-beam-content-level-wrapper" item>
            {containerGroupedByYAxis.map((containerWeight) => (
              <Grid
                className="weight-over-beam-content-level-item"
                item
                sx={{
                  height: `${
                    containerWeight > 0 ? `${containerWeight / 10000}px` : "0px"
                  } !important`,
                }}
              ></Grid>
            ))}
          </Grid>
        </Grid>
        <Grid
          className="weight-over-beam-sides weight-over-beam-sides--port"
          item
        >
          P
        </Grid>
      </Grid>
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <Grid className="side-weights" item>
          {sideWeightChecker(
            vessel,
            containerGroupedByYAxis
          ).weightDistribution.map((weight, index) => (
            <Tooltip
              title={`${
                sideWeightChecker(vessel, containerGroupedByYAxis)
                  ?.beamWarnings[3 - index] ?? 0
              }T`}
            >
              {weight &&
              !sideWeightChecker(vessel, containerGroupedByYAxis)
                .isPortSideHeavier &&
              sideWeightChecker(vessel, containerGroupedByYAxis).overTheLimit &&
              index === 0 ? (
                <BlinkedBox
                  className={`side-weight side-weight-starboard side-weight-starboard--active`}
                />
              ) : (
                <CircleIcon
                  fontSize="10px"
                  className={`side-weight side-weight-starboard ${
                    weight &&
                    !sideWeightChecker(vessel, containerGroupedByYAxis)
                      .isPortSideHeavier
                      ? "side-weight-starboard--active"
                      : ""
                  }`}
                />
              )}
            </Tooltip>
          ))}
        </Grid>
        <Grid className="side-weights side-weights--portside" item>
          {sideWeightChecker(
            vessel,
            containerGroupedByYAxis
          ).weightDistribution.map((weight, index) => (
            <Tooltip
              title={`${
                sideWeightChecker(vessel, containerGroupedByYAxis)
                  ?.beamWarnings[3 - index] ?? 0
              }T`}
            >
              {weight &&
              sideWeightChecker(vessel, containerGroupedByYAxis)
                .isPortSideHeavier &&
              sideWeightChecker(vessel, containerGroupedByYAxis).overTheLimit &&
              index === 0 ? (
                <BlinkedBox
                  className={`side-weight side-weight-port side-weight-port--active`}
                />
              ) : (
                <CircleIcon
                  fontSize="10px"
                  className={`side-weight side-weight-port ${
                    weight &&
                    sideWeightChecker(vessel, containerGroupedByYAxis)
                      .isPortSideHeavier
                      ? "side-weight-port--active"
                      : ""
                  }`}
                />
              )}
            </Tooltip>
          ))}
        </Grid>
      </Grid>
      {GetContainerGroupedByLayer(vessel, containers)}
      <Grid item sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
        <Grid item>
          <Typography variant="subtitle1" className="vessel-stability-title">
            Total on departure
          </Typography>
          <Grid item>
            <Grid item>
              {`Total weight: ${
                sideWeights(containerGroupedByYAxis).starboard +
                sideWeights(containerGroupedByYAxis).port
              }T`}
            </Grid>
            <Grid item>
              {`Total planned container weight: ${containers.reduce(
                (acc, container) => (acc += container.weight),
                0
              )/1000}T`}
            </Grid>
            {vessel.isBarge && (
              <>
                <Grid item>
                  Total draught:{" "}
                  {terminalCall.bargedepartureState?.statistics?.draught} cm
                </Grid>
                <Grid item>
                  Total weight (vessel+barge):{" "}
                  {terminalCall.bargedepartureState?.statistics?.total_weight.toFixed(0)}{"T"}
                </Grid>
              </>
            )}
            {!vessel.isBarge && (
              <>
                <Grid item>
                  Total draught:{" "}
                  {terminalCall.departureState?.statistics?.draught} cm
                </Grid>
                <Grid item>
                  Total weight (vessel+barge):{" "}
                  {terminalCall.departureState?.statistics?.total_weight.toFixed(0)}{"T"}
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1" className="vessel-stability-title">
            Stability
          </Typography>
          <Grid item>
            <Grid item>
              Max stability KG:{" "}
              {terminalCall.departureState?.stability?.cog_loaded_max["z"]}
            </Grid>
            <Grid item sx={{display: "flex", flexDirection: "row"}}>
              <Typography>
                Stability KG:{" "}
              </Typography>
              <Typography className={terminalCall.departureState?.stability?.cog_loaded["z"] > terminalCall.departureState?.stability?.cog_loaded_max["z"] ? "vessel-stability-overload": ""}>
                {terminalCall.departureState?.stability?.cog_loaded["z"]}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default VesselLoadStability;
