import contactsReducer from "./contacts";
import labelsReducer from "./labels";
import vesselsReducer from "./vessels";
import usersReducer from "./user";
import containersReducer from "./containers";
import containertypesReducer from "./containertype";
import fileReducer from "./files";
import terminalsReducer from "./terminals";
import terminalcallsReducer from "./terminalcall";
import notesReducer from "./notes";
import modalReducer from "./modal";
import filterReducer from "./filter";
import { configureStore } from "@reduxjs/toolkit";

const store = configureStore({
  reducer: {
    user: usersReducer,
    vessels: vesselsReducer,
    containers: containersReducer,
    containerTypes: containertypesReducer,
    files: fileReducer,
    terminals: terminalsReducer,
    terminalCalls: terminalcallsReducer,
    contacts: contactsReducer,
    labels: labelsReducer,
    modal: modalReducer,
    notes: notesReducer,
    filter: filterReducer,
  },
});

export default store;
