import React from "react";
import dayjs from "dayjs";
import { ListItem, Chip, List as MUIList, Stack, Grid, Tooltip } from "@mui/material";
import {LockSharp as LockSharpIcon, LockOpenSharp as LockOpenSharpIcon } from '@mui/icons-material';
import { Label } from "../types/Label";
import { Terminal } from "../types/Terminal";
import { TerminalCall } from "../types/TerminalCall";
import { Vessel } from "../types/Vessel";


export const findObjectById = (objectArray: any, objectId: string) => {
    if (!objectArray || objectArray.length === 0) {
        return undefined;
    }

    return objectArray.find(
      (object: any) => object && object["@id"] && object["@id"] === objectId
    );
};

export const formateDate = (date: Date) => {
    return date ? dayjs(date).format("DD/MM/YYYY") : "";
};

export const colorCodeIsLight = (color: string) => {
  const hex = color.replace('#', '');
  const c_r = parseInt(hex.substring(0, 0 + 2), 16);
  const c_g = parseInt(hex.substring(2, 2 + 2), 16);
  const c_b = parseInt(hex.substring(4, 4 + 2), 16);
  const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;

  return brightness > 155;
}

export const textColorHandler = (color: string) => {
  if (!color) {
    return '#6C757D';
  }
  
  return colorCodeIsLight(color) ? '#6C757D' : '#FFFFFF';
}

export const LabelColorFormatter = (color:string|undefined): string => {
    const defaultColor = '#2196f3'
  
    if (color === undefined) {
      return defaultColor;
    }
    
    color = color.replace(/\s/g, '')
    color = color.replace(/\#/g, '')
    color = `#${color.toUpperCase()}`
    
    if (!(/^#([0-9a-f]{3}){1,2}$/i).test(color)) {
      return defaultColor;
    }
  
    return color;
};

export const formatDateToString = (date: Date|undefined, format = "DD/MM/YYYY") => {
  return date ? dayjs(date).format(format) : "";
};

export const RenderLabelChip = (label: string, color: string) => {
  return <Chip size="small" label={label} sx={{ backgroundColor: `${ color } !important`, color: `${ textColorHandler(color) } !important` }} />
}

export const RenderLabels = (labels: Label[]) => {
  if (labels === undefined || !labels) {
    return <></>;
  }
  
  return (
    <>
      <MUIList key={"label-list"} component={Stack} direction="row" sx={{ padding: 0 }}>
        {labels.map((label: any) => (
          <ListItem
            sx={{ display: "flex", width: 'auto', padding: '4px' }}
            key={ label["@id"] }
          >
            { RenderLabelChip(label.name, label.color) }
          </ListItem>
        ))}
      </MUIList>
    </>
  );
}

export const RenderTerminalName = (terminal: Terminal) => {
  return (
    <>
      <Grid
        item
        sx={{
          height: "15px",
          width: "5px !important",
          backgroundColor: terminal.color ?? "#FFFFF",
          border: "1px solid #DDDDDD",
        }}
      ></Grid>
      &nbsp;{ terminal && terminal.name ? terminal.name : ''}
    </>
  )
}

export const terminalCallFormatter = (terminalCalls: TerminalCall[]) => {
  const formattedTerminalCallList = [];

  if (!terminalCalls) {
    return formattedTerminalCallList;
  }

  terminalCalls.sort(function (
    a: any,
    b: any
  ) {
    return Number(new Date(a.planned)) - Number(new Date(b.planned));
  });

  terminalCalls.map((termincalCall: TerminalCall) => {
    formattedTerminalCallList[
      formatDateToString(termincalCall.planned)
    ] ??= [];
    formattedTerminalCallList[
      formatDateToString(termincalCall.planned)
    ].push(termincalCall);
  });

  return formattedTerminalCallList;
}

export const containerUidFormatter = (uid) => {
  const letters = uid.substring(0,4)
  const numbers = uid.substring(4,uid.length)
  return {
    letters: letters,
    numbers: numbers,
  }
}

export const RenderTerminalCallLock = (terminalCall: TerminalCall) => {
  return (
    <>
      { terminalCall.loadingPlanLocked ? (
        <Tooltip title="The captain has approved the stowage plan, therefore the planning on this terminal is locked for editing.">
          <LockSharpIcon fontSize='inherit' sx={{ color: '#666666' }} />
        </Tooltip>
      ) : (
        <LockOpenSharpIcon fontSize='inherit' sx={{ color: '#666666' }} />
      )}
    </>
  )
}

export const getTerminalCallArrivalStateContainerIds = (terminalCall: TerminalCall) => {
  const arrivalStateContainerIds = [];

  if (terminalCall && terminalCall.arrivalState && terminalCall.arrivalState.container_positions) {
    const arrivalStateContainerPositions = terminalCall.arrivalState.container_positions;

    arrivalStateContainerPositions.map((position) => {
      arrivalStateContainerIds.push(`/api/containers/${position[0]}`)
    })
  }

  return arrivalStateContainerIds;
}

export const getTerminalCallLoadingContainerIds = (terminalCall: TerminalCall) => {
  const filteredContainerIds = getTerminalCallArrivalStateContainerIds(terminalCall).filter(
    function(e) {
      return this.indexOf(e) < 0;
    },
    terminalCall.unloadingContainers
  ) ?? [];

  return [...filteredContainerIds, ...terminalCall.loadingContainers];
}

export const hasPositionBarrier = (axis:any, vessel: Vessel) => {
  let requirementsObtained:any = [];

  if (!vessel || vessel.barriers.length === 0) {
    return false;
  }

  vessel.barriers.map((barrierPosition: any) => {
    requirementsObtained.push(JSON.stringify(barrierPosition) === JSON.stringify(axis))
  })
  
  return requirementsObtained.includes(true);
}

export const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}
  
export const stringAvatar = (name: string) => {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}
