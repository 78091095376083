import React, { useEffect } from "react";
import dayjs from "dayjs";
import { useForm, SubmitHandler } from "react-hook-form"
import { getTerminalCalls, updateTerminalCall } from "../../data/terminalcall";
import { removeNote } from "../../data/notes";
import { ContainerType } from '../../types/ContainerType';
import { InputTextareaField } from '../FormFields/RenderInputTextareaField';
import { useDispatch } from "react-redux";
import { hideModal } from "../../data/modal";
import { Note } from '../../types/Note';

import {
    Button,
    Grid,
    IconButton,
    List as MuiList,
    ListItem,
    ListItemText,
    Paper,
} from "@mui/material";

import {
    Close as CloseIcon,
    Delete as DeleteIcon,
    Save as SaveIcon,
} from '@mui/icons-material';

const TerminalCallNoteForm = ({ terminalCall }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getTerminalCalls())
    }, [dispatch]);

    const { handleSubmit, control, formState } = useForm({
        defaultValues: new Note,
    });

    const { isSubmitting } = formState;

    const onSubmit: SubmitHandler<ContainerType> = (data: Note) => {   
        const terminalCallNoteData = {
            ...terminalCall,
            notes: [
                ...terminalCall.notes,
                ...[data]
            ],
        }

        dispatch(updateTerminalCall(terminalCallNoteData))
    }
        
    return (
        <form onSubmit={ handleSubmit(onSubmit) }>
            <Grid container sx={{ maxWidth: '40vh' }}>
                <Grid item xs={12} sm={12}>
                    <MuiList sx={{ width: '100%' }}>
                        { terminalCall.notes && terminalCall.notes.map((note: Note) => (
                            <ListItem alignItems="flex-start" component={ Paper } sx={{ marginTop: '5px', border: '1px solid #DDDDDD' }}>
                                <ListItemText
                                    primary={ note.note }
                                    secondary={ note.user ?  `${ note.updatedAt ? dayjs(note.updatedAt ).format("DD/MM/YYYY HH:mm") : ""} - ${note.user.firstName ?? ''} ${note.user.lastName ?? ''}`: '' }
                                />
                                <IconButton 
                                    onClick={() => {dispatch(removeNote(note))}}
                                >
                                    <DeleteIcon sx={{ fontSize: 20 }}/> 
                                </IconButton>
                            </ListItem>
                        ))}
                    </MuiList>
                </Grid>
                <Grid item xs={12} sm={12} sx={{ marginTop: '10px' }}>
                    <Grid item xs={12} className='form-fields-container'>
                        <Grid item xs={12} sm={12}>
                            <InputTextareaField
                                name='note'
                                label='Note'
                                control={ control }
                                required
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className='button-wrapper'>
                    <Button 
                        onClick={() => {dispatch(hideModal())}}
                        disabled={ isSubmitting }
                        variant='contained'
                    >
                        <CloseIcon fontSize="inherit" />&nbsp;Close
                    </Button>
                    <Button 
                        type='submit'
                        disabled={ isSubmitting }
                        variant='contained'
                    >
                        <SaveIcon fontSize="inherit" />&nbsp;Save
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

export default TerminalCallNoteForm;