import React, { useState } from "react";
import axios from "axios";
import { useDropzone } from 'react-dropzone';
import { useDispatch } from "react-redux";
import { Controller } from "react-hook-form";
import { showModal } from "../../data/modal";

import { 
  Box, 
  IconButton,
  Grid, 
  Typography, 
  FormControl,
  List as MuiList,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
 } from "@mui/material";

 import {
  InsertDriveFileSharp as InsertDriveFileSharpIcon,
  FileOpenSharp as FileOpenSharpIcon,
  ClearSharp as ClearSharpIcon,
  FileCopySharp as FileCopySharpIcon,
  UploadFileSharp as UploadFileSharpIcon,
} from "@mui/icons-material";

export const MultipleFileField: any = ({
  name,
  control,
  register,
  setValue,
  existingFiles,
}) => {
  const dispatch = useDispatch();
  const [existingFilesData, setExistingFilesData] = useState(existingFiles);

  const dropzone = useDropzone({
    onDrop: (files: File[]) => files && fileDropHandler(files),
  });

  const uploadHandler = async (formData) => {
    const response = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/files`,
      headers: {
        "Content-Type": "application/ld+json; charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: formData,
    });
    
    if (response.status === 200 || response.status === 201) {
      return response.data;
    } else {
      console.log("Something went wrong updating");
    }
  }

  const fileDropHandler = (files) => {
    const formData = new FormData();
    const uploadedFilesData = [];
    const promises = [];

    files.map((file:File) => {
        formData.append('file', file)
        promises.push(uploadHandler(formData).then((res) => 
          uploadedFilesData.push(res)
      ))
    })

    axios.all(promises).then(() => setValue(name, [...uploadedFilesData, ...existingFilesData]));
  }

  const fileRemoveHandler = (element) => {
    element.preventDefault();
    const existingFileWrapper = element.target.closest('.js-existing-file-element');
    const fileId = existingFileWrapper.getAttribute('data-existing-file-id');
    const filteredFiles = existingFilesData.filter(
      (file) =>
        file['@id'] !== fileId
    )

    setValue(name, filteredFiles);
    setExistingFilesData(filteredFiles);
  }

  const fileView = (file) => {
    dispatch(
      showModal({
        title: 'View File',
        description: `${ file.discription ?? 'File' }`,
        form: "fileview",
        formProps: {
          file: file,
        },
      })
    )
  }

  return (
    <>
    <Grid container>
        <Typography variant='h6' className='form-fields-title'>
            Files
        </Typography>
        <Grid  item xs={12} sm={12} sx={{ display: 'flex' }}>
          <Grid item xs={12} sm={6} className='form-fields-container'>
            <FormControl size={"small"} sx={{ width: '100%' }}>
              <Controller
                control={control}
                render={() => (
                  <Grid item {...dropzone.getRootProps({ className: 'dropzone' })} sx={{ minHeight: '20vh', height: '100%', border: '2px dotted #DDDDDD', display:'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Box 
                      component='input' 
                      {...dropzone.getInputProps({
                        ...register(name),
                      })}
                    />
                    <p>Drag 'n' drop some files here, or click to select files</p>
                  </Grid>
                )}
                name={ name }
                defaultValue={[]}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} paddingLeft={2}>
            <MuiList>
              { existingFilesData && existingFilesData.length > 0 ? (
                <ListItem disabled>
                    <ListItemIcon>
                      <FileCopySharpIcon />
                    </ListItemIcon>
                    <ListItemText primary='Files' />
                </ListItem>
              ) : ''}
              { existingFilesData && existingFilesData.length > 0 &&
                existingFilesData.map((file, index) => (
                  <ListItem 
                    disablePadding 
                    className='js-existing-file-element' 
                    data-existing-file-id={file['@id']}
                    key={`file-view-existing-file-${index}`}
                  >
                    <ListItemButton 
                      onClick={() => fileView(file) }
                    >
                      <ListItemIcon>
                        <InsertDriveFileSharpIcon />
                      </ListItemIcon>
                      <ListItemText primary={`${ file.description }`} />
                    </ListItemButton>
                    <IconButton
                        onClick={(e) => fileRemoveHandler(e) }
                    >
                        <ClearSharpIcon />
                    </IconButton>
                  </ListItem>
                ))
              }
              {dropzone.acceptedFiles && dropzone.acceptedFiles.length > 0 ? (
                <ListItem disabled>
                    <ListItemIcon>
                      <UploadFileSharpIcon />
                    </ListItemIcon>
                    <ListItemText primary='Dropped files' />
                </ListItem>
              ) : ''}
              { dropzone.acceptedFiles  && dropzone.acceptedFiles.map((file, index) => (
                <ListItem disablePadding key={`uploaded-file-${ index }`}>
                  <ListItemButton>
                    <ListItemIcon>
                      <FileOpenSharpIcon />
                    </ListItemIcon>
                    <ListItemText primary={`${ file.name }`} />
                  </ListItemButton>
                </ListItem>
              ))}
            </MuiList>
          </Grid>
        </Grid>
    </Grid>
    </>
  );
};