import React from "react";
import "../styles/modal.css";
import {
  Box,
  Fade,
  Modal as MuiModal,
  Grid,
  Paper,
  Button,
} from "@mui/material";

import { Close as CloseIcon } from "@mui/icons-material";
import { connect, ConnectedProps } from "react-redux";
import { hideChildModal, hideModal } from "../data/modal";
import ContainerForm from "../components/Forms/Container";
import ContainerTypeForm from "../components/Forms/ContainerType";
import LabelForm from "../components/Forms/Label";
import TenantForm from "../components/Forms/Tenant";
import TerminalForm from "../components/Forms/Terminal";
import TerminalCallForm from "../components/Forms/TerminalCall";
import VesselForm from "../components/Forms/Vessel";
import ContactForm from "../components/Forms/Contact";
import ContainerModal from "../components/ModalView/Container";
import TerminalMapModal from "../components/ModalView/TerminalMap";
import TerminalCallNoteForm from "./Forms/TerminalCallNote";
import ContainerTerminalCallModal from "./ModalView/ContainerTerminalCall";
import FileView from "./partial/FileView";
import VesselGridSettings from "./Forms/VesselGridSettings";

const mapStateToProps = (state) => ({
  modal: state.modal.modal,
  childModal: state.modal.childModal,
});

const mapDispatchToProps = {
  dispatchHideModal: hideModal,
  dispatchHideChildModal: hideChildModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ModalProps = {} & ConnectedProps<typeof connector>;

function Modal(props: ModalProps) {
  const { dispatchHideModal, dispatchHideChildModal, modal, childModal } =
    props;

  if (!modal) {
    return null;
  }

  const onCloseButtonClick = () => {
    dispatchHideModal();
  };

  const onChildCloseButtonClick = () => {
    dispatchHideChildModal();
  };

  return (
    <MuiModal
      className="mui-modal-container"
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={true}
    >
      <Fade in={true}>
        <Box>
          <Grid container component={Paper} className="modal-wrapper">
            <Grid item className="modal-header">
              <Grid item>{modal.title ?? ""}</Grid>
              <Grid item>
                <Button onClick={onCloseButtonClick}>
                  <CloseIcon fontSize="inherit" />
                </Button>
              </Grid>
            </Grid>
            <Grid item className="modal-content">
              {modal.form && modal.form === "container" && (
                <ContainerForm {...modal.formProps} />
              )}
              {modal.form && modal.form === "containerType" && (
                <ContainerTypeForm {...modal.formProps} />
              )}
              {modal.form && modal.form === "label" && (
                <LabelForm {...modal.formProps} />
              )}
              {modal.form && modal.form === "tenant" && (
                <TenantForm {...modal.formProps} />
              )}
              {modal.form && modal.form === "terminal" && (
                <TerminalForm {...modal.formProps} />
              )}
              {modal.form && modal.form === "terminalCall" && (
                <TerminalCallForm {...modal.formProps} />
              )}
              {modal.form && modal.form === "vessel" && (
                <VesselForm {...modal.formProps} />
              )}
              {modal.form && modal.form === "contact" && (
                <ContactForm {...modal.formProps} />
              )}
              {modal.form && modal.form === "terminalmapmodal" && (
                <TerminalMapModal {...modal.formProps} />
              )}
              {modal.form && modal.form === "containerviewmodal" && (
                <ContainerModal {...modal.formProps} />
              )}
              {modal.form && modal.form === "note" && (
                <TerminalCallNoteForm {...modal.formProps} />
              )}
              {modal.form && modal.form === "containerterminalcall" && (
                <ContainerTerminalCallModal {...modal.formProps} />
              )}
              {modal.form && modal.form === "fileview" && (
                <FileView {...modal.formProps} />
              )}
              {modal.form && modal.form === "vesselgridsettings" && (
                <VesselGridSettings {...modal.formProps} />
              )}
            </Grid>
          </Grid>
          {childModal && (
            <>
              <MuiModal
                className="mui-modal-container"
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={true}
              >
                <Fade in={true}>
                  <Box>
                    <Grid container component={Paper} className="modal-wrapper">
                      <Grid item className="modal-header">
                        <Grid item>{childModal.title ?? ""}</Grid>
                        <Grid item>
                          <Button onClick={onChildCloseButtonClick}>
                            <CloseIcon fontSize="inherit" />
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid item className="modal-content">
                        {childModal.form && childModal.form === "container" && (
                          <ContainerForm {...childModal.formProps} />
                        )}
                        {childModal.form &&
                          childModal.form === "containerType" && (
                            <ContainerTypeForm {...childModal.formProps} />
                          )}
                        {childModal.form && childModal.form === "label" && (
                          <LabelForm {...childModal.formProps} />
                        )}
                        {childModal.form && childModal.form === "tenant" && (
                          <TenantForm {...childModal.formProps} />
                        )}
                        {childModal.form && childModal.form === "terminal" && (
                          <TerminalForm {...childModal.formProps} />
                        )}
                        {childModal.form &&
                          childModal.form === "terminalCall" && (
                            <TerminalCallForm {...childModal.formProps} />
                          )}
                        {childModal.form && childModal.form === "vessel" && (
                          <VesselForm {...childModal.formProps} />
                        )}
                        {childModal.form && childModal.form === "contact" && (
                          <ContactForm {...childModal.formProps} />
                        )}
                        {childModal.form &&
                          childModal.form === "terminalmapModal" && (
                            <TerminalMapModal {...childModal.formProps} />
                          )}
                        {childModal.form &&
                          childModal.form === "containerviewModal" && (
                            <ContainerModal {...childModal.formProps} />
                          )}
                        {childModal.form && childModal.form === "note" && (
                          <TerminalCallNoteForm {...childModal.formProps} />
                        )}
                        {childModal.form &&
                          childModal.form === "containerterminalcall" && (
                            <ContainerTerminalCallModal
                              {...childModal.formProps}
                            />
                          )}
                        {childModal.form && childModal.form === "fileview" && (
                          <FileView {...childModal.formProps} />
                        )}
                        {childModal.form &&
                          childModal.form === "vesselgridsettings" && (
                            <VesselGridSettings {...childModal.formProps} />
                          )}
                      </Grid>
                    </Grid>
                  </Box>
                </Fade>
              </MuiModal>
            </>
          )}
        </Box>
      </Fade>
    </MuiModal>
  );
}

export default connector(Modal);
