import './App.css';
import './index.css';
import React from 'react';
import { Provider } from "react-redux";
import store from "./data";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/login.tsx';
import Logistics from './components/logistics/index.tsx';
import ContactManage from './components/settings/contact/index.tsx';
import ContainerPlanner from './components/planner/index.tsx';
import ContainerTypeManage from './components/settings/containerType/index.tsx';
import LabelManage from './components/settings/label/index.tsx';
import TerminalManage from './components/settings/terminal/index.tsx';
import VesselManage from './components/settings/vessel/index.tsx';
import TerminalView from './components/terminalview/index.tsx';
import Reports from './components/reports/index.tsx';

function App() {
  return (
    <Provider store={store} >
      <Router>
        <Routes>
          <Route path='/' Component={Login}></Route>
          <Route path='/logistics' Component={Logistics}></Route>
          <Route path='/container-planner' Component={ContainerPlanner}></Route>
          <Route path='/terminal-view' Component={TerminalView}></Route>
          <Route path='/reports' Component={Reports}></Route>
          <Route path='/settings/terminal' Component={TerminalManage}></Route>
          <Route path='/settings/container-type' Component={ContainerTypeManage}></Route>
          <Route path='/settings/vessel' Component={VesselManage}></Route>
          <Route path='/settings/contact' Component={ContactManage}></Route>
          <Route path='/settings/label' Component={LabelManage}></Route>
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
