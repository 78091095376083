import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../Layout.tsx";
import { getTerminals } from "../../../data/terminals";
import { Terminal } from "../../../types/Terminal";
import { showModal } from "../../../data/modal";
import Modal from "../../Modal.tsx";

import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Typography,
} from "@mui/material";

import { 
  Edit as EditIcon,
  Map as MapIcon 
} from "@mui/icons-material";
import SettingsMenu from "../Menu";

const TerminalManage = () => {
  const dispatch = useDispatch();

  const selectedData = useSelector((state) => ({
    terminals: state.terminals,
  }));

  useEffect(() => {
    dispatch(getTerminals());
  }, [dispatch]);

  const { terminals } = selectedData;

  return (
    <Layout>
      <Modal/>
      <Grid container>
        <Grid item xs={12} sm={2} padding={1}>
          <Grid item component={ Paper }  sx={{ height: 'auto' }}>
            <SettingsMenu activeMenu={ 'terminal' }/>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={10} padding={1}>
          <Grid item component={ Paper }  sx={{ height: 'auto' }}>
            <Grid className='settings-page-header' item xs={12} sm={12} padding={1}>
              <Grid item>
                <Typography variant="h6" gutterBottom>
                  Terminal
                </Typography>
              </Grid>
              <Grid item>
              <Button
                  onClick={() => {
                    dispatch(
                      showModal({
                        title: "Add terminal",
                        description: "Creates a new terminal",
                        form: "terminal"
                      })
                    );
                  }}
                  variant='contained'
                  size='small'
                >
                  Add terminal
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} padding={1} sx={{ height: '80vh' }}>
              <TableContainer>
                <Table aria-label="terminal table" stickyHeader>
                  <TableHead>
                      <TableRow>
                        <TableCell>
                          Name
                        </TableCell>
                        <TableCell align='center'>
                          City
                        </TableCell>
                        <TableCell align='center'>
                          Color
                        </TableCell>
                        <TableCell align='center'>
                          Location
                        </TableCell>
                        <TableCell align='right'></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                        { terminals && terminals.data.length !== 0 && terminals.data.map((terminal: Terminal) => (
                          <TableRow>
                            <TableCell>{ `${ terminal.abbreviation ? `${ terminal.abbreviation } - ` : '' }${ terminal.name }` }</TableCell>
                            <TableCell align='center'>{terminal.city ?? ''}</TableCell>
                            <TableCell align='center'>{terminal.color ?? ''}</TableCell>
                            <TableCell align='center'>
                              <IconButton onClick={() => dispatch(
                                showModal({
                                  title: "Show map",
                                  description: "show map",
                                  form: "terminalmapmodal",
                                  formProps: {
                                    terminal: terminal
                                  },
                                })
                              )}>
                                <MapIcon size='small'/> 
                              </IconButton>
                            </TableCell>
                            <TableCell align='right'>
                              <IconButton 
                              onClick={() => dispatch(
                                showModal({
                                  title: "Edit terminal",
                                  description: "Edit terminal",
                                  form: "terminal",
                                  formProps: {
                                    terminal: terminal
                                  },
                                })
                              )}>
                                <EditIcon size='small'/> 
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default TerminalManage;
