import { Contact } from "../../types/Contact";

const GetContactOptions = (contacts: Contact[]) => {
  const contactOptions = [];

  
  if (!contacts || contacts.length === 0) {
      return contactOptions
  }

  contacts.map((contact: Contact) => { 
    contactOptions.push({
      value: contact['@id'],
      label: `${contact.name}${ contact.phoneNumber ? `- ${ contact.phoneNumber }` : ''}`
    })
  })

  return contactOptions;
}

export default GetContactOptions;