import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, SubmitHandler } from "react-hook-form"
import { InputTextField } from '../FormFields/RenderInputTextField';
import { Vessel } from '../../types/Vessel';
import { SelectField } from '../FormFields/RenderSelectField';
import GetVesselOptions from '../FormHelpers/VesselSelectOptions';
import { getContacts } from "../../data/contacts";
import { SelectMultipleField } from '../FormFields/RenderSelectMultipleField';
import GetContactOptions from '../FormHelpers/ContactSelectOptions';
import { CheckboxField } from "../FormFields/RenderCheckboxField";
import { createVessel, updateVessel } from "../../data/vessels";
import { hideModal } from "../../data/modal";
import VesselWeightDraughtTable from "../Forms/VesselWeightDraughtTable";
import VesselDraughtKGMax from "./VesselDraughtKgMaxTable";

import {
    Button,
    Divider,
    Grid,
    Typography
} from "@mui/material";

import {
    Close as CloseIcon,
    Save as SaveIcon,
} from '@mui/icons-material';
import { NumberField } from "../FormFields/RenderNumberField";

const VesselForm = ({ vessel, vessels }) => {
    const dispatch = useDispatch();

    const selectedData = useSelector((state) => ({
        contacts: state.contacts,
    }));
    
    useEffect(() => {
        dispatch(getContacts());
    }, [dispatch]);
    
    const { contacts } = selectedData;

    const { handleSubmit, control, formState, register } = useForm({
        defaultValues: vessel ?? new Vessel(),
    });
    const { isSubmitting } = formState;

    const onSubmit: SubmitHandler<Vessel> = (data) => {
        if (vessel) {
            dispatch(updateVessel(data));
        } else {
            dispatch(createVessel(data));
        }  
    }

    return (
        <form onSubmit={ handleSubmit(onSubmit) }>
            <Grid container xs={12}>
                <Grid item xs={12} sm={12}>
                    <Grid item xs={12} className='form-fields-container'>
                        <Grid xs={12} sm={6}>
                            <InputTextField
                                name='name'
                                label='Name'
                                control={ control }
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <InputTextField
                                name='mmsi'
                                label='MMSI'
                                control={ control }
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <InputTextField
                                name='euid'
                                label='EUID'
                                control={ control }
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className='form-fields-container'>
                        <Grid item xs={12} sm={3}>
                            <NumberField
                                name='maxTeu'
                                label='Max TEU'
                                control={ control }
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <NumberField
                                name='maxWeight'
                                label='Max Weight(kg)'
                                control={ control }
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className='form-fields-container'>
                        <Grid item xs={12} sm={4}>
                            <SelectField
                                name='linkedVessel'
                                label='Linked Barge'
                                control={ control }
                                options={GetVesselOptions(vessels)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <SelectMultipleField
                                name='contacts'
                                label='Contacts'
                                control={ control }
                                options={GetContactOptions(contacts.data)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className='form-fields-container'>
                        <Grid item xs={12} sm={4}>
                            <CheckboxField
                                name='barge'
                                label='Barge'
                                control={ control }
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Divider/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography sx={{marginBottom: "10px"}}>Total weight & draught</Typography>
                    </Grid>
                    <Grid item xs={12} className='form-fields-container' sx={{ maxHeight: '25vh', overflowX: 'hidden', overflowY: 'auto' }}>
                        <Grid item xs={12} sm={6}>
                            <Typography sx={{marginBottom: "10px"}}>Fresh Water</Typography>
                            <VesselWeightDraughtTable
                                name='totalWeightDraughtTable.fresh'
                                label='fresh water total weight draught table'
                                control={ control }
                                register = { register }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <Typography sx={{marginBottom: "10px"}}>Salt Water</Typography>
                            <VesselWeightDraughtTable
                                name='totalWeightDraughtTable.salt'
                                label='salt water total weight draught table'
                                control={ control }
                                register = { register }
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Divider/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography sx={{marginBottom: "10px"}}>Center of gravity height for draught</Typography>
                    </Grid>
                    <Grid item xs={12} className='form-fields-container' sx={{ maxHeight: '25vh', overflowX: 'hidden', overflowY: 'auto' }}>
                        <Grid item xs={12} sm={6}>
                            <Typography sx={{marginBottom: "10px"}}>Secured</Typography>
                            <VesselDraughtKGMax
                                name='allowedCenterOfGravityHeightForDraughtTable.secured'
                                label='Secured center of gravity height'
                                control={ control }
                                register = { register }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <Typography sx={{marginBottom: "10px"}}>Unsecured</Typography>
                            <VesselDraughtKGMax
                                name='allowedCenterOfGravityHeightForDraughtTable.unsecured'
                                label='Unsecured center of gravity height'
                                control={ control }
                                register = { register }
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className='button-wrapper'>
                <Button 
                    onClick={() => {dispatch(hideModal())}}
                    disabled={ isSubmitting }
                    variant='contained'
                >
                    <CloseIcon fontSize="inherit" />&nbsp;Close
                </Button>
                    <Button 
                        type='submit'
                        disabled={ isSubmitting }
                        variant='contained'
                    >
                        <SaveIcon fontSize="inherit" />&nbsp;Save
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

export default VesselForm;