import { Vessel } from "../../types/Vessel";

const GetVesselOptions = (vessels: Vessel[], includeBarge: boolean = true) => {
  const vesselOptions = [];

  
  if (!vessels || vessels.length === 0) {
      return vesselOptions
  }

  if (!includeBarge) {
    vessels = vessels.filter((vessel: Vessel) => !vessel.barge)
  }

  vessels.map((vessel: Vessel) => { 
   vesselOptions.push({
      value: vessel['@id'],
      label: vessel.name
    })
  })

  return vesselOptions;
}

export default GetVesselOptions;