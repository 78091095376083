import "../../styles/planner.css";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../Layout.tsx";
import { TerminalCall } from "../../types/TerminalCall";
import { getTerminalCalls } from "../../data/terminalcall";
import { getVessels } from "../../data/vessels";
import { getContainers } from "../../data/containers";
import { getTerminals } from "../../data/terminals";
import { getContainerTypes } from "../../data/containertype";
import { Terminal } from "../../types/Terminal";
import TerminalCallDetail from "../partial/TerminalCallDetail";
import ContainerCounts from "../partial/ContainerCounts";
import { Container } from "../../types/Container";
import { Vessel } from "../../types/Vessel";
import GeneratePDF, { DOCUMENT_ORIENTATION_LANDSCAPE } from "../partial/GeneratePdf";

import { 
  RenderTerminalName, 
  containerUidFormatter, 
  formatDateToString, 
  hasPositionBarrier,
  terminalCallFormatter, 
  textColorHandler 
} from "../Helpers.tsx";

import {
  Alert,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  List as MuiList,
  ListItem,
  ListItemButton,
} from "@mui/material";

import {
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";

const NO_CONTAINER_COLOR = '#FFFFFF';
const DEFAULT_CONTAINER_COLOR = '#FFE4C4';
const PLAN_TYPE_LOADING = 'loadingTerminalCall';
const PLAN_TYPE_UNLOADING = 'unloadingTerminalCall';
const DOCKING_SIDE_STARBOARD = 'starboard';
const DOCKING_SIDE_PORT = 'port';

const TerminalView = () => {
  const dispatch = useDispatch();

  const selectedData = useSelector((state) => ({
    terminalCalls: state.terminalCalls,
    vessels: state.vessels,
    containers: state.containers,
    terminals: state.terminals,
    containerTypes: state.containerTypes,
  }));

  const [selectedTerminal, setSelectedTerminal] = useState(undefined);  
  const [selectedTerminalCall, setSelectedTerminalCall] = useState(undefined);  
  const [selectedVessel, setSelectedVessel] = useState(undefined);  
  const [selectedVesselForPlanner, setSelectedVesselForPlanner] = useState(undefined);
  const [dockingSide, setDockingSide] = useState(DOCKING_SIDE_PORT);
  const [planType, setPlanType] = useState(PLAN_TYPE_LOADING);  
  const [terminalCallData, setTerminalCallData] = useState([]);  
  const [containerData, setContainerData] = useState([]);

  useEffect(() => {
    dispatch(getVessels(true));
    dispatch(getTerminals());
    dispatch(getTerminalCalls());
    dispatch(getContainerTypes())
  }, [dispatch]);

  const { vessels, terminals, terminalCalls, containerTypes, containers } = selectedData;

  const findTerminalCallsByTerminal = (terminal) => {
    return terminalCalls.data.filter((terminalCall: TerminalCall) => terminalCall.terminal && terminalCall.terminal['@id'] === terminal['@id'])
  }
  
  useEffect(() => {
   if(selectedTerminal) {
      setTerminalCallData(findTerminalCallsByTerminal(selectedTerminal))
    } else {
      setTerminalCallData([])
    }

    if (selectedTerminalCall) {
      const containerIds = [...selectedTerminalCall.unloadingContainers, ...selectedTerminalCall.loadingContainers];

      if (containerIds.length === 0) {
        setContainerData([])
      } else {
        dispatch(getContainers(containerIds));
      }

      setDockingSide(selectedTerminalCall?.dockingSide ?? DOCKING_SIDE_PORT) 
      setSelectedVessel(selectedTerminalCall.vessel)
      setSelectedVesselForPlanner(selectedTerminalCall.vessel)
    }
  }, [selectedTerminal, selectedTerminalCall, terminalCalls]);

  useEffect(() => {
    if (containers.error) {
      setContainerData([]);
    }  else   {
      setContainerData(containers.data)
    }

    if (selectedTerminalCall) {

    }
  }, [containers, selectedTerminalCall]);


  const filterContainerByIds = (containerIds) => {
    return containerData.filter(
      (container) =>
        containerIds.includes(
          container["@id"]
        )
        && container.position
    )
  }

  const findContainerByPosition = (position:any) => {
    let containerIds = selectedTerminalCall.loadingContainers;

    if (selectedVessel) {
      position.push(selectedVesselForPlanner['@id'].replace('/api/vessels/', ''))
    }

    if (planType === PLAN_TYPE_UNLOADING) {
      containerIds = selectedTerminalCall.unloadingContainers;
    }

    return containerData.find(
      (container: Container) => container && container.position && JSON.stringify(container.position) === JSON.stringify(position) && containerIds.includes(
        container["@id"]
      )
    );
  }

  const containerColorHandler = (container: Container|undefined) => {
    if (container === undefined) {
      return NO_CONTAINER_COLOR;
    }

    if (planType === PLAN_TYPE_UNLOADING) {
      return  container.loadingTerminalCall && container.loadingTerminalCall.terminal
            ? container.loadingTerminalCall.terminal.color 
            : (
              container.loadingTerminal 
              && container.loadingTerminal.color 
              ? container.loadingTerminal.color
              : DEFAULT_CONTAINER_COLOR
            )
    }

    if (planType === PLAN_TYPE_LOADING) {
      return container.unloadingTerminalCall && container.unloadingTerminalCall.terminal
            ? container.unloadingTerminalCall.terminal.color 
            : (
              container.unloadingTerminal 
              && container.unloadingTerminal.color 
              ? container.unloadingTerminal.color
              : DEFAULT_CONTAINER_COLOR
            )
    }

    return DEFAULT_CONTAINER_COLOR;
  }

  const dockingSideChange = (
    event: React.MouseEvent<HTMLElement>,
    dockingSide: string
  ) => {
    setDockingSide(dockingSide)
  }

  const selectedVesselForPlannerHandler = (
    event: React.MouseEvent<HTMLElement>,
    vessel: Vessel
  ) => {
    setSelectedVesselForPlanner(vessel) 
  }

  const RenderGrid = (layer: any, xAxis: any, yAxis: any) => {
    const containerWithPosition: Container|undefined = findContainerByPosition([layer, xAxis, yAxis])

    return (
      <Grid 
        item
        sx={{ 
          backgroundColor: `${containerColorHandler(containerWithPosition)} !important`,
          color: textColorHandler(containerColorHandler(containerWithPosition)),
        }}
        className= { `container-planner-grid ${ containerWithPosition && containerWithPosition.containerType && containerWithPosition.containerType.teu > 1 ? `container-planner-grid-teu-${ containerWithPosition.containerType.teu }` : ''}` }
      >
        { containerWithPosition ? (
          <Typography fontSize='inherit'>
            {containerUidFormatter(containerWithPosition.uid).letters}<br/>
            {containerUidFormatter(containerWithPosition.uid).numbers}
          </Typography>
        ) : ''}
      </Grid>
    )
  }

  return (
    <Layout>
      <Grid container>
        <Grid className='container-planner-terminal-main-wrapper' item xs={12} sm={1} padding={1}>
          <Grid item component={ Paper }  sx={{ height: '85vh !important' }}>
            <MuiList>
              { terminals && terminals.data.length > 0 &&
                terminals.data.map((terminal:Terminal) => (
                <ListItem 
                  disablePadding 
                  className={`js-existing-file-element ${ terminal === selectedTerminal ? 'active-menu' : '' }`}
                  key={`termial-view-terminal-${terminal['@id']}`}
                >
                  <ListItemButton 
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    onClick={() => {setSelectedTerminal(terminal), setSelectedTerminalCall(undefined)} }
                  >
                    { RenderTerminalName(terminal) }
                  </ListItemButton>
                </ListItem>
              ))
              }
            </MuiList>
          </Grid>
        </Grid>
        <Grid className='container-planner-terminal-main-wrapper' item xs={12} sm={2} padding={1}>
          <Grid 
              item 
              className='container-planner-terminal-information' 
              component={ Paper }
              sx={{ height: '85vh !important' }}
            >
              { terminalCallData
              ? Object.entries(terminalCallFormatter(terminalCallData)).map(([key, value, i]) => (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={` panel-${i}-content`}
                    id={` panel-${i}-header`}
                    key={`terminal-panel-${i}-header`}
                    sx={{ padding: '0 5px' }}
                  >
                    <Grid
                      sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid>{key}</Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: '8px 5px 10px' }}>
                    { value.map((terminalCall: TerminalCall) => (
                      <Grid
                        className={ `planning-terminal-item js-terminal-call-parent ${selectedTerminalCall && selectedTerminalCall['@id'] === terminalCall['@id'] ? 'planning-selected-terminal-call' : ''}` }
                        sx={{ 
                          padding: '4px',
                          backgroundColor: `${ terminalCall.terminal && terminalCall.terminal.color ? terminalCall.terminal.color : NO_CONTAINER_COLOR } !important`,
                          color: textColorHandler(terminalCall.terminal && terminalCall.terminal.color ? terminalCall.terminal.color : NO_CONTAINER_COLOR),
                        }}
                        component={Paper}
                        data-terminal-call-id={terminalCall["@id"]}
                      >
                        <Grid
                          item
                          sx={{ display: 'flex', flexDirection: 'column' }}
                          onClick={(e:any) => setSelectedTerminalCall(terminalCall)}
                        >
                          <Grid item sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography>
                              { terminalCall.vessel && terminalCall.vessel.name
                                  ? `${terminalCall.vessel.name}`
                                  : "No name"
                              }
                            </Typography>
                            <Typography>
                              {formatDateToString(terminalCall.planned, "HH:mm")}
                            </Typography>
                          </Grid>
                          <Grid item>
                            
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ))
            : ""}
          </Grid>
        </Grid>
        <Grid className='container-planner-terminal-main-wrapper' item xs={12} sm={2} padding={1}>
          <Grid 
              item 
              className='container-planner-terminal-information' 
              component={ Paper }
              sx={{ height: '85vh !important' }}
            >
              { selectedTerminalCall ? (
                <>
                <TerminalCallDetail terminalCall={selectedTerminalCall} />
                <Grid item className="planning-terminal-action" sx={{ width: '100% !important', margin: '5px 0 0 !important' }}>
                  <ContainerCounts
                    containers={filterContainerByIds(selectedTerminalCall.loadingContainers)}
                    containerTypes={containerTypes.data}
                    loading={true}
                  />
                </Grid>
                <Grid item className="planning-terminal-action" sx={{ width: '100% !important', margin: '5px 0 0 !important' }}>
                  <ContainerCounts
                    containers={filterContainerByIds(selectedTerminalCall.unloadingContainers)}
                    containerTypes={containerTypes.data}
                    loading={false}
                  />
                </Grid>
                </>
              ) : ''}
          </Grid>
        </Grid>
        <Grid className='container-planner-terminal-main-wrapper' item xs={12} sm={7} padding={1}>
          <Grid item className='container-planner-menu-wrapper' component={ Paper }>
            <Grid item className='container-planner-menu'>
              <Button
                  size='small'
                  variant='outlined'
                  onClick={() => setPlanType(PLAN_TYPE_LOADING)} 
                  className={`${ planType === PLAN_TYPE_LOADING ? 'active-plan' : '' }`}
                >
                  Loading plan
                </Button>
                <Button
                  size='small'
                  variant='outlined'
                  onClick={() => setPlanType(PLAN_TYPE_UNLOADING)} 
                  className={`${ planType === PLAN_TYPE_UNLOADING ? 'active-plan' : '' }`}
                >
                  Unloading plan
                </Button>
            </Grid> 
            <Grid item className='container-planner-vessel-switch'>
                { selectedVessel && selectedTerminal ? (
                  <ToggleButtonGroup
                      value={ dockingSide }
                      exclusive
                      onChange={dockingSideChange}
                  >
                    <ToggleButton value={DOCKING_SIDE_PORT}>
                      { DOCKING_SIDE_PORT }
                    </ToggleButton>
                    <ToggleButton value={DOCKING_SIDE_STARBOARD}>
                      { DOCKING_SIDE_STARBOARD }
                    </ToggleButton>
                  </ToggleButtonGroup>
                ) : '' }
                { selectedVessel && selectedVessel.linkedVessel ? (
                  <>
                    <Divider orientation="vertical"  />
                    <ToggleButtonGroup
                      value={selectedVesselForPlanner}
                      exclusive
                      onChange={selectedVesselForPlannerHandler}
                    >
                      <ToggleButton value={selectedVessel}>
                        { selectedVessel.name }
                      </ToggleButton>
                      <ToggleButton value={selectedVessel.linkedVessel}>
                        { selectedVessel.linkedVessel.name }
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </>
                ) : '' }
                { selectedVessel && selectedTerminal ? (
                  <GeneratePDF orientation={ DOCUMENT_ORIENTATION_LANDSCAPE }/>
                ) : '' }
              </Grid>
          </Grid>
          <Grid item className='container-planner-main-wrapper pdf-document-content' component={ Paper }>
            { selectedVesselForPlanner && selectedTerminalCall && selectedVesselForPlanner.positions && selectedVesselForPlanner.positions.map((grids:any, layer:any) => (
                <Grid className={`container-planner-wrapper ${ dockingSide === DOCKING_SIDE_STARBOARD ? 'container-planner-wrapper--revese' : ''}`} item>
                  <Grid className='container-planner-ship-bow' item>
                    <Typography className='container-planner-layer-label'>{ layer + 1}</Typography>  
                  </Grid>
                  <Grid className='container-planner-ship-body' item>
                    { grids && grids.map((gridCount, x) => (
                      <>
                        <Grid className='container-planner-ship-grid-wrapper' item> 
                        <Typography className='container-planner-x-axis-label'>{x + 1}</Typography>
                        {[...Array(gridCount)].map((g, y) => (
                          RenderGrid(layer + 1, x + 1, y + 1)
                        ))}
                      </Grid>
                      { hasPositionBarrier([x + 1, x + 2], selectedVesselForPlanner) ? (
                        <Grid className='container-planner-barrier' item></Grid>
                      ) : '' }
                      </>
                    ))}
                  </Grid>
                </Grid>
              ))}
              { selectedTerminalCall && selectedVesselForPlanner && !selectedVesselForPlanner.positions ? (
                <Grid item sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Alert severity="warning">Grid positions for this vessel are not set. Please contact the administrator.</Alert>
                </Grid>
              ) : ''}
              { selectedVesselForPlanner && dockingSide ? (
                <Grid item sx={{ padding: '15px' }}>
                  <Typography variant='h6'>
                    { `${selectedVesselForPlanner.name} - ${planType === PLAN_TYPE_LOADING ? 'Loading' : 'Unloading'} - ${ dockingSide === DOCKING_SIDE_STARBOARD  ? 'Starboard' : 'Port side' }`}
                  </Typography>   
                </Grid>
              ) : ''}
          </Grid> 
        </Grid>
      </Grid>
    </Layout>
  );
};

export default TerminalView;
