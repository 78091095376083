import { Tenant } from "./Tenant";
import { Item } from "./item";

export class Label implements Item {
  public "@id"?: string;

  constructor(
    _id?: string,
    public name?: string,
    public color?: string,
    public tenant?: Tenant[]| string[],
  ) {
    this["@id"] = _id;
  }
}
