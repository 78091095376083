import { ContainerType } from "../../types/ContainerType";

const GetContainerTypeOptions = (containerTypes: ContainerType[]) => {
  const containerTypeOptions = [];
  
  if (!containerTypes || containerTypes.length === 0) {
      return containerTypeOptions;
  }

  containerTypes.map((containerType: ContainerType) => { 
      containerTypeOptions.push({
      value: containerType['@id'],
      label: `${ containerType.name }${ containerType.abbreviation ? ` (${ containerType.abbreviation })` : '' }`
    })
  })

  return containerTypeOptions;
}

export default GetContainerTypeOptions;