import { ContainerType } from "./ContainerType";
import { Tenant } from "./Tenant";
import { Terminal } from "./Terminal";
import { TerminalCall } from "./TerminalCall";
import { Vessel } from "./Vessel";
import { Item } from "./item";

export class Container implements Item {
  public "@id"?: string;

  constructor(
    _id?: string,
    public uid?: string,
    public weight?: number,
    public containerType?: ContainerType[]| string[],
    public loadingTerminal?: Terminal[]| string[],
    public loadingTerminalCall?: TerminalCall[]| string[],
    public unloadingTerminal?: Terminal[]| string[],
    public unloadingTerminalCall?: TerminalCall[]| string[],
    public vessel?: Vessel[]| string[],
    public tenant?: Tenant[]| string[],
    public loadingTerminalClosure?: Date,
    public unloadingTerminalClosure?: Date,
  ) {
    this["@id"] = _id;
  }
}
