import { Address } from "./Address";
import { Tenant } from "./Tenant";
import { Item } from "./item";

export class Contact implements Item {
  public "@id"?: string;

  constructor(
    _id?: string,
    public name?: string,
    public phoneNumber?: string,
    public email?: string,
    public tenant?: Tenant[]| string[],
    public address?: Address[]| string[],
  ) {
    this["@id"] = _id;
  }
}
