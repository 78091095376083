import React from 'react';
import { 
  Grid,
  Typography,
  SvgIcon,
} from '@mui/material';

export default function Logo({ fillColor='#ffffff', fontSize='1.2rem', size = '48px', fontColor='#ffffff' }) {
    return (
      <Grid sx={{ display: 'flex', alignItems: 'center' }}>
        <SvgIcon sx={{ height: size, width: size, fill: (fillColor) }}>
            <svg 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="-80 -170 500 625" 
            preserveAspectRatio="none"
            >
                <g>
                <path d="M358.807418,187.951896 L358.807418,55.723896 C358.807418,53.322896 357.385,51.149896 355.181,50.198896 L239.897,0.489896019 C238.39,-0.159103981 236.684,-0.163103981 235.175,0.476896019 L3.654,98.836896 C1.441,99.776896 0,101.947896 0,104.353896 C0,104.355896 0,104.357896 0,104.359896 L0,236.640896 C0,239.043896 1.434,241.214896 3.644,242.158896 L121.932,292.924896 L335.994,201.620896 L355.151,193.472896 C357.367,192.530896 358.807418,190.357896 358.807418,187.951896 Z M237.504,12.526896 L337.563,55.671896 L121.938,147.366896 L21.308,104.374896 L237.504,12.526896 Z M115.932,157.850896 L115.932,277.055896 L63.7,254.752896 L63.7,135.534896 L115.932,157.850896 Z M12,113.446896 L51.7,130.407896 L51.7,249.628896 L12,232.677896 L12,113.446896 Z M346.805,183.983896 L127.932,277.069896 L127.932,157.857896 L346.805,64.780896 L346.805,183.983896 Z" id="Shape"></path>
                <path d="M89.479,222.199896 C92.792,222.199896 95.479,219.512896 95.479,216.199896 L95.479,192.129896 C95.479,188.816896 92.792,186.129896 89.479,186.129896 C86.166,186.129896 83.479,188.816896 83.479,192.129896 L83.479,216.199896 C83.479,219.512896 86.166,222.199896 89.479,222.199896 Z" id="Path"></path>
                <path d="M30.965,163.354896 C27.652,163.354896 24.965,166.041896 24.965,169.354896 L24.965,193.424896 C24.965,196.737896 27.652,199.424896 30.965,199.424896 C34.278,199.424896 36.965,196.737896 36.965,193.424896 L36.965,169.354896 C36.965,166.041896 34.278,163.354896 30.965,163.354896 Z" id="Path"></path>
                <path d="M151.872,259.529896 C155.185,259.529896 157.872,256.842896 157.872,253.529896 L157.872,163.790896 C157.872,160.477896 155.185,157.790896 151.872,157.790896 C148.559,157.790896 145.872,160.477896 145.872,163.790896 L145.872,253.529896 C145.872,256.843896 148.559,259.529896 151.872,259.529896 Z" id="Path"></path>
                <path d="M181.147,247.249896 C184.46,247.249896 187.147,244.562896 187.147,241.249896 L187.147,151.510896 C187.147,148.197896 184.46,145.510896 181.147,145.510896 C177.834,145.510896 175.147,148.197896 175.147,151.510896 L175.147,241.249896 C175.147,244.563896 177.833,247.249896 181.147,247.249896 Z" id="Path"></path>
                <path d="M210.419,234.969896 C213.732,234.969896 216.419,232.282896 216.419,228.969896 L216.419,139.230896 C216.419,135.917896 213.732,133.230896 210.419,133.230896 C207.106,133.230896 204.419,135.917896 204.419,139.230896 L204.419,228.969896 C204.419,232.282896 207.106,234.969896 210.419,234.969896 Z" id="Path"></path>
                <path d="M239.692,222.688896 C243.005,222.688896 245.692,220.001896 245.692,216.688896 L245.692,126.948896 C245.692,123.635896 243.005,120.948896 239.692,120.948896 C236.379,120.948896 233.692,123.635896 233.692,126.948896 L233.692,216.688896 C233.692,220.002896 236.379,222.688896 239.692,222.688896 Z" id="Path"></path>
                <path d="M268.967,210.408896 C272.28,210.408896 274.967,207.721896 274.967,204.408896 L274.967,114.669896 C274.967,111.356896 272.28,108.669896 268.967,108.669896 C265.654,108.669896 262.967,111.356896 262.967,114.669896 L262.967,204.408896 C262.967,207.721896 265.653,210.408896 268.967,210.408896 Z" id="Path"></path>
                <path d="M298.24,198.127896 C301.553,198.127896 304.24,195.440896 304.24,192.127896 L304.24,102.388896 C304.24,99.075896 301.553,96.388896 298.24,96.388896 C294.927,96.388896 292.24,99.075896 292.24,102.388896 L292.24,192.127896 C292.24,195.441896 294.927,198.127896 298.24,198.127896 Z" id="Path"></path>
                <path d="M327.514,185.847896 C330.827,185.847896 333.514,183.160896 333.514,179.847896 L333.514,90.108896 C333.514,86.795896 330.827,84.108896 327.514,84.108896 C324.201,84.108896 321.514,86.795896 321.514,90.108896 L321.514,179.847896 C321.514,183.160896 324.2,185.847896 327.514,185.847896 Z" id="Path"></path>
                </g>
            </svg>
        </SvgIcon>
        <Typography sx={{ fontFamily: '"Orbitron", sans-serif', fontSize: fontSize, color: fontColor, fontWeight: '700' }}>
          Stackr
        </Typography>
      </Grid>
    );
  }