import * as React from 'react';
import { forwardRef } from 'react';
import { NumericFormat } from "react-number-format";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";

interface NumericFormatProps {
  onChange: (event: { target: { name: string; value?: number } }) => void;
  name: string;
}

export const CurrencyNumericFormat = forwardRef<unknown, NumericFormatProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.floatValue,
            },
          });
        }}
        valueIsNumericString
        decimalSeparator="," 
        thousandSeparator=" " 
        decimalScale={ props.decimal }
      />
    );
  }
);

export const NumberField = ({ name, label, control, required = false, decimal = 3 }: any) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        <TextField
          size="small"
          variant="outlined"
          helperText={ error ? error.message : null }
          error={!!error}
          onChange={onChange}
          value={ value ?? ''}
          fullWidth
          label={label}
          required={ required }
          InputProps={{
            inputComponent: CurrencyNumericFormat as any
          }}
          inputProps={{
            decimal: decimal
        }}
        />
      )}
    />
  );
};