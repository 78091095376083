import axios from "axios";

/*****************/
/* INITIAL STATE */
/*****************/
const initialState = {
  loading: false,
  error: "",
  data: [],
};

/*********/
/* TYPES */
/*********/
const FETCH_LABELS = "FETCH_LABELS";
const FETCH_LABELS_SUCCESS = "FETCH_LABELS_SUCCESS";
const FETCH_LABELS_ERROR = "FETCH_LABELS_ERROR";
const FETCH_NEXT_LABELS = "FETCH_NEXT_LABELS";
const FETCH_NEXT_LABELS_SUCCESS = "FETCH_NEXT_LABELS_SUCCESS";

/*******************/
/* ACTION CREATORS */
/*******************/
export const getLabels = () => async (dispatch) => {
  dispatch(loadLabels());

  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/labels?page=1`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.data["hydra:member"].length === 0) {
    dispatch(setError("No labels found"));
  } else {
    dispatch(setLabels(response.data["hydra:member"]));
    if (
      response.data &&
      response.data["hydra:view"] &&
      response.data["hydra:view"] !== undefined &&
      response.data["hydra:view"]["hydra:next"] &&
      response.data["hydra:view"]["hydra:next"] !== undefined
    ) {
      dispatch(getNextLabels(response.data["hydra:view"]["hydra:next"]));
    }
  }
};

export const getNextLabels = (nextUrl) => async (dispatch, getState) => {
  dispatch(loadNextLabels());

  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL}${nextUrl}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  dispatch(setNextLabels(response.data["hydra:member"]));

  if (
    response.data &&
    response.data["hydra:view"] &&
    response.data["hydra:view"] !== undefined &&
    response.data["hydra:view"]["hydra:next"] &&
    response.data["hydra:view"]["hydra:next"] !== undefined
  ) {
    dispatch(getNextLabels(response.data["hydra:view"]["hydra:next"]));
  }
};

export const loadLabels = () => ({ type: FETCH_LABELS });

export const setLabels = (labels) => ({
  type: FETCH_LABELS_SUCCESS,
  payload: { labels },
});

export const loadNextLabels = (page) => ({
  type: FETCH_NEXT_LABELS,
  payload: page,
});
export const setNextLabels = (labels) => ({
  type: FETCH_NEXT_LABELS_SUCCESS,
  payload: labels,
});

export const setError = (msg) => ({
  type: FETCH_LABELS_ERROR,
  payload: msg,
});

/***********/
/* REDUCER */
/***********/
const labelsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_LABELS:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_LABELS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        data: payload.labels,
      };

    case FETCH_LABELS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case FETCH_NEXT_LABELS:
      return {
        ...state,
        error: "",
      };
    case FETCH_NEXT_LABELS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, ...payload],
      };
    default:
      return state;
  }
};

export default labelsReducer;
