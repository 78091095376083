import { createSelector } from "@reduxjs/toolkit";

const selectContainers = (state) => state.containers;

const selectContainerFilter = (state) => state.filter.container;

export const selectFilteredContainers = createSelector(
  [selectContainers, selectContainerFilter],
  (containers, filter) => {
    let filteredData = containers.data;

    if (filter.labels.length) {
      filteredData = filteredData.filter((container) =>
        container.labels.some((label) => filter.labels.includes(label))
      );
    }
    if (filter.loadingTerminals.length) {
      filteredData = filteredData.filter((container) =>
        filter.loadingTerminals.includes(container.loadingTerminal["@id"])
      );
    }

    return filteredData;
  }
);
