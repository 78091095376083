import React from "react";
import { useDispatch } from "react-redux";
import { hideModal } from "../../data/modal";
import { RenderTerminalName } from "../Helpers.tsx";
import { updateContainer } from "../../data/containers";

import {
  Alert,
  Button,
  Grid,
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableRow,
} from "@mui/material";

import {
  Close as CloseIcon,
  LinkOff as LinkOffIcon,
} from "@mui/icons-material";

const ContainerTerminalCallModal = (properties: any, show:boolean) => {
  const dispatch = useDispatch();
  const container = properties.container;
  const terminalCallType = properties.terminalCallType;

  const LOADING_TERMINAL_CALL_TYPE = 'loading'
  const UNLOADING_TERMINAL_CALL_TYPE = 'unloading'

  const unlinkTerminalCallHandler = () => {
    let containerData = [];

    if (terminalCallType === LOADING_TERMINAL_CALL_TYPE) {
      containerData = {
        ...container,
        loadingTerminalCall: null,
        position: null,
      }
    }

    if (terminalCallType === UNLOADING_TERMINAL_CALL_TYPE) {
      containerData = {
        ...container,
        unloadingTerminalCall: null,
        position: null,
      }
    }

    if (containerData) {
      dispatch(updateContainer(containerData));
    }
  }
  if (!properties.container) {
    return
  }
  return (
      <Grid container sx={{ width: '50vh' }}>
         <TableContainer>
          <Table aria-label="container table" className='container-modal-table'>
            <TableBody>
              <TableRow>
                  <TableCell>Container:</TableCell>
                  <TableCell>{ container.uid ?? '' }</TableCell>
              </TableRow>
              <TableRow>
                  <TableCell>Weight:</TableCell>
                  <TableCell>{ container.weight && container.weight > 0 ? (container.weight/1000).toFixed(3) : 0}T</TableCell>
              </TableRow>

              { terminalCallType ===  LOADING_TERMINAL_CALL_TYPE ? (
                <TableRow>
                    <TableCell>Loading to:</TableCell>
                    <TableCell>
                      { RenderTerminalName(container.loadingTerminalCall.terminal) }
                    </TableCell>
                </TableRow>
              ) : ''}
              { terminalCallType ===  UNLOADING_TERMINAL_CALL_TYPE ? (
                <TableRow>
                    <TableCell>Unloading to:</TableCell>
                    <TableCell>
                      { RenderTerminalName(container.unloadingTerminalCall.terminal) }
                    </TableCell>
                </TableRow>
              ) : ''}
              { container.position &&  container.position.length >= 3 ? (
                <TableRow>
                    <TableCell>Position:</TableCell>
                    <TableCell>{ `Layer: ${ container.position[0] } Row: ${ container.position[1] } Position: ${ container.position[2] } `}</TableCell>
                </TableRow>
              ) : ''}
            </TableBody>
          </Table>
        </TableContainer>
        { container.position &&  container.position.length >= 3 ? (
          <Grid item xs={12}>
            <Alert severity="warning">Unlinking the container to a planning will also remove the container position in the planner.</Alert>
          </Grid>
        ) : ''}
        <Grid item xs={12} className='button-wrapper' sx={{ justifyContent: 'space-between' }}>
          <Button 
              onClick={() => {dispatch(hideModal())}}
              variant='contained'
          >
              <CloseIcon fontSize="inherit" />&nbsp;Close
          </Button>
          <Button 
              onClick={() => unlinkTerminalCallHandler()}
              variant='contained'
          >
              <LinkOffIcon fontSize="inherit" />&nbsp;Unlink to planning
          </Button>
        </Grid>
      </Grid>
  );
}

export default ContainerTerminalCallModal;
