import { TerminalCall } from "../../types/TerminalCall";
import dayjs from 'dayjs';

const GetTerminalCallOptions = (terminalCalls: TerminalCall[]) => {
  const terminalCallOptions = [];
  
  if (!terminalCalls || terminalCalls.length === 0) {
      return terminalCallOptions;
  }

  terminalCalls.map((terminalCall: TerminalCall) => { 
      terminalCallOptions.push({
      value: terminalCall['@id'],
      label: `${ terminalCall.planned && terminalCall.terminal && terminalCall.terminal.name ? `${ dayjs(terminalCall.planned).format('DD/MM/YYYY HH:mm') } - ${ terminalCall.terminal.name }` : '' }`
    })
  })

  return terminalCallOptions;
}

export default GetTerminalCallOptions;