import React from "react";
import { Grid } from "@mui/material";

export default function ContainerCounts({
  containers,
  containerTypes,
  loading,
}) {
  const countContainersbyType = (containerType) => {
    return containers.filter(
      (container) => container.containerType["@id"] === containerType["@id"]
    );
  };

  return (
    <Grid className='terminal-container-load-wrapper' item>
      <Grid className='terminal-container-load-title' item>
          {loading ? "Load" : "Unload"}{" "}
          {containers.reduce(
            (accumulator, container) =>
              accumulator +
              (container.containerType.teu ? container.containerType.teu : 0),
            0
          )}{" "}
          TEU{" "}
      </Grid>
      {containerTypes && (
        <Grid className='terminal-container-load-content-wrapper' item>
          {containerTypes.map(
            (containerType) =>
              countContainersbyType(containerType).length > 0 && (
                <Grid width='100%'>
                  <Grid className='terminal-container-load-content-title' item xs>
                    {containerType.abbreviation ?? containerType.name}
                  </Grid>
                  <Grid className='terminal-container-load-content' item xs>
                    {countContainersbyType(containerType).length}
                  </Grid>
                </Grid>
              )
          )}
        </Grid>
      )}
    </Grid>
  );
}
