import axios from "axios";
import { hideModal } from "./modal";

/*****************/
/* INITIAL STATE */
/*****************/
const initialState = {
  loading: false,
  error: "",
  data: [],
};

/*********/
/* TYPES */
/*********/
const FETCH_FILES = "FETCH_FILES";
const FETCH_FILES_SUCCESS = "FETCH_FILES_SUCCESS";
const FETCH_FILES_ERROR = "FETCH_FILES_ERROR";
const PUT_FILE = "PUT_FILE";
const POST_FILE = "POST_FILE";
const DELETE_FILE = "DELETE_FILE";
const FETCH_NEXT_FILES = "FETCH_NEXT_FILES";
const FETCH_NEXT_FILES_SUCCESS = "FETCH_NEXT_FILES_SUCCESS";

/*******************/
/* ACTION CREATORS */
/*******************/
export const getFiles = () => async (dispatch) => {
  dispatch(loadFiles());

  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/files?page=1`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.data["hydra:member"].length === 0) {
    dispatch(setError("No files found"));
  } else {
    dispatch(setFiles(response.data["hydra:member"]));
    if (
      response.data &&
      response.data["hydra:view"] &&
      response.data["hydra:view"] !== undefined &&
      response.data["hydra:view"]["hydra:next"] &&
      response.data["hydra:view"]["hydra:next"] !== undefined
    ) {
      dispatch(getNextFiles(response.data["hydra:view"]["hydra:next"]));
    }
  }
};

export const getNextFiles = (nextUrl) => async (dispatch) => {
  dispatch(loadNextFiles());

  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL}${nextUrl}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  dispatch(setNextFiles(response.data["hydra:member"]));

  if (
    response.data &&
    response.data["hydra:view"] &&
    response.data["hydra:view"] !== undefined &&
    response.data["hydra:view"]["hydra:next"] &&
    response.data["hydra:view"]["hydra:next"] !== undefined
  ) {
    dispatch(getNextFiles(response.data["hydra:view"]["hydra:next"]));
  }
};

export const updateFile = (file) => async (dispatch, getState) => {
  dispatch(putFile());
  const {
    files: { data },
  } = getState();

  const response = await axios({
    method: "put",
    url: `${process.env.REACT_APP_URL}${file["@id"]}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: file,
  });

  if (response.status === 200) {
    const resultData = data.map((file) => {
      if (file["@id"] === response.data["@id"]) {
        return response.data;
      }

      return file;
    });

    dispatch(setFiles(resultData));
    dispatch(hideModal());
  } else {
    setError("Something went wrong updating");
  }
};

export const removeFile = (file) => async (dispatch, getState) => {
  dispatch(putFile());
  const {
    files: { data },
  } = getState();

  const response = await axios({
    method: "delete",
    url: `${process.env.REACT_APP_URL}${file["@id"]}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.status === 204) {
    const resultData = data.filter((x) => x["@id"] !== file["@id"]);

    dispatch(setFiles(resultData));
    dispatch(hideModal());
  } else {
    setError("Something went wrong deleting");
  }
};

export const createFile = (file) => async (dispatch, getState) => {
  dispatch(postFile());
  const {
    files: { data },
  } = getState();

  const response = await axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/files`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: file,
  });

  if (response.status === 201) {
    const resultData = [...data, ...[response.data]];

    dispatch(setFiles(resultData));
    dispatch(hideModal());
  } else {
    setError("Something went wrong deleting");
  }
};

export const loadFiles = () => ({ type: FETCH_FILES });

export const putFile = () => ({ type: PUT_FILE });

export const postFile = () => ({ type: POST_FILE });

export const deleteFile = () => ({ type: DELETE_FILE });

export const setFiles = (files) => ({
  type: FETCH_FILES_SUCCESS,
  payload: { files },
});

export const loadNextFiles = (page) => ({
  type: FETCH_NEXT_FILES,
  payload: page,
});
export const setNextFiles = (files) => ({
  type: FETCH_NEXT_FILES_SUCCESS,
  payload: files,
});

export const setError = (msg) => ({
  type: FETCH_FILES_ERROR,
  payload: msg,
});

/***********/
/* REDUCER */
/***********/
const fileReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_FILES:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_FILES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        data: payload.files,
      };
    case PUT_FILE:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case POST_FILE:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_FILES_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case FETCH_NEXT_FILES:
      return {
        ...state,
        error: "",
      };
    case FETCH_NEXT_FILES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, ...payload],
      };
    default:
      return state;
  }
};

export default fileReducer;
