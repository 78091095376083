import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useSearchParams } from 'react-router-dom';
import { keyframes } from "@mui/system";


import {
    useNavigate,
    useLocation,
    createSearchParams,
} from "react-router-dom";

import {
    Grid,
    Paper,
    InputBase,
    IconButton,
} from "@mui/material";

import {
    SearchSharp as SearchSharpIcon, 
    CloseSharp as CloseSharpIcon,
} from '@mui/icons-material';

const pulse = keyframes`
   0% {
        -moz-transform: scale(0);
        opacity: 0.0;
    }
    25% {
        -moz-transform: scale(0);
        opacity: 0.1;
    }
    50% {
        -moz-transform: scale(0.1);
        opacity: 0.3;
    }
    75% {
        -moz-transform: scale(0.5);
        opacity: 0.5;
    }
    100% {
        -moz-transform: scale(1);
        opacity: 0.0;
    }
`;

export const getContainerSearchParams = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [searchParams] = useSearchParams();
  const foundTerminalCallIds = [];
  const foundContainerIds = [];
  
  for (const entry of searchParams.entries()) {
    const [param, value] = entry;

    if(param === 'foundTerminalCallIds') {
     foundTerminalCallIds.push(value)
    }
    
    if(param === 'foundContainerIds') {
      foundContainerIds.push(value)
    }
  }

  return {
    searchedTerminalCallIds: foundTerminalCallIds,
    searchedContainerIds: foundContainerIds
  }
}

const renderPulse = () => {
    return (
        <Grid item className="highlighted-object">
            <Grid item className="container">
                <Grid item className="pulse"  sx={{
                    animation: `${pulse} 2s infinite`
                }}></Grid>
                <Grid item className="center"></Grid>
            </Grid>
        </Grid>
    )
}

export const highlightContainer = (searchedContainerIds: any, containerId: string) => {
    return searchedContainerIds.find(
        (searchedContainerId: string) =>  searchedContainerId === containerId
    ) ? (
        renderPulse()
    ) : ''
}

export const highlightTerminal = (searchedTerminalCallIds: any, terminalCallId: string) => {
    return searchedTerminalCallIds.find(
        (searchedTerminalCallId: string) =>  searchedTerminalCallId === terminalCallId
    ) ? (
        renderPulse()
    ) : ''
}

export default function ContainerSearch() {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchString, setSearchString] = useState('');

    useEffect(() => {
        if (searchString.length === 0) {
            removeSearch()
        }
    }, [searchString]);

    const SearchHandler = async (searchString) => {
        const foundTerminalCallIds = [];
        const foundContainerIds = [];

        const response = await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}/containers?page=1&uid=${ searchString }`,
            headers: {
              "Content-Type": "application/ld+json; charset=utf-8",
              "accept": "application/ld+json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        
        if ((response.status === 200 || response.status === 201) && response.data && response.data["hydra:member"].length > 0) {
            response.data["hydra:member"].map((container) => {
                foundContainerIds.push(container['@id']);

                if (container.loadingTerminalCall) {
                    foundTerminalCallIds.push(container.loadingTerminalCall['@id']);
                }
            })

            return {
                foundTerminalCallIds: foundTerminalCallIds,
                foundContainerIds: foundContainerIds
            }
          } else {
            console.log("Something went wrong updating");
          }
    }

    const removeSearch = () => {
        const element = document.getElementById('js-container-search-field') as HTMLInputElement|null;
        element.value = ''
        setSearchString('')

        navigate({
            pathname: location.pathname,
            search: ''
        });
    }

    const SearchFunction = () => {
        const element = document.getElementById('js-container-search-field') as HTMLInputElement|null;
        const text = element.value;
        setSearchString(text)

        if (text.length < 5) {
            return;
        }

        SearchHandler(text).then((res) => {
            if (res && res.foundContainerIds.length > 0) {
                navigate({
                    pathname: location.pathname,
                    search: createSearchParams(res).toString()
                });
            } else {
                removeSearch()
            }
        })
    }

    return (
      <Paper
        component="form"
        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%', height: 35 }}
      >
        <InputBase
          id='js-container-search-field'
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search a container"
          inputProps={{ 'aria-label': 'search for a container' }}
        />
        { searchString.length > 0 ? (
            <IconButton type="button" 
                sx={{ p: '5px' }} 
                aria-label="clear search"
                onClick={() => removeSearch()}
            >
            <CloseSharpIcon />
            </IconButton>
        ) : '' }
        <IconButton type="button" 
            sx={{ p: '5px' }} 
            aria-label="search"
            onClick={() => SearchFunction()}
        >
          <SearchSharpIcon />
        </IconButton>
      </Paper>
    )
}
