import axios from "axios";

/*****************/
/* INITIAL STATE */
/*****************/
const initialState = {
  loading: false,
  error: "",
  data: [],
};

/*********/
/* TYPES */
/*********/
const FETCH_USER = "FETCH_USER";
const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
const FETCH_USER_ERROR = "FETCH_USER_ERROR";

/*******************/
/* ACTION CREATORS */
/*******************/
export const getCurrentUser = () => async (dispatch) => {
  dispatch(loadUser());
  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/users?page=1`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (!response.data) {
    dispatch(setError("error"));
  } else {
    dispatch(setUser(response.data["hydra:member"][0]));
  }
};

export const loadUser = () => ({ type: FETCH_USER });

export const setUser = (object) => ({
  type: FETCH_USER_SUCCESS,
  payload: object,
});

export const setError = (msg) => ({ type: FETCH_USER_ERROR, payload: msg });

/***********/
/* REDUCER */
/***********/
const usersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_USER:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: payload,
      };

    case FETCH_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
export default usersReducer;
