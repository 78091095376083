import axios from "axios";
import { hideModal } from "../data/modal";

/*****************/
/* INITIAL STATE */
/*****************/
const initialState = {
  loading: false,
  error: "",
  data: [],
};

/*********/
/* TYPES */
/*********/
const FETCH_TERMINALS = "FETCH_TERMINALS";
const FETCH_TERMINALS_SUCCESS = "FETCH_TERMINALS_SUCCESS";
const FETCH_TERMINALS_ERROR = "FETCH_TERMINALS_ERROR";
const PUT_TERMINAL = "PUT_TERMINAL";
const POST_TERMINAL = "POST_TERMINAL";
const FETCH_NEXT_TERMINALS = "FETCH_NEXT_TERMINALS";
const FETCH_NEXT_TERMINALS_SUCCESS = "FETCH_NEXT_TERMINALS_SUCCESS";

/*******************/
/* ACTION CREATORS */
/*******************/
export const getTerminals = () => async (dispatch) => {
  dispatch(loadTerminals());

  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/terminals?page=1`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.data["hydra:member"].length === 0) {
    dispatch(setError("No terminals found"));
  } else {
    dispatch(setTerminals(response.data["hydra:member"]));
    if (
      response.data &&
      response.data["hydra:view"] &&
      response.data["hydra:view"] !== undefined &&
      response.data["hydra:view"]["hydra:next"] &&
      response.data["hydra:view"]["hydra:next"] !== undefined
    ) {
      dispatch(getNextTerminals(response.data["hydra:view"]["hydra:next"]));
    }
  }
};

export const getNextTerminals = (nextUrl) => async (dispatch) => {
  dispatch(loadNextTerminals());

  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL}${nextUrl}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  dispatch(setNextTerminals(response.data["hydra:member"]));

  if (
    response.data &&
    response.data["hydra:view"] &&
    response.data["hydra:view"] !== undefined &&
    response.data["hydra:view"]["hydra:next"] &&
    response.data["hydra:view"]["hydra:next"] !== undefined
  ) {
    dispatch(getNextTerminals(response.data["hydra:view"]["hydra:next"]));
  }
};

export const updateTerminal = (terminal) => async (dispatch, getState) => {
  dispatch(putTerminal());
  const {
    terminals: { data },
  } = getState();

  const response = await axios({
    method: "put",
    url: `${process.env.REACT_APP_URL}${terminal["@id"]}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: terminal,
  });

  if (response.status === 200) {
    const resultData = data.map((terminal) => {
      if (terminal["@id"] === response.data["@id"]) {
        return response.data;
      }

      return terminal;
    });

    dispatch(setTerminals(resultData));
    dispatch(hideModal());
  } else {
    setError("Something went wrong updating");
  }
};

export const createTerminal = (terminal) => async (dispatch, getState) => {
  dispatch(postTerminal());
  const {
    terminals: { data },
  } = getState();

  const response = await axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/terminals`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: terminal,
  });

  if (response.status === 201) {
    const resultData = [...data, ...[response.data]];

    dispatch(setTerminals(resultData));
    dispatch(hideModal());
  } else {
    setError("Something went wrong deleting");
  }
};

export const loadTerminals = () => ({ type: FETCH_TERMINALS });

export const putTerminal = () => ({ type: PUT_TERMINAL });

export const postTerminal = () => ({ type: POST_TERMINAL });

export const setTerminals = (terminals) => ({
  type: FETCH_TERMINALS_SUCCESS,
  payload: { terminals },
});

export const loadNextTerminals = (page) => ({
  type: FETCH_NEXT_TERMINALS,
  payload: page,
});
export const setNextTerminals = (terminals) => ({
  type: FETCH_NEXT_TERMINALS_SUCCESS,
  payload: terminals,
});

export const setError = (msg) => ({
  type: FETCH_TERMINALS_ERROR,
  payload: msg,
});

/***********/
/* REDUCER */
/***********/
const terminalReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_TERMINALS:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_TERMINALS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        data: payload.terminals,
      };

    case PUT_TERMINAL:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case POST_TERMINAL:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_TERMINALS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case FETCH_NEXT_TERMINALS:
      return {
        ...state,
        error: "",
      };
    case FETCH_NEXT_TERMINALS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, ...payload],
      };
    default:
      return state;
  }
};
export default terminalReducer;
