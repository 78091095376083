import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../Layout.tsx";
import { getContainerTypes } from "../../../data/containertype";
import { ContainerType } from "../../../types/ContainerType";
import { showModal } from "../../../data/modal";
import Modal from "../../Modal.tsx";
import SettingsMenu from "../Menu";

import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Typography,
} from "@mui/material";

import { 
  Edit as EditIcon,
} from "@mui/icons-material";

const ContainerTypeManage = () => {
  const dispatch = useDispatch();

  const selectedData = useSelector((state) => ({
    containerTypes: state.containerTypes,
  }));

  useEffect(() => {
    dispatch(getContainerTypes());
  }, [dispatch]);

  const { containerTypes } = selectedData;

  return (
    <Layout>
      <Modal/>
      <Grid container>
        <Grid item xs={12} sm={2} padding={1}>
          <Grid item component={ Paper }  sx={{ height: 'auto' }}>
            <SettingsMenu activeMenu={ 'containertype' }/>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={10} padding={1}>
          <Grid item component={ Paper }  sx={{ height: 'auto' }}>
            <Grid className='settings-page-header' item xs={12} sm={12} padding={1}>
              <Grid item>
                <Typography variant="h6" gutterBottom>
                  Container Type
                </Typography>
              </Grid>
              <Grid item>
              <Button 
                    variant='contained'
                    size='small'
                    onClick={() => {
                      dispatch(
                        showModal({
                          title: "Add container type",
                          description: "Creates a new container type",
                          form: "containerType",
                        })
                      );
                    }}
                  >
                  Add Container Type
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} padding={1}>
                <Grid item  sx={{ height: '80vh' }}>
                  <TableContainer sx={{ maxHeight: '79vh' }}>
                    <Table aria-label="terminal table" stickyHeader>
                      <TableHead>
                          <TableRow>
                            <TableCell>
                              Name
                            </TableCell>
                            <TableCell align='center'>
                              Teu
                            </TableCell>
                            <TableCell align='right'></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                            { containerTypes && containerTypes.data.length !== 0 && containerTypes.data.map((containerType: ContainerType) => (
                              <TableRow className='js-collection-item' data-object-id={ containerType['@id'] }>
                                <TableCell>{ `${ containerType.abbreviation ? `${ containerType.abbreviation } - ` : '' }${ containerType.name }` }</TableCell>
                                <TableCell align='center'>{containerType.teu}</TableCell>
                                <TableCell align='right'>
                                <IconButton onClick={() => {
                                    dispatch(
                                      showModal({
                                        title: "Edit container type",
                                        description: "Edit a container type",
                                        form: "containerType",
                                        formProps: { containerType: containerType },
                                      })
                                    );
                                  }}>
                                    <EditIcon size='small'/> 
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default ContainerTypeManage;
