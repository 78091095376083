import axios from "axios";
import { hideModal, hideChildModal } from "../data/modal";

/*****************/
/* INITIAL STATE */
/*****************/
const initialState = {
  loading: false,
  error: "",
  data: [],
  query: { ids: [], total: null },
};

/*********/
/* TYPES */
/*********/
const FETCH_CONTAINERS = "FETCH_CONTAINERS";
const FETCH_CONTAINERS_SUCCESS = "FETCH_CONTAINERS_SUCCESS";
const FETCH_CONTAINERS_ERROR = "FETCH_CONTAINERS_ERROR";
const PUT_CONTAINER = "PUT_CONTAINER";
const POST_CONTAINER = "POST_CONTAINER";
const DELETE_CONTAINER = "DELETE_CONTAINER";
const FETCH_NEXT_CONTAINERS = "FETCH_NEXT_CONTAINERS";
const FETCH_NEXT_CONTAINERS_SUCCESS = "FETCH_NEXT_CONTAINERS_SUCCESS";

const getIdParams = (ids) => {
  let idsString = "";

  ids.map((id) => {
    idsString += `&id[]=${id}`;
  });

  return idsString.length > 0 ? idsString : "";
};

/*******************/
/* ACTION CREATORS */
/*******************/
export const getContainers =
  (ids = []) =>
  async (dispatch, getState) => {
    const {
      containers: { query, data },
    } = getState();

    if (data.length !== query.total) {
      dispatch(loadContainers());

      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/containers?page=1${getIdParams(
          ids
        )}`,
        headers: {
          "Content-Type": "application/ld+json; charset=utf-8",
          accept: "application/ld+json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.data["hydra:member"].length === 0) {
        dispatch(setError("No containers found"));
      } else {
        dispatch(
          setContainers(response.data["hydra:member"], {
            ids: ids,
            total: response.data["hydra:totalItems"],
          })
        );
        if (
          response.data &&
          response.data["hydra:view"] &&
          response.data["hydra:view"] !== undefined &&
          response.data["hydra:view"]["hydra:next"] &&
          response.data["hydra:view"]["hydra:next"] !== undefined
        ) {
          dispatch(
            getNextContainers(response.data["hydra:view"]["hydra:next"])
          );
        }
      }
    }
  };

export const getNextContainers = (nextUrl) => async (dispatch) => {
  dispatch(loadNextContainers());

  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL}${nextUrl}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  dispatch(setNextContainers(response.data["hydra:member"]));

  if (
    response.data &&
    response.data["hydra:view"] &&
    response.data["hydra:view"] !== undefined &&
    response.data["hydra:view"]["hydra:next"] &&
    response.data["hydra:view"]["hydra:next"] !== undefined
  ) {
    dispatch(getNextContainers(response.data["hydra:view"]["hydra:next"]));
  }
};

export const updateContainer = (container) => async (dispatch, getState) => {
  dispatch(putContainer());
  const {
    containers: { data, query },
  } = getState();

  const response = await axios({
    method: "put",
    url: `${process.env.REACT_APP_URL}${container["@id"]}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: container,
  });

  if (response.status === 200) {
    const resultData = data.map((container) => {
      if (container["@id"] === response.data["@id"]) {
        return response.data;
      }

      return container;
    });

    dispatch(setContainers(resultData, query));
    // if(container.loadingTerminalCall || container.loadingTerminalCall) {
    //   dispatch(getTerminalCalls());
    // }
    dispatch(hideModal());
  } else {
    setError("Something went wrong updating");
  }
};

export const removeContainer = (container) => async (dispatch, getState) => {
  dispatch(putContainer());
  const {
    containers: { data, query },
  } = getState();

  const response = await axios({
    method: "delete",
    url: `${process.env.REACT_APP_URL}${container["@id"]}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.status === 204) {
    const resultData = data.filter((x) => x["@id"] !== container["@id"]);

    dispatch(setContainers(resultData, query));
    dispatch(hideModal());
  } else {
    setError("Something went wrong deleting");
  }
};

export const createContainer =
  (container, callback) => async (dispatch, getState) => {
    dispatch(postContainer());
    const {
      containers: { data, query },
      modal: childModal
    } = getState();

    const response = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/containers`,
      headers: {
        "Content-Type": "application/ld+json; charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: container,
    });

    if (response.status === 201) {
      const resultData = [...data, ...[response.data]];

      dispatch(setContainers(resultData, query));

      if (childModal) {
        dispatch(hideChildModal());
        callback("containerType", response.data["@id"]);
      } else {
        dispatch(hideModal());
      }
    } else {
      setError("Something went wrong deleting");
    }
  };

export const loadContainers = () => ({ type: FETCH_CONTAINERS });

export const putContainer = () => ({ type: PUT_CONTAINER });

export const postContainer = () => ({ type: POST_CONTAINER });

export const deleteContainer = () => ({ type: DELETE_CONTAINER });

export const setContainers = (containers, query) => ({
  type: FETCH_CONTAINERS_SUCCESS,
  payload: { containers, query },
});

export const loadNextContainers = (page) => ({
  type: FETCH_NEXT_CONTAINERS,
  payload: page,
});
export const setNextContainers = (containers) => ({
  type: FETCH_NEXT_CONTAINERS_SUCCESS,
  payload: containers,
});

export const setError = (msg) => ({
  type: FETCH_CONTAINERS_ERROR,
  payload: msg,
});

/***********/
/* REDUCER */
/***********/
const containerReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CONTAINERS:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_CONTAINERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        data: payload.containers,
        query: payload.query,
      };
    case PUT_CONTAINER:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case POST_CONTAINER:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case DELETE_CONTAINER:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_CONTAINERS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case FETCH_NEXT_CONTAINERS:
      return {
        ...state,
        error: "",
      };
    case FETCH_NEXT_CONTAINERS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, ...payload],
      };
    default:
      return state;
  }
};
export default containerReducer;
