import React from "react";
import { jsPDF } from "jspdf";
import { v4 as uuid } from "uuid";

import {
    IconButton,
} from "@mui/material";
  
import {
    PictureAsPdfSharp as PictureAsPdfSharpIcon,
} from "@mui/icons-material";

export const DOCUMENT_ORIENTATION_PORTRAIT = 'portrait';
export const DOCUMENT_ORIENTATION_LANDSCAPE = 'landscape';

const DOCUMENT_ORIENTATIONS = {
    'portrait': 'p',
    'landscape': 'l',
}

const createPdf = (orientation: string) => {
    return new jsPDF(DOCUMENT_ORIENTATIONS[orientation], "mm", "a4", true);
}

const generatePdf = (filename?: string | undefined, orientation: string = DOCUMENT_ORIENTATION_PORTRAIT) => {
    const pdf = createPdf(orientation)
    const htmlContainer: any = document.querySelector(".pdf-document-content");
    const img: any = document.querySelector(".pdf-document-content img");

    if (img) {
        pdf.addImage(img, "PNG", 0, 0, 0, 0, "", "FAST");
    }

    console.log(htmlContainer.scrollWidth)

    pdf.html(htmlContainer, {
        html2canvas: {
            width: htmlContainer.scrollWidth,
            height: htmlContainer.scrollHeight,
            scale: 0.2,
            scrollX: -window.scrollX,
            scrollY: -window.scrollY
        },
        callback(pdf) {
            pdf.save(`${filename !== undefined ? filename : uuid()}.pdf`);
        },
        x: 0,
        y: 0,
    });
};

const GeneratePDF = ({filename=undefined, orientation}) => {
    return (
        <>
        <IconButton onClick={() => generatePdf(filename, orientation)}>
            <PictureAsPdfSharpIcon/>
        </IconButton>
        </>
    );
  }
  
  export default GeneratePDF;
  