import React from "react";
import Layout from "../Layout.tsx";

const Reports = () => {
  
  return (
    <Layout>
      Reports
    </Layout>
  );
};

export default Reports;
