import "../../styles/vesselsettings.css";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useForm, SubmitHandler } from "react-hook-form"
import { Vessel } from "../../types/Vessel";
import { NumberField } from "../FormFields/RenderNumberField";
import { hideModal } from "../../data/modal";
import { RadioField } from "../FormFields/RenderRadioField";
import { createVessel, updateVessel } from "../../data/vessels";

import {
    Button,
    Divider,
    Grid,
    Typography,
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead,
    TableRow,
} from "@mui/material";

import {
    Add as AddIcon,
    Circle as CircleIcon,
    Close as CloseIcon,
    RemoveSharp as RemoveSharpIcon,
    Save as SaveIcon,
} from '@mui/icons-material';

const WEIGHT_GROUP_FRONT = 'front';
const WEIGHT_GROUP_MIDDLE = 'middle';
const WEIGHT_GROUP_BACK = 'back';

const WEIGHT_GROUPS = [
    WEIGHT_GROUP_FRONT,
    WEIGHT_GROUP_MIDDLE,
    WEIGHT_GROUP_BACK,
]
const WEIGHT_GROUP_TITLE = {
    'front': 'Front',
    'middle': 'Middle',
    'back' :'Back',
}

const VesselGridSettings = ({ vessel }) => {
    const dispatch = useDispatch();

    const { handleSubmit, control, setValue, formState } = useForm({
        defaultValues: {
            ...vessel,
            beamWarnings: vessel.beamWarnings && vessel.beamWarnings.length > 0 ? vessel.beamWarnings : {
                0: 20,
                1: 35,
                2: 50,
                3: 60,
            }
        },
    });

    const { isSubmitting } = formState;

    const vesselExistingPostions = vessel.positions && vessel.positions.length > 0 ? vessel.positions[0] : [];
    const vesselExistingBarriers = vessel.barriers && vessel.barriers.length > 0 ? vessel.barriers : [];
    const [vesselPositions, setVesselPositions] = useState(vesselExistingPostions);
    const [vesselBarriers, setVesselBarriers] = useState(vesselExistingBarriers);
    const [weightGroups, setWeightGroups] = useState([]);
    let [grids, setGrids] = useState(vesselPositions.length);

    const gridVesselPosition = (position) => {
        if (!vesselPositions || vesselPositions.length === 0 || vesselPositions[position] === undefined) {
            return 0;
        }

        return vesselPositions[position]
    }

    const hasPositionBarrier = (axis: any) => {
        let requirementsObtained: any = [];
    
        if (
          !vessel ||
          (vessel.barriers && vessel.barriers.length === 0)
        ) {
          return false;
        }

        vesselBarriers.map((barrierPosition: any) => {
          requirementsObtained.push(
            JSON.stringify(barrierPosition) === JSON.stringify(axis)
          );
        });
    
        return requirementsObtained.includes(true);
      };

    const addColumn = () => {
        setGrids(++grids)
    }

    const addGridPosition = (position) => {
        let updatedPositions = vesselPositions.map((vesselPosition, index) => {
            if (position === index) {
               return vesselPosition + 1;
            }

            return vesselPosition;
         })

         if (gridVesselPosition(position) === 0) {
            updatedPositions.push(1)
         }
          
         setVesselPositions(updatedPositions)
    }

    const removeGridPosition = (position) => {
        let updatedPositions = vesselPositions.map((vesselPosition, index) => {
            if (position === index) {
               return vesselPosition - 1;
            }

            return vesselPosition;
         })

         if (updatedPositions[position] === 0) {
            setGrids(--grids)
            updatedPositions = updatedPositions.filter((updatedPosition) => updatedPosition > 0)
         }

         setVesselPositions(updatedPositions)
    }

    const addBarrierPosition = (axis) => {
        setVesselBarriers([
            ...vesselBarriers,
            axis
        ])
    }

    const removeBarrierPosition = (axis) => {
        const updatedBarrierPosition = vesselBarriers.filter((barrierPosition) => JSON.stringify(barrierPosition) !== JSON.stringify(axis))
        setVesselBarriers(updatedBarrierPosition)
    }

    const weightGroupOptions = () => {
        const options = [];

        { WEIGHT_GROUPS.map((weightGroup) => {
            options.push({
                value: weightGroup,
                label: undefined,
            })
        })}

        return options;
    }

    const showBeamWarningsByLoop = (loop: number,  fieldLabel: string) => {
        return (
            <Grid item sx={{ display: 'flex', alignItems: 'center', gap: '5px', marginBottom: '10px', marginTop: '10px' }}>
                <Grid item sx={{ display: 'flex', minWidth: '75px', alignItems: 'center' }}>
                    {[...Array(loop ?? 0)].map((x, i) =>
                        <CircleIcon
                            fontSize="10px"
                        />
                    )}
                </Grid>
                <Grid item xs={12} sm={ 2 }>
                    <NumberField
                        name={ `beamWarnings.${ loop - 1 }` }
                        label={ fieldLabel }
                        control={ control }
                        required
                    />
                </Grid>
                <Typography>T</Typography>
            </Grid>
        )
    }

    const onSubmit: SubmitHandler<Vessel> = (data: any) => {   
       let vesselData = data;
       const vesselPositionsWithLayers = [];

       {[...Array(data.layers ?? 0)].map((x, i) =>
        vesselPositionsWithLayers.push(vesselPositions)
       )}
       
       if (vesselPositionsWithLayers.length > 0) {
            vesselData = {
                ...vesselData,
                positions: vesselPositionsWithLayers,
            }
       }

       if (vesselBarriers.length > 0) {
            vesselData = {
                ...vesselData,
                barriers: vesselBarriers,
            }
       }

        if (vessel) {
            dispatch(updateVessel(vesselData));
        } else {
            dispatch(createVessel(vesselData));
        }  
    }

    useEffect(() => {
      setValue('layers', vessel.positions?.length ?? 0)
    }, [vessel]);

    return (
        <form onSubmit={ handleSubmit(onSubmit) }>
            <Typography variant='h5'>Vessel positions</Typography>
            <Grid container className='vessel-settings-container'>
                <Grid item className="vessel-settings-main-wrapper">
                    <Grid className='vessel-settings-wrapper' item>
                        <Grid className="vessel-settings-ship-bow" item></Grid>
                        <Grid className="vessel-settings-ship-body" item>
                            { grids > 0 && [...Array(grids)].map((container, index) => (
                                <>
                                <Grid 
                                    className="vessel-settings-ship-grid-wrapper" 
                                    item 
                                    key={ `grid-container-${ index }`}
                                    data-grid-position={index}
                                >   
                                    <Typography className="vessel-settings-axis-label">
                                        {index + 1}
                                    </Typography>
                                    { gridVesselPosition(index) > 0  && [...Array(gridVesselPosition(index))].map((grid, gridIndex) => (
                                        <>
                                        <Grid
                                            onClick={() => removeGridPosition(index) } 
                                            item 
                                            className='vessel-settings-grid' 
                                            key={ `grid-${ gridIndex }`}
                                        >
                                            <RemoveSharpIcon className='grid-remove' size='small' />
                                        </Grid>
                                        </>
                                    ))}
                                    { gridVesselPosition(index) === 0 || gridVesselPosition(index) < 4 ? (
                                    <Grid item className='vessel-settings-grid vessel-settings-grid--add' onClick={() => addGridPosition(index)}>
                                        <AddIcon size='small'/>
                                    </Grid>
                                    ) : ' '}
                                </Grid>

                                { (index !== (grids - 1)) && ! hasPositionBarrier([index + 1, index + 2]) ? (
                                    <Grid 
                                        onClick={() => addBarrierPosition([index + 1, index + 2])}
                                        item 
                                        className='vessel-settings-column-button vessel-settings-column-button--add-barrier'
                                    >
                                        <AddIcon size='small'/>
                                    </Grid>
                                ) : ''} 
                                
                                { hasPositionBarrier([index + 1, index + 2]) ? (
                                <Grid 
                                    onClick={() => removeBarrierPosition([index + 1, index + 2])}
                                    className="vessel-settings-column-button vessel-settings-column-button--remove-barrier" 
                                    item
                                >
                                    <RemoveSharpIcon className='grid-remove' size='small' />
                                </Grid>
                                ) : '' }
                                </>
                            ))}
                        </Grid>
                        <Grid 
                            onClick={() => addColumn()}
                            item 
                            className='vessel-settings-column-button vessel-settings-column-button--add-column'
                        >
                            <AddIcon size='small'/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Grid item xs={12} className='form-fields-container' sx={{ marginTop: '15px'}}>
                    <Grid item xs={12} sm={2}>
                        <NumberField
                            name='layers'
                            label='Number of layers'
                            control={ control }
                            required
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Divider/>
            </Grid>
            <Grid container className='vessel-settings-container'>
                <Grid item xs={12} sm={12}>
                    <Typography variant='h5'>Beam warnings</Typography>
                    { showBeamWarningsByLoop(1, '1st beam warning') }
                    { showBeamWarningsByLoop(2, '2nd beam warning') }
                    { showBeamWarningsByLoop(3, '3rd beam warning') }
                    { showBeamWarningsByLoop(4, '4th beam warning') }
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TableContainer>
                        <Table aria-label="container table dense">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Weight Group</TableCell>
                                    { grids > 0 && [...Array(grids)].map((grid, index) => (
                                        <TableCell align='center'>
                                            {index + 1}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <Grid item>
                                            <Grid item sx={{ height: '45px', paddingTop:'8px'}}>
                                                { WEIGHT_GROUP_TITLE[WEIGHT_GROUP_FRONT] }
                                            </Grid>
                                            <Grid item sx={{ height: '45px', paddingTop:'4px'}}>
                                                {WEIGHT_GROUP_TITLE[WEIGHT_GROUP_MIDDLE]}
                                            </Grid>
                                            <Grid item>
                                                {WEIGHT_GROUP_TITLE[WEIGHT_GROUP_BACK]}
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                    { grids > 0 && [...Array(grids)].map((grid, index) => (
                                        <TableCell 
                                            align='center'
                                        >
                                            <RadioField 
                                                name={`weightGroup.${index}`}
                                                control={control}
                                                options={weightGroupOptions()}
                                            />
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <Grid item xs={12} className='button-wrapper' sx={{ borderTop: 'transparent !important' }}>
                <Button 
                    onClick={() => {dispatch(hideModal())}}
                    disabled={ isSubmitting }
                    variant='contained'
                >
                    <CloseIcon fontSize="inherit" />&nbsp;Close
                </Button>
                    <Button 
                        type='submit'
                        disabled={ isSubmitting }
                        variant='contained'
                    >
                        <SaveIcon fontSize="inherit" />&nbsp;Save
                    </Button>
                </Grid>
        </form>
    )
}

export default VesselGridSettings;