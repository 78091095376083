import React from "react";
import  { Link } from 'react-router-dom'

import { 
  Menu,
  MenuList,
  MenuItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';

import {
  HighlightAlt as HighlightAltIcon,
  DirectionsBoat as DirectionsBoatIcon,
  PrecisionManufacturing as PrecisionManufacturingIcon,
  Label as LabelIcon,
  ContactEmergency as ContactEmergencyIcon,
} from '@mui/icons-material';


const SETTINGS_CONTACT = 'contact'
const SETTINGS_CONTAINER_TYPE = 'containertype'
const SETTINGS_LABEL = 'label'
const SETTINGS_TERMINAL = 'terminal'
const SETTINGS_VESSEL = 'vessel'

export default function SettingsMenu({ activeMenu }) {
  return (
    <MenuList>
      <Link to='/settings/contact'>
        <MenuItem className={`${ activeMenu === SETTINGS_CONTACT ? 'active-menu' : '' }`}>
          <ListItemIcon>
            <ContactEmergencyIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Contact</ListItemText>
        </MenuItem>
      </Link>
      <Link to='/settings/label'>
        <MenuItem className={`${ activeMenu === SETTINGS_LABEL ? 'active-menu' : '' }`}>
          <ListItemIcon>
            <LabelIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Label</ListItemText>
        </MenuItem>
      </Link>
      <Link to='/settings/container-type'>
        <MenuItem className={`${ activeMenu === SETTINGS_CONTAINER_TYPE ? 'active-menu' : '' }`}>
          <ListItemIcon>
            <HighlightAltIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Container Type</ListItemText>
        </MenuItem>
      </Link>
      <Link to='/settings/terminal'>
        <MenuItem className={`${ activeMenu === SETTINGS_TERMINAL ? 'active-menu' : '' }`}>
          <ListItemIcon>
            <PrecisionManufacturingIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Terminal</ListItemText>
        </MenuItem>
      </Link>
      <Link to='/settings/vessel'>
        <MenuItem className={`${ activeMenu === SETTINGS_VESSEL ? 'active-menu' : '' }`}>
          <ListItemIcon>
            <DirectionsBoatIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Vessel</ListItemText>
        </MenuItem>
      </Link>
    </MenuList>
  );
}