import React, { useState, useEffect, useRef } from "react";
import "../../styles/logistics.css";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import Layout from "../Layout.tsx";
import { TerminalCall } from "../../types/TerminalCall";
import Modal from "../Modal.tsx";
import { getContainers, updateContainer } from "../../data/containers";
import { getContainerTypes } from "../../data/containertype";
import { getTerminals } from "../../data/terminals";
import { getTerminalCalls } from "../../data/terminalcall";
import { getVessels } from "../../data/vessels";
import { getLabels } from "../../data/labels";
import { getCurrentUser } from "../../data/user";
import { RenderLabels, RenderTerminalCallLock, RenderTerminalName, findObjectById } from "../Helpers.tsx";
import { showModal } from "../../data/modal";
import { selectFilteredContainers } from "../../selectors/filterSelector";
import ContainerCounts from "../partial/ContainerCounts";
import TerminalCallWeightsAvailable from "../partial/TerminalCallWeightsAvailable";

import {
  addContainerLabelFilterValue,
  removeContainerLabelFilterValue,
  addContainerLoadingTerminalFilterValue,
  removeContainerLoadingTerminalFilterValue,
} from "../../data/filter";

import {
  Button,
  IconButton,
  Chip,
  Grid,
  Container,
  Paper,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List as MuiList,
  ListItemButton,
  ListItemText,
  Tooltip,
} from "@mui/material";

import {
  Add as AddIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import Search from "../partial/Search";

const ACTION_TYPE_LOADING = "loading";
const ACTION_TYPE_UNLOADING = "unloading";

const arrayIndexFormatter = (container: Container) => {
  return `${
    container.loadingTerminal && container.loadingTerminal.name
      ? container.loadingTerminal.name
      : "Null"
  } -> ${
    container.unloadingTerminal && container.unloadingTerminal.name
      ? container.unloadingTerminal.name
      : "Null"
  }`;
};

const containerListFormatter = (containers: Container[]) => {
  const containerListData = [];

  containers.map((container: Container) => {
    containerListData[arrayIndexFormatter(container)] ??= [];
    containerListData[arrayIndexFormatter(container)]["data"] ??= [];
    containerListData[arrayIndexFormatter(container)]['loadingTerminal'] = container.loadingTerminal;
    containerListData[arrayIndexFormatter(container)]['unloadingTerminal'] = container.unloadingTerminal;
    containerListData[arrayIndexFormatter(container)][
      "loading_unloadng_set"
    ] ??= 0;

    if (container.loadingTerminalCall && container.unloadingTerminalCall) {
      containerListData[arrayIndexFormatter(container)][
        "loading_unloadng_set"
      ] += 1;
    }

    if (
      !containerListData[arrayIndexFormatter(container)]["data"].includes(
        container
      )
    ) {
      containerListData[arrayIndexFormatter(container)]["data"].push(container);
    }
  });

  return containerListData;
};

const formateTerminalCallDate = (plannedDate: Date) => {
  return plannedDate ? dayjs(plannedDate).format("DD/MM/YYYY") : "";
};

const terminalPlanningFormatter = (terminalCalls: TerminalCall[]) => {
  const formattedTerminalCallList = [];

  terminalCalls.map((termincalCall: TerminalCall) => {
    formattedTerminalCallList[
      formateTerminalCallDate(termincalCall.planned)
    ] ??= [];
    formattedTerminalCallList[
      formateTerminalCallDate(termincalCall.planned)
    ].push(termincalCall);
  });

  return formattedTerminalCallList;
};

const Logistics = () => {
  const dispatch = useDispatch();
  const selectedData = useSelector((state) => ({
    containers: state.containers,
    terminals: state.terminals,
    vessels: state.vessels,
    containerTypes: state.containerTypes,
    terminalCalls: state.terminalCalls,
    user: state.user,
    labels: state.labels,
    filter: state.filter,
  }));

  const filteredContainers = useSelector(selectFilteredContainers);

  useEffect(() => {
    dispatch(getContainers());
    dispatch(getTerminals());
    dispatch(getTerminalCalls());
    dispatch(getContainerTypes());
    dispatch(getVessels());
    dispatch(getCurrentUser());
    dispatch(getLabels());
  }, [dispatch]);

  const {
    containers,
    terminals,
    vessels,
    containerTypes,
    terminalCalls,
    user,
    labels,
    filter,
    containers: { loading },
  } = selectedData;
  const tenant = user.data ? user.data.tenant : null;

  const [containerData, setContainerData] = useState([]);
  const [terminalCallData, setTerminalCallData] = useState([]);

  useEffect(() => {
    if (filteredContainers) {
      setContainerData(containerListFormatter(filteredContainers));
    } else if (containers.data) {
      setContainerData(containerListFormatter(containers.data));
    }

    if (terminalCalls.data) {
      setTerminalCallData(terminalPlanningFormatter(terminalCalls.data));
    }
  }, [containers, terminalCalls, filteredContainers]);

  const draggedElement = useRef();

  const dragStart = (e: any) => {
    draggedElement.current = e.target.getAttribute("data-container-id");
    draggedElement.action = e.target.getAttribute("data-action-type");
    draggedElement.hasTerminalCall =
      e.target.className.includes("has-logistic-call");
  };

  const droppableElement = (e: any) => {
    e.preventDefault();
    const hasTerminalCall = draggedElement.hasTerminalCall;

    if (hasTerminalCall) {
      return;
    }

    const draggedElementContainerId = draggedElement.current;
    const draggedElementContainerActionType = draggedElement.action;
    const drappableElement = e.target.closest(".js-droppable-parent");
    const terminalId = drappableElement.getAttribute("data-terminal-id");
    const terminalCallId = drappableElement.getAttribute(
      "data-terminal-call-id"
    );

    if (
      !draggedElementContainerId ||
      !terminalCallId ||
      !draggedElementContainerActionType ||
      !terminalId
    ) {
      return;
    }

    let draggedContainer = findObjectById(
      containers.data,
      draggedElementContainerId
    );
    let droppedTerminal = findObjectById(terminals.data, terminalId);

    if (draggedElementContainerActionType === ACTION_TYPE_LOADING) {
      draggedContainer = {
        ...draggedContainer,
        loadingTerminal: droppedTerminal,
        loadingTerminalCall: terminalCallId,
      };
    }

    if (draggedElementContainerActionType === ACTION_TYPE_UNLOADING) {
      draggedContainer = {
        ...draggedContainer,
        unloadingTerminal: droppedTerminal,
        unloadingTerminalCall: terminalCallId,
      };
    }
    dispatch(updateContainer(draggedContainer));
  };

  const containerEditHandler = (containerId: string) => {
    const container = findObjectById(containers.data, containerId);

    dispatch(
      showModal({
        title: "Edit container",
        description: "Edit container",
        form: "container",
        formProps: {
          container: container,
          tenant: tenant,
          terminals: terminals.data,
          terminalCalls: terminalCalls.data,
          vessels: vessels.data,
          containerTypes: containerTypes.data,
          labels: labels.data,
        },
      })
    );
  };

  const addContainerHandler = (element:any, loadingTerminal: TerminalCall|undefined = undefined, unloadingTerminal: TerminalCall|undefined = undefined) => {
    element.stopPropagation();
    dispatch(
      showModal({
        title: "Add container",
        description: "Add a container",
        form: "container",
        formProps: {
          terminals: terminals.data,
          terminalCalls: terminalCalls.data,
          vessels: vessels.data,
          containerTypes: containerTypes.data,
          labels: labels.data,
          selectedLoadingTerminal: loadingTerminal,
          selectedUnloadingTerminal: unloadingTerminal,
        },
      })
    );
  };

  const containerTerminalCallModal = (
    container: any,
    loadingUnloading: string
  ) => {
    dispatch(
      showModal({
        title: "Planning",
        description: "Unlink to planning",
        form: "containerterminalcall",
        formProps: {
          container: container,
          terminalCallType: loadingUnloading,
        },
      })
    );
  };

  const containerTerminalCallEdit = (terminalCall: any) => {
    dispatch(
      showModal({
        title: "Edit Planning",
        description: "Edit a planning",
        form: "terminalCall",
        formProps: {
          terminalCall: terminalCall,
          terminals: terminals.data,
          vessels: vessels.data,
          labels: labels.data,
        },
      })
    );
  };

  const toggleLabelFilterHandler = (labelId) => {
    if (!filter.container.labels.includes(labelId)) {
      dispatch(addContainerLabelFilterValue(labelId));
    } else {
      dispatch(removeContainerLabelFilterValue(labelId));
    }
  };

  const toggleTerminalFilterHandler = (terminalId) => {
    if (!filter.container.loadingTerminals.includes(terminalId)) {
      dispatch(addContainerLoadingTerminalFilterValue(terminalId));
    } else {
      dispatch(removeContainerLoadingTerminalFilterValue(terminalId));
    }
  };

  return (
    <Layout>
      <Modal />
      <Grid container className="main-logistic-wrapper">
        <Grid item xs={12} sm={4} className="logistic-wrapper filter-list">
          <Typography variant="h6">Filters</Typography>
          <Grid item component={Paper} className="list-content-wrapper">
            <Paper>
              <Typography>Labels</Typography>
            </Paper>
            {labels.data.map((label) => (
              <Grid
                sx={{
                  display: "flex",
                  marginTop: "5px",
                  gap: "5px",
                  marginLeft: "5px",
                }}
              >
                <Chip
                  variant={
                    filter.container.labels.includes(label["@id"])
                      ? ""
                      : "outlined"
                  }
                  label={label.name}
                  onClick={() => toggleLabelFilterHandler(label["@id"])}
                />
              </Grid>
            ))}
            <Paper>
              <Typography>Loading terminals</Typography>
            </Paper>
            {terminals.data.map((terminal) => (
              <Grid
                sx={{
                  display: "flex",
                  marginTop: "5px",
                  gap: "5px",
                  marginLeft: "5px",
                }}
              >
                <Chip
                  variant={
                    filter.container.loadingTerminals.includes(terminal["@id"])
                      ? ""
                      : "outlined"
                  }
                  avatar={
                    <Grid
                      item
                      sx={{
                        maxHeight: "15px",
                        minWidth: "8px !important",
                        maxWidth: "8px !important",
                        backgroundColor: terminal.color ?? "#FFFFF",
                        border: "1px solid #DDDDDD",
                      }}
                    ></Grid>
                  }
                  label={terminal.name}
                  onClick={() => toggleTerminalFilterHandler(terminal["@id"])}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item className="logistic-wrapper container-list" xs={12} sm={4}>
          <Typography variant="h6">Containers to plan</Typography>
          <Grid item component={Paper} className="list-content-wrapper">
            <Grid item className="list-content">
              <Grid item sx={{ maxHeight: "80vh", overflow: "auto" }}>
                {containerData
                  ? Object.entries(containerData).map(([key, value], i) => (
                      <Accordion>
                        <AccordionSummary
                          className={`js-search-element-accordion`}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={` panel-${i}-content`}
                          id={` panel-${i}-header`}
                        >
                          <Grid
                            sx={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Grid
                              item
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "2px",
                              }}
                            >
                              {key}
                            </Grid>
                            <Grid
                              className={`${
                                value.data.length > 0 &&
                                value.data.length === value.loading_unloadng_set
                                  ? "all-containers-planned"
                                  : ""
                              }`}
                            >
                              <Tooltip title="Add a container to this trajectory.">
                                <IconButton
                                  onClick={(e: any) => addContainerHandler(e, value.loadingTerminal, value.unloadingTerminal)}
                                >
                                  <AddIcon sx={{ fontSize: 20 }} />
                                </IconButton>
                              </Tooltip>
                              {`${value.loading_unloadng_set}/${value.data.length}`}
                            </Grid>
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                          { value.data && value.data.length > 10 ? (
                            <Search className={`js-search-element-${i}`} />
                          ) : ''}
                          {value.data.map((container: Container) => (
                            <Grid
                              className={`js-search-element-${i}`}
                              item
                              sx={{
                                border: "1px solid #DDDDDD",
                                padding: "4px",
                                marginBottom: "4px",
                                backgroundColor: "rgba(221, 221, 221, 0.2)",
                                borderRadius: "4px",
                              }}
                            >
                              {container.labels &&
                              container.labels.length > 0 ? (
                                <Grid item>
                                  {RenderLabels(container.labels)}
                                </Grid>
                              ) : (
                                ""
                              )}
                              <Grid item sx={{ display: "flex", gap: "5px" }}>
                                <Grid
                                  className="logistic-container"
                                  onClick={() =>
                                    containerEditHandler(container["@id"])
                                  }
                                >
                                  <p>{container.uid ?? ""}</p>
                                  <small>
                                    {container.weight
                                      ? `${container.weight}kg`
                                      : ""}
                                  </small>
                                </Grid>
                                <Grid
                                  data-container-id={container["@id"]}
                                  data-action-type={ACTION_TYPE_LOADING}
                                  onClick={() =>
                                    container.loadingTerminalCall
                                      ? containerTerminalCallModal(
                                          container,
                                          ACTION_TYPE_LOADING
                                        )
                                      : ""
                                  }
                                  onDragStart={(e: any) => dragStart(e)}
                                  draggable={
                                    !loading && !container.loadingTerminalCall
                                  }
                                  className={`logistic-in-out-default ${
                                    container.loadingTerminalCall
                                      ? "has-logistic-call"
                                      : ""
                                  }`}
                                >
                                  IN
                                </Grid>
                                <Grid
                                  data-container-id={container["@id"]}
                                  data-action-type={ACTION_TYPE_UNLOADING}
                                  onClick={() =>
                                    container.unloadingTerminalCall
                                      ? containerTerminalCallModal(
                                          container,
                                          ACTION_TYPE_UNLOADING
                                        )
                                      : ""
                                  }
                                  onDragStart={(e: any) => dragStart(e)}
                                  draggable={
                                    !loading && !container.unloadingTerminalCall
                                  }
                                  className={`logistic-in-out-default ${
                                    container.unloadingTerminalCall
                                      ? "has-logistic-call"
                                      : ""
                                  }`}
                                >
                                  OUT
                                </Grid>
                              </Grid>
                            </Grid>
                          ))}
                        </AccordionDetails>
                      </Accordion>
                    ))
                  : ""}
              </Grid>
              <Button
                onClick={(e:any) => addContainerHandler(e)}
                variant="outlined"
              >
                <AddIcon />
                &nbsp;Container
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className="logistic-wrapper vessel-list" xs={12} sm={2}>
          <Typography variant="h6">Vessels</Typography>
          <Grid item component={Paper} className="list-content-wrapper">
            <MuiList>
              {vessels && vessels.data && vessels.data
                ? Object.entries(vessels.data).map(([key, value, i]) => (
                    <>
                      <ListItemButton className="vessel-list-item">
                        <ListItemText primary={value.name} />
                      </ListItemButton>
                    </>
                  ))
                : ""}
            </MuiList>
          </Grid>
        </Grid>
        <Grid item className="logistic-wrapper planning-list" xs={12} sm={7}>
          <Typography variant="h6">Vessel planning</Typography>
          <Grid item component={Paper} className="list-content-wrapper">
            <Grid item className="list-content">
              <Grid item sx={{ maxHeight: "80vh", overflow: "auto" }}>
                {terminalCallData
                  ? Object.entries(terminalCallData).map(([key, value, i]) => (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={` panel-${i}-content`}
                          id={` panel-${i}-header`}
                        >
                          <Grid
                            sx={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            <Grid>{key}</Grid>
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                          {value.map((terminalCall: TerminalCall) => (
                            <Grid
                              className="planning-terminal-item js-droppable-parent"
                              component={Paper}
                              data-terminal-id={terminalCall.terminal["@id"]}
                              data-terminal-call-id={terminalCall["@id"]}
                              onDragOver={(e: any) => !terminalCall.loadingPlanLocked ? e.preventDefault() : ''}
                              onDrop={(e: any) => !terminalCall.loadingPlanLocked ? droppableElement(e) : ''}
                              onClick={() =>
                                containerTerminalCallEdit(terminalCall)
                              }
                            >
                              {terminalCall.labels &&
                              terminalCall.labels.length > 0 ? (
                                <Grid item>
                                  {RenderLabels(terminalCall.labels)}
                                </Grid>
                              ) : (
                                ""
                              )}
                              <Grid
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  padding: "10px",
                                  alignItems: 'center',
                                }}
                              >
                                <Grid
                                  sx={{
                                    fontWeight: "700",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {RenderTerminalCallLock(terminalCall)}
                                  {RenderTerminalName(terminalCall.terminal)}
                                </Grid>
                                <Grid>
                                  {terminalCall.planned
                                    ? dayjs(terminalCall.planned).format(
                                        "HH:mm"
                                      )
                                    : ""}
                                </Grid>
                              </Grid>
                              <Grid sx={{ display: "flex", flexDirection: 'row', alignItems: 'flex-start' }}>
                                <Grid className="planning-terminal-action">
                                  <ContainerCounts
                                    containers={filteredContainers.filter(
                                      (container) =>
                                        terminalCall.loadingContainers.includes(
                                          container["@id"]
                                        )
                                    )}
                                    containerTypes={containerTypes.data}
                                    loading={true}
                                  />
                                  <TerminalCallWeightsAvailable
                                    terminalCall={ terminalCall }
                                    containers={ containers.data }
                                  />
                                </Grid>
                                <Grid className="planning-terminal-action">
                                  <ContainerCounts
                                    containers={filteredContainers.filter(
                                      (container) =>
                                        terminalCall.unloadingContainers.includes(
                                          container["@id"]
                                        ),
                                      false
                                    )}
                                    containerTypes={containerTypes.data}
                                    loading={false}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          ))}
                        </AccordionDetails>
                      </Accordion>
                    ))
                  : ""}
              </Grid>
              <Button
                onClick={() => {
                  dispatch(
                    showModal({
                      title: "Add planning",
                      description: "Creates a new planning",
                      form: "terminalCall",
                      formProps: {
                        tenant: tenant,
                        terminals: terminals.data,
                        vessels: vessels.data,
                        labels: labels.data,
                      },
                    })
                  );
                }}
                variant="outlined"
              >
                <AddIcon />
                &nbsp;Planning
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Logistics;
