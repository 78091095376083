import axios from "axios";
import { hideChildModal, hideModal } from "../data/modal";

/*****************/
/* INITIAL STATE */
/*****************/
const initialState = {
  loading: false,
  error: "",
  data: [],
};

/*********/
/* TYPES */
/*********/
const FETCH_CONTAINERTYPES = "FETCH_CONTAINERTYPES";
const FETCH_CONTAINERTYPES_SUCCESS = "FETCH_CONTAINERTYPES_SUCCESS";
const FETCH_CONTAINERTYPES_ERROR = "FETCH_CONTAINERTYPES_ERROR";
const PUT_CONTAINER_TYPE = "PUT_CONTAINER_TYPE";
const POST_CONTAINER_TYPE = "POST_CONTAINER_TYPE";
const FETCH_NEXT_CONTAINERTYPES = "FETCH_NEXT_CONTAINERTYPES";
const FETCH_NEXT_CONTAINERTYPES_SUCCESS = "FETCH_NEXT_CONTAINERTYPES_SUCCESS";

/*******************/
/* ACTION CREATORS */
/*******************/
export const getContainerTypes = () => async (dispatch) => {
  dispatch(loadContainerTypes());

  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/container_types?page=1`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.data["hydra:member"].length === 0) {
    dispatch(setError("No container types found"));
  } else {
    dispatch(setContainerTypes(response.data["hydra:member"]));
    if (
      response.data &&
      response.data["hydra:view"] &&
      response.data["hydra:view"] !== undefined &&
      response.data["hydra:view"]["hydra:next"] &&
      response.data["hydra:view"]["hydra:next"] !== undefined
    ) {
      dispatch(
        getNextContainerTypes(response.data["hydra:view"]["hydra:next"])
      );
    }
  }
};

export const getNextContainerTypes = (nextUrl) => async (dispatch) => {
  dispatch(loadNextContainerTypes());

  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL}${nextUrl}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  dispatch(setNextContainerTypes(response.data["hydra:member"]));

  if (
    response.data &&
    response.data["hydra:view"] &&
    response.data["hydra:view"] !== undefined &&
    response.data["hydra:view"]["hydra:next"] &&
    response.data["hydra:view"]["hydra:next"] !== undefined
  ) {
    dispatch(getNextContainerTypes(response.data["hydra:view"]["hydra:next"]));
  }
};

export const updateContainerType =
  (containertype) => async (dispatch, getState) => {
    dispatch(putContainerType());
    const {
      containerTypes: { data },
    } = getState();

    const response = await axios({
      method: "put",
      url: `${process.env.REACT_APP_URL}${containertype["@id"]}`,
      headers: {
        "Content-Type": "application/ld+json; charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: containertype,
    });

    if (response.status === 200) {
      const resultData = data.map((containerType) => {
        if (containerType["@id"] === response.data["@id"]) {
          return response.data;
        }

        return containerType;
      });

      dispatch(setContainerTypes(resultData));
      dispatch(hideModal());
    } else {
      setError("Something went wrong updating");
    }
  };

export const createContainerType =
  (containertype, callback) => async (dispatch, getState) => {
    dispatch(postContainerType());
    const {
      containerTypes: { data },
      modal: childModal,
    } = getState();

    const response = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/container_types`,
      headers: {
        "Content-Type": "application/ld+json; charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: containertype,
    });

    if (response.status === 201) {
      const resultData = [...data, ...[response.data]];

      dispatch(setContainerTypes(resultData));

      if (childModal) {
        dispatch(hideChildModal());
        callback("containerType", response.data["@id"]);
      } else {
        dispatch(hideModal());
      }
    } else {
      setError("Something went wrong deleting");
    }
  };

export const putContainerType = () => ({ type: PUT_CONTAINER_TYPE });

export const postContainerType = () => ({ type: POST_CONTAINER_TYPE });

export const loadContainerTypes = () => ({ type: FETCH_CONTAINERTYPES });

export const setContainerTypes = (containertypes) => ({
  type: FETCH_CONTAINERTYPES_SUCCESS,
  payload: { containertypes },
});

export const loadNextContainerTypes = (page) => ({
  type: FETCH_NEXT_CONTAINERTYPES,
  payload: page,
});
export const setNextContainerTypes = (containertypes) => ({
  type: FETCH_NEXT_CONTAINERTYPES_SUCCESS,
  payload: containertypes,
});

export const setError = (msg) => ({
  type: FETCH_CONTAINERTYPES_ERROR,
  payload: msg,
});

/***********/
/* REDUCER */
/***********/
const containertypeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CONTAINERTYPES:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_CONTAINERTYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        data: payload.containertypes,
      };

    case PUT_CONTAINER_TYPE:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case POST_CONTAINER_TYPE:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_CONTAINERTYPES_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case FETCH_NEXT_CONTAINERTYPES:
      return {
        ...state,
        error: "",
      };
    case FETCH_NEXT_CONTAINERTYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, ...payload],
      };
    default:
      return state;
  }
};
export default containertypeReducer;
