import "../../styles/planner.css";
import React from "react";
import dayjs from "dayjs";
import { RenderLabels, RenderTerminalCallLock, textColorHandler } from "../Helpers.tsx";

import { 
  Grid,
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableRow,
  Typography, 
} from "@mui/material";

const DEFAULT_CONTAINER_COLOR = '#FFE4C4';

export default function TerminalCallDetail({
  terminalCall,
}) {

  return (
    <Grid container>
      <Grid 
        item
        className='container-planner-terminal-information-title' 
      >
        <Typography variant="h6">
          <Grid item className='container-planner-terminal-color' sx={{ 
            backgroundColor: terminalCall.terminal && terminalCall.terminal.color ? terminalCall.terminal.color : DEFAULT_CONTAINER_COLOR,
            color: textColorHandler(terminalCall.terminal && terminalCall.terminal.color ? terminalCall.terminal.color : DEFAULT_CONTAINER_COLOR),
          }}></Grid>
          {terminalCall.terminal.name}
        </Typography>
        {RenderTerminalCallLock(terminalCall)}
      </Grid>
      <TableContainer>
        <Table aria-label="terminal table" stickyHeader>
          <TableBody>
            <TableRow>
                <TableCell colSpan={100}>
                  { terminalCall.labels && terminalCall.labels.length > 0 ? (
                    RenderLabels(terminalCall.labels) 
                  ) : ''}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Planned:</TableCell>
                <TableCell>{terminalCall.planned ? dayjs(terminalCall.planned).format("DD/MM/YYYY HH:mm"): ""}</TableCell>
            </TableRow>
            { terminalCall.arrival ? (
              <TableRow>
                  <TableCell>Arrival:</TableCell>
                  <TableCell>{ dayjs(terminalCall.arrival).format("DD/MM/YYYY HH:mm") }</TableCell>
              </TableRow>
            ) : ''}

            { terminalCall.operationStart ? (
              <TableRow>
                  <TableCell>Start Operation:</TableCell>
                  <TableCell>{ dayjs(terminalCall.operationStart).format("DD/MM/YYYY HH:mm") }</TableCell>
              </TableRow>
            ) : ''}

            { terminalCall.operationEnd ? (
              <TableRow>
                  <TableCell>End Operation:</TableCell>
                  <TableCell>{ dayjs(terminalCall.operationEnd).format("DD/MM/YYYY HH:mm") }</TableCell>
              </TableRow>
            ) : ''}

            { terminalCall.departure ? (
              <TableRow>
                  <TableCell>Departure:</TableCell>
                  <TableCell>{ dayjs(terminalCall.departure).format("DD/MM/YYYY HH:mm") }</TableCell>
              </TableRow>
            ) : ''}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
